import React, { FunctionComponent, PropsWithChildren } from 'react';
import MainDesktopPaper from '../../components/MainDesktopPaper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { LOCALE, RECAPTCHA_RESET_PASSWORD } from '../../config/membersArea';
import { ReCaptcha as GoogleReCaptcha } from '../../components/ReCaptcha';
import { useConfig } from '../../common/hooks/ConfigProvider';
import { KnownBreakpoints } from '../../common/constants';
import DesktopLayout from '../PageLayout/Desktop';
import MobileLayout from '../DialogLayout/Mobile';
import { ContentLayoutProps } from '../BaseLayout';
import Box from '@mui/material/Box';
import { CONTENT_MARGIN_TOP } from '../../config';

export type SingleBlockPageProps = PropsWithChildren & ContentLayoutProps & {
    withReCaptcha?: boolean;
    onReCaptchaVerify?: (token: string) => void | Promise<void>;
    sessionId?: string;
    fullPaperWidth?: boolean;
    maintenanceCode?: string;
};

export function genSessionId(): string {
    return Date.now() + Math.random() + '';
}

const ReCaptcha: FunctionComponent<SingleBlockPageProps> = props => {
    const config = useConfig();

    return props?.withReCaptcha && config?.recaptchaId
        ? <GoogleReCaptcha
            siteKey={ config.recaptchaId }
            onVerify={ props.onReCaptchaVerify || (() => {}) }
            language={ LOCALE }
            action={ RECAPTCHA_RESET_PASSWORD }
            sessionId={ props.sessionId }
        />
        : null;
};

const SingleBlockPage: FunctionComponent<SingleBlockPageProps> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    const { children, ...layoutProps } = props;

    if (props.withReCaptcha && !props.onReCaptchaVerify) {
        throw new TypeError(
            'ReCaptcha verify handler required, but was not provided',
        );
    }

    if (props.withReCaptcha && !props.sessionId) {
        throw new TypeError(
            'ReCaptcha session identifier required, but was not provided',
        );
    }

    return isMobile
        ? <MobileLayout { ...layoutProps }>
            { children }
            <ReCaptcha { ...props } />
        </MobileLayout>
        : <DesktopLayout
            { ...layoutProps }
            headerBottomSticky={{
                element: <Box sx={{ height: `${ CONTENT_MARGIN_TOP }px` }}/>,
                zIndex: 10,
            }}
        >
            <MainDesktopPaper>{ children }</MainDesktopPaper>
            <ReCaptcha { ...props } />
        </DesktopLayout>;
};

export default SingleBlockPage;
