import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../constants';
import useAuthorized from './useAuthorized';

export function useIsTransform(theme: Theme) {
    return useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            useAuthorized() ? 1125 : 1060,
        ),
    );
}
