import { FC } from 'react';
import { useRouter } from 'next/router';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    SxProps,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    useNotificationsInfo,
    Notification,
} from '../../../common/hooks/NotificationsProvider';
import { KnownBreakpoints } from '../../../common/constants';
import NotificationsNoneOutlinedIcon
    from '@mui/icons-material/NotificationsNoneOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import { isLight } from '../../../common/helpers';
import DoneIcon from '@mui/icons-material/Done';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Loader } from '../../Pages/PageLoader';

const styles = {
    notificationPaperRoot: {
        '&.MuiPaper-root': {
            padding: '0 !important',
            margin: 0,
            marginBottom: '8px',
            borderRadius: '19px',
        },
    },
    notificationSummary: {
        padding: '12px',
        display: '-webkit-box',
    },
    notificationIcon: (notification: Notification) => (theme: Theme) => ({
        width: '38px',
        minWidth: '38px',
        height: '38px',
        borderRadius: '19px',
        background: getIconBackground(theme, notification),
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: getIconColor(theme, notification),
    }),
    notificationHeaderContainer: {
        width: 'calc(100% - 44px - 12px)',
    },
    notificationHeader: {
        width: '100%',
    },
    notificationTitle: (notification: Notification) => (theme: Theme) => ({
        ...theme.typography.h6,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: notification.isRead ? 'normal' : 'bold',
    }),
    notificationTime: (theme: Theme) => ({
        ...theme.typography.caption,
        fontSize: '10px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }),
    notificationDetails: {
        padding: '0 24px 18px 24px',
    },
    notificationText: (theme: Theme) => ({
        ...theme.typography.caption,
    }),
    notificationButtonContainer: {
        width: '100%',
    },
    notificationButton: (isMobile: boolean) => (theme: Theme) => ({
        marginTop: '16px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: '38px',
        boxShadow: 'none',
        padding: '0 32px',
        width: isMobile ? '100%' : 'unset',
    }),
    emptyMessageContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 25px',
    },
    emptyMessageCentralContainer: {
        marginTop: '-64px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emptyMessageIconBackground: (theme: Theme) => ({
        width: '128px',
        height: '128px',
        borderRadius: '64px',
        background: isLight(theme)
            ? theme.palette.info.light
            : theme.palette.info.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    emptyMessageIcon: (theme: Theme) => ({
        width: '70%',
        height: '70%',
        color: theme.palette.common.white,
    }),
    emptyMessageTitle: (theme: Theme) => ({
        ...theme.typography.h4,
        textAlign: 'center',
        marginTop: '32px',
    }),
    emptyMessageText: (theme: Theme) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        marginTop: '16px',
    }),
};

const NotificationsList: FC<{ onActionTapped?: () => void }> = props => {
    const { onActionTapped } = props;
    const {
        notifications,
        timeKey,
        unreadKey,
        filter: { unreadOnly },
        showLoader,
        markRead,
    } = useNotificationsInfo();
    const router = useRouter();
    const theme = useTheme();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );

    const onExpand = (notification: Notification) =>
        (_event: React.SyntheticEvent, isExpanded: boolean) => {
            if (isExpanded) {
                markRead(notification);
            }
        };

    if (showLoader) {
        return <Loader zIndex='unset'/>;
    }

    return <> { notifications.length
        ? notifications.map((notification, i) =>
            <Accordion
                key={ i }
                onChange={ onExpand(notification) }
                sx={ styles.notificationPaperRoot }
            >
                <AccordionSummary sx={ styles.notificationSummary }>
                    <Box sx={ styles.notificationIcon(notification) }>
                        { getIcon(notification) }
                    </Box>
                    <Box sx={ styles.notificationHeaderContainer }>
                        <Box sx={ styles.notificationHeader }>
                            <Typography
                                key={ `unread:${notification.id}:${unreadKey}` }
                                sx={
                                    styles.notificationTitle(
                                        notification,
                                    ) as SxProps
                                }
                            >
                                { notification.title }
                            </Typography>
                            <Typography
                                key={ `time:${notification.id}:${timeKey}` }
                                sx={ styles.notificationTime as SxProps }
                            >
                                { notification.sentAtDisplay }
                            </Typography>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={ styles.notificationDetails }>
                    <Typography sx={ styles.notificationText }>
                        { notification.text }
                    </Typography>
                    {( notification.url &&
                        <Box sx={ styles.notificationButtonContainer }>
                            <Button
                                sx={ styles.notificationButton(isMobile) }
                                onClick={ () => {
                                    router.push(notification.url!).catch();
                                    onActionTapped?.();
                                }}
                            >
                                { getActionTitle(notification) }
                            </Button>
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>,
        )
        : <Box sx={ styles.emptyMessageContainer }>
            <Box sx={ styles.emptyMessageCentralContainer }>
                <Box sx={ styles.emptyMessageIconBackground }>
                    { unreadOnly
                        ? <InboxOutlinedIcon sx={ styles.emptyMessageIcon } />
                        : <NotificationsNoneOutlinedIcon
                            sx={ styles.emptyMessageIcon }
                        />
                    }
                </Box>
                <Typography sx={ styles.emptyMessageTitle as SxProps }>
                    { unreadOnly
                        ? 'You have no unread notifications'
                        : 'You have no notifications'
                    }
                </Typography>
                <Typography sx={ styles.emptyMessageText as SxProps }>
                    { unreadOnly
                        ? 'Switch to "All" to see your read notifications.'
                        : 'Here will be various important notifications, ' +
                          'be sure to check them periodically.'
                    }
                </Typography>
            </Box>
        </Box>
    } </>;
};

function getIconBackground(theme: Theme, notification: Notification) {
    if (notification.isRead) {
        return theme.palette.info.light; // grey
    }

    switch (notification.eventName) {
        case 'paymentClearedIn': // 3 days after customer payment if no return,"{""text"": ""Dear {firstName},\nyour loan payment of {paymentAmount} is cleared"", ""subject"": ""Loan payment""}"
        case 'paymentMadeOut': // Happens when payment is made,"{""text"": ""Dear {firstName},\na payment in the amount of {paymentAmount} was sent to your bank account today. It may take up to 3 business days for it to clear."", ""subject"": ""Loan payment""}"
        case 'paymentMadeIn': // Happens when customer payment is made,"{""text"": ""Dear {firstName},\na payment in the amount of {paymentAmount} was sent to your bank account today. It may take up to 3 business days for it to clear."", ""subject"": ""Loan payment""}"
        case 'paymentClearedOut': // 3 days after payment if no return,"{""text"": ""Dear {firstName},\nyour loan payment of {paymentAmount} is cleared."", ""subject"": ""Loan payment""}"
        case 'applicationApproved': // Sent to Customer if browser is not open once the app is approved,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is approved!"", ""subject"": ""Loan application""}"
        case 'applicationPaidOff': // Sent to Customer at a reasonable time 3 days after the last payment for the loan clears.,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is paid off. Thanks for cooperation with us!"", ""subject"": ""Loan application""}"
            return theme.palette.primary.light; // green

        case 'twoDaysPriorToPaymentDueDate': // Happens when user should make the payment in two days,"{""text"": ""Dear {firstName} {lastName},\n a loan payment of {paymentAmount} is scheduled to be drafted on {scheduledPaymentDate}. Changes to this payment may be made on your Member's Area. Click to log in."", ""subject"": ""Payment reminder""}"
        case 'ibvRequired': // Sent to Customer daily if IBV is not completed and a browser is not open.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please complete the bank verification."", ""subject"": ""Application is not completed""}"
        case 'applicationNotCompleted': // Sent to Customer when 1 hour after the customer closed the browser without finishing all procedures for the app.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. If you have any questions please contact our managers."", ""subject"": ""Application is not completed""}"
        case 'ibvStillRequired': // Sent to Customer Sent daily if browser is not open & IBV is completed & the answer for IBV is not Denied (Loan Status is not D),"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please contact our managers."", ""subject"": ""Application is not completed""}"
        case 'applicationDenied': // Sent to Customer if the browser is not open one the app is denied,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is denied. If you have any questions please contact our managers."", ""subject"": ""Loan application""}"
        case 'paymentPlanOffers': // "As long as there is no active payment plan, every 14 days","{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, We have new payment plan options to offer you. Check them out here!"", ""subject"": ""Loan collections""}"
            return theme.palette.warning.main; // yellow

        case 'paymentReturnedOut': // Happens when payment is returned,"{""text"": ""Dear {firstName},\nunfortunately, your loan payment of {paymentAmount} was returned due to insufficient funds, Login to your Member's Area for more info and resolutions."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedIn': // Happens when customer payment is returned,"{""text"": ""Dear {firstName},\nunfortunately, your loan payment of {paymentAmount} was returned due to insufficient funds, Login to your Member's Area for more info and resolutions."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedHardOut': // Happens when payment is hard returned,"{""text"": ""Dear {firstName},\n your loan payment of {paymentAmount} did not go through and your account was transferred to collections. Please log in to you Member's area to choose a new payment plan."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedHardIn': // Happens when customer payment is hard returned,"{""text"": ""Dear {firstName},\n your loan payment of {paymentAmount} did not go through and your account was transferred to collections. Please log in to you Member's area to choose a new payment plan."", ""subject"": ""Loan payment""}"
        case 'paymentReversedOut': // Happens when payment is reversed,"{""text"": ""Dear {firstName} {lastName},\nyour loan amount of {paymentAmount} has been reversed, please get in touch with our managers."", ""subject"": ""Loan payment""}"
        case 'paymentReversedIn': // Happens when customer payment is reversed,"{""text"": ""Dear {firstName} {lastName},\nyour loan amount of {paymentAmount} has been reversed, please get in touch with our managers."", ""subject"": ""Loan payment""}"
        case 'paymentRefundedOut': // Happens when payment is refunded,"{""text"": ""Dear {firstName},\n A refund for the amount of {paymentAmount} was issued today, you will be able to see it on the next busineess day."", ""subject"": ""Loan payment""}"
        case 'paymentRefundedIn': // Happens when customer payment is refunded,"{""text"": ""Dear {firstName},\n A refund for the amount of {paymentAmount} was issued today, you will be able to see it on the next busineess day."", ""subject"": ""Loan payment""}"
        case 'enterCollections': // Same day as the account enters collections,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, your loan in the amount of {currentBalance} was transferred for collection. Login to your Member's Area to claim a discount and set up a payment plan."", ""subject"": ""Loan collections""}"
        case 'transferringToThirdParty': // 7 days after placing into 3rd party collections,"{""text"": ""Dear {firstName}, your loan in the amount of {currentBalance} has been transferred for the 3-rd party collection."", ""subject"": ""Loan collections""}"
        case 'thirdPartyCollectionsWarning': // On the day of Placing into 3rd party collections,"{""text"": ""Dear {firstName}, your negligence leaves us no option but to transfer your loan to a 3rd party collection agency in it's full amount of {currentBalance}. Login to your Member's Area to set up a payment plan now with a discount and avoid unwanted steps."", ""subject"": ""Loan collections""}"
            return theme.palette.error.main; // red

        case 'newLoyaltyStatus': // When Customer advances into the loyalty program (i.e Silver to gold),"{""data"": {""url"": ""/app""}, ""text"": ""Congratulations {firstName}! You reached a new loyalty status and gain access to our '{status}' products. We appreciate your ongoing business!"", ""subject"": ""Congratulations!""}"
        case 'customerBirthday': // Sent at a reasonable time on the customer birthday,"{""data"": {""url"": ""holiday/birthday""}, ""text"": ""Happy birthday {firstName}! {consumerName} wishes you a the best and want to offer you a special gift."", ""subject"": ""Happy birthday!""}"
        case 'holidayNewYear': // New Year's Day,"{""data"": {""url"": ""/holiday/new-year""}, ""text"": ""Happy New Year!!! Start the year on the right foot with our amazing promo!"", ""subject"": ""Happy New Year!""}"
        case 'holidayIndependence': // Independence Day,"{""data"": {""url"": ""/holiday/independence""}, ""text"": ""Happy Independence Day! Take a minute to look at our special 4th of July promo!"", ""subject"": ""Happy Independence Day!""}"
        case 'holidayThanksgiving': // Thanksgiving Day,"{""data"": {""url"": ""/holiday/thanksgiving""}, ""text"": ""{consumerName} wishes you a happy Thanksgiving! Don't forget to check our special Thanksgiving promotion!"", ""subject"": ""Happy Thanksgiving Day!""}"
        case 'holidayChristmas': // Christmas Day,"{""data"": {""url"": ""/holiday/christmas""}, ""text"": ""{consumerName} wishes you a Merry Christmas and a happy new year! Click here to check our special holiday promotion for you."", ""subject"": ""Merry Christmas""}"
        case 'holidayMartinLutherKing': // "Birthday of Martin Luther King, Jr.","{""data"": {""url"": ""/holiday/martin-luther-king""}, ""text"": ""{consumerName} wishes you a happy Martin Luther King day! Don't forget to check our special promotion!"", ""subject"": ""Happy Martin Luther King Day!""}"
        case 'holidayValentine': // Valentine's day,"{""data"": {""url"": ""/holiday/valentine""}, ""text"": ""{consumerName} wishes you a happy Valentine''s day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Valentine''s Day!""}"
        case 'holidayMemorial': // Memorial Day,"{""data"": {""url"": ""/holiday/memorial""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Memorial Day""}"
        case 'holidayJuneteenthIndependence': // Juneteenth National Independence Day,"{""data"": {""url"": ""/holiday/juneteenth-independence""}, ""text"": ""{consumerName} wishes you a happy Juneteenth Independence day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Juneteenth Independence Day!""}"
        case 'holidayLabor': // Labor Day,"{""data"": {""url"": ""/holiday/labor""}, ""text"": ""{consumerName} wishes you a happy Labor day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Labor Day!""}"
        case 'holidayColumbus': // Columbus Day,"{""data"": {""url"": ""/holiday/columbus""}, ""text"": ""{consumerName} wishes you a happy Columbus day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Columbus Day!""}"
        case 'holidayVeterans': // Veterans Day,"{""data"": {""url"": ""/holiday/veterans""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Veterans Day""}"
        case 'holidayWinterPromo': // Winter Promo,"{"data": {"url": "/holiday/winter"}, "text": "Warm greetings from {consumerName}! Embrace the winter chill with our new Winter Promotion!", "subject": "Winter Promotion Inside!"}"
            return '#FBB4F8'; // purple

        default:
            return theme.palette.warning.main; // yellow
    }
}

function getIconColor(theme: Theme, notification: Notification) {
    return notification.isRead
        ? theme.palette.common.white
        : theme.palette.common.black;
}

function getIcon(notification: Notification) {
    switch (notification.eventName) {
        case 'paymentClearedIn': // 3 days after customer payment if no return,"{""text"": ""Dear {firstName},\nyour loan payment of {paymentAmount} is cleared"", ""subject"": ""Loan payment""}"
        case 'paymentMadeOut': // Happens when payment is made,"{""text"": ""Dear {firstName},\na payment in the amount of {paymentAmount} was sent to your bank account today. It may take up to 3 business days for it to clear."", ""subject"": ""Loan payment""}"
        case 'paymentMadeIn': // Happens when customer payment is made,"{""text"": ""Dear {firstName},\na payment in the amount of {paymentAmount} was sent to your bank account today. It may take up to 3 business days for it to clear."", ""subject"": ""Loan payment""}"
        case 'paymentClearedOut': // 3 days after payment if no return,"{""text"": ""Dear {firstName},\nyour loan payment of {paymentAmount} is cleared."", ""subject"": ""Loan payment""}"
        case 'applicationApproved': // Sent to Customer if browser is not open once the app is approved,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is approved!"", ""subject"": ""Loan application""}"
        case 'applicationPaidOff': // Sent to Customer at a reasonable time 3 days after the last payment for the loan clears.,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is paid off. Thanks for cooperation with us!"", ""subject"": ""Loan application""}"
            return <DoneIcon />;

        case 'twoDaysPriorToPaymentDueDate': // Happens when user should make the payment in two days,"{""text"": ""Dear {firstName} {lastName},\n a loan payment of {paymentAmount} is scheduled to be drafted on {scheduledPaymentDate}. Changes to this payment may be made on your Member's Area. Click to log in."", ""subject"": ""Payment reminder""}"
        case 'ibvRequired': // Sent to Customer daily if IBV is not completed and a browser is not open.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please complete the bank verification."", ""subject"": ""Application is not completed""}"
        case 'applicationNotCompleted': // Sent to Customer when 1 hour after the customer closed the browser without finishing all procedures for the app.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. If you have any questions please contact our managers."", ""subject"": ""Application is not completed""}"
        case 'ibvStillRequired': // Sent to Customer Sent daily if browser is not open & IBV is completed & the answer for IBV is not Denied (Loan Status is not D),"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please contact our managers."", ""subject"": ""Application is not completed""}"
        case 'applicationDenied': // Sent to Customer if the browser is not open one the app is denied,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is denied. If you have any questions please contact our managers."", ""subject"": ""Loan application""}"
        case 'paymentPlanOffers': // "As long as there is no active payment plan, every 14 days","{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, We have new payment plan options to offer you. Check them out here!"", ""subject"": ""Loan collections""}"
        case 'paymentReturnedOut': // Happens when payment is returned,"{""text"": ""Dear {firstName},\nunfortunately, your loan payment of {paymentAmount} was returned due to insufficient funds, Login to your Member's Area for more info and resolutions."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedIn': // Happens when customer payment is returned,"{""text"": ""Dear {firstName},\nunfortunately, your loan payment of {paymentAmount} was returned due to insufficient funds, Login to your Member's Area for more info and resolutions."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedHardOut': // Happens when payment is hard returned,"{""text"": ""Dear {firstName},\n your loan payment of {paymentAmount} did not go through and your account was transferred to collections. Please log in to you Member's area to choose a new payment plan."", ""subject"": ""Loan payment""}"
        case 'paymentReturnedHardIn': // Happens when customer payment is hard returned,"{""text"": ""Dear {firstName},\n your loan payment of {paymentAmount} did not go through and your account was transferred to collections. Please log in to you Member's area to choose a new payment plan."", ""subject"": ""Loan payment""}"
        case 'paymentReversedOut': // Happens when payment is reversed,"{""text"": ""Dear {firstName} {lastName},\nyour loan amount of {paymentAmount} has been reversed, please get in touch with our managers."", ""subject"": ""Loan payment""}"
        case 'paymentReversedIn': // Happens when customer payment is reversed,"{""text"": ""Dear {firstName} {lastName},\nyour loan amount of {paymentAmount} has been reversed, please get in touch with our managers."", ""subject"": ""Loan payment""}"
        case 'paymentRefundedOut': // Happens when payment is refunded,"{""text"": ""Dear {firstName},\n A refund for the amount of {paymentAmount} was issued today, you will be able to see it on the next busineess day."", ""subject"": ""Loan payment""}"
        case 'paymentRefundedIn': // Happens when customer payment is refunded,"{""text"": ""Dear {firstName},\n A refund for the amount of {paymentAmount} was issued today, you will be able to see it on the next busineess day."", ""subject"": ""Loan payment""}"
        case 'enterCollections': // Same day as the account enters collections,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, your loan in the amount of {currentBalance} was transferred for collection. Login to your Member's Area to claim a discount and set up a payment plan."", ""subject"": ""Loan collections""}"
        case 'transferringToThirdParty': // 7 days after placing into 3rd party collections,"{""text"": ""Dear {firstName}, your loan in the amount of {currentBalance} has been transferred for the 3-rd party collection."", ""subject"": ""Loan collections""}"
        case 'thirdPartyCollectionsWarning': // On the day of Placing into 3rd party collections,"{""text"": ""Dear {firstName}, your negligence leaves us no option but to transfer your loan to a 3rd party collection agency in it's full amount of {currentBalance}. Login to your Member's Area to set up a payment plan now with a discount and avoid unwanted steps."", ""subject"": ""Loan collections""}"
            return <PriorityHighIcon />;

        case 'newLoyaltyStatus': // When Customer advances into the loyalty program (i.e Silver to gold),"{""data"": {""url"": ""/app""}, ""text"": ""Congratulations {firstName}! You reached a new loyalty status and gain access to our '{status}' products. We appreciate your ongoing business!"", ""subject"": ""Congratulations!""}"
        case 'customerBirthday': // Sent at a reasonable time on the customer birthday,"{""data"": {""url"": ""holiday/birthday""}, ""text"": ""Happy birthday {firstName}! {consumerName} wishes you a the best and want to offer you a special gift."", ""subject"": ""Happy birthday!""}"
        case 'holidayNewYear': // New Year's Day,"{""data"": {""url"": ""/holiday/new-year""}, ""text"": ""Happy New Year!!! Start the year on the right foot with our amazing promo!"", ""subject"": ""Happy New Year!""}"
        case 'holidayIndependence': // Independence Day,"{""data"": {""url"": ""/holiday/independence""}, ""text"": ""Happy Independence Day! Take a minute to look at our special 4th of July promo!"", ""subject"": ""Happy Independence Day!""}"
        case 'holidayThanksgiving': // Thanksgiving Day,"{""data"": {""url"": ""/holiday/thanksgiving""}, ""text"": ""{consumerName} wishes you a happy Thanksgiving! Don't forget to check our special Thanksgiving promotion!"", ""subject"": ""Happy Thanksgiving Day!""}"
        case 'holidayChristmas': // Christmas Day,"{""data"": {""url"": ""/holiday/christmas""}, ""text"": ""{consumerName} wishes you a Merry Christmas and a happy new year! Click here to check our special holiday promotion for you."", ""subject"": ""Merry Christmas""}"
        case 'holidayMartinLutherKing': // "Birthday of Martin Luther King, Jr.","{""data"": {""url"": ""/holiday/martin-luther-king""}, ""text"": ""{consumerName} wishes you a happy Martin Luther King day! Don't forget to check our special promotion!"", ""subject"": ""Happy Martin Luther King Day!""}"
        case 'holidayValentine': // Valentine's day,"{""data"": {""url"": ""/holiday/valentine""}, ""text"": ""{consumerName} wishes you a happy Valentine''s day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Valentine''s Day!""}"
        case 'holidayMemorial': // Memorial Day,"{""data"": {""url"": ""/holiday/memorial""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Memorial Day""}"
        case 'holidayJuneteenthIndependence': // Juneteenth National Independence Day,"{""data"": {""url"": ""/holiday/juneteenth-independence""}, ""text"": ""{consumerName} wishes you a happy Juneteenth Independence day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Juneteenth Independence Day!""}"
        case 'holidayLabor': // Labor Day,"{""data"": {""url"": ""/holiday/labor""}, ""text"": ""{consumerName} wishes you a happy Labor day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Labor Day!""}"
        case 'holidayColumbus': // Columbus Day,"{""data"": {""url"": ""/holiday/columbus""}, ""text"": ""{consumerName} wishes you a happy Columbus day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Columbus Day!""}"
        case 'holidayVeterans': // Veterans Day,"{""data"": {""url"": ""/holiday/veterans""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Veterans Day""}"
        case 'holidayWinterPromo': // Winter Promo,"{"data": {"url": "/holiday/winter"}, "text": "Warm greetings from {consumerName}! Embrace the winter chill with our new Winter Promotion!", "subject": "Winter Promotion Inside!"}"
            return <CardGiftcardIcon />;

        default:
            return <NotificationsNoneIcon />;
    }
}

function getActionTitle(notification: Notification) {
    switch (notification.eventName) {
        case 'newLoyaltyStatus': // When Customer advances into the loyalty program (i.e Silver to gold),"{""data"": {""url"": ""/app""}, ""text"": ""Congratulations {firstName}! You reached a new loyalty status and gain access to our '{status}' products. We appreciate your ongoing business!"", ""subject"": ""Congratulations!""}"
            return 'See my status';

        case 'customerBirthday': // Sent at a reasonable time on the customer birthday,"{""data"": {""url"": ""holiday/birthday""}, ""text"": ""Happy birthday {firstName}! {consumerName} wishes you a the best and want to offer you a special gift."", ""subject"": ""Happy birthday!""}"
        case 'holidayNewYear': // New Year's Day,"{""data"": {""url"": ""/holiday/new-year""}, ""text"": ""Happy New Year!!! Start the year on the right foot with our amazing promo!"", ""subject"": ""Happy New Year!""}"
        case 'holidayIndependence': // Independence Day,"{""data"": {""url"": ""/holiday/independence""}, ""text"": ""Happy Independence Day! Take a minute to look at our special 4th of July promo!"", ""subject"": ""Happy Independence Day!""}"
        case 'holidayThanksgiving': // Thanksgiving Day,"{""data"": {""url"": ""/holiday/thanksgiving""}, ""text"": ""{consumerName} wishes you a happy Thanksgiving! Don't forget to check our special Thanksgiving promotion!"", ""subject"": ""Happy Thanksgiving Day!""}"
        case 'holidayChristmas': // Christmas Day,"{""data"": {""url"": ""/holiday/christmas""}, ""text"": ""{consumerName} wishes you a Merry Christmas and a happy new year! Click here to check our special holiday promotion for you."", ""subject"": ""Merry Christmas""}"
        case 'holidayMartinLutherKing': // "Birthday of Martin Luther King, Jr.","{""data"": {""url"": ""/holiday/martin-luther-king""}, ""text"": ""{consumerName} wishes you a happy Martin Luther King day! Don't forget to check our special promotion!"", ""subject"": ""Happy Martin Luther King Day!""}"
        case 'holidayValentine': // Valentine's day,"{""data"": {""url"": ""/holiday/valentine""}, ""text"": ""{consumerName} wishes you a happy Valentine''s day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Valentine''s Day!""}"
        case 'holidayMemorial': // Memorial Day,"{""data"": {""url"": ""/holiday/memorial""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Memorial Day""}"
        case 'holidayJuneteenthIndependence': // Juneteenth National Independence Day,"{""data"": {""url"": ""/holiday/juneteenth-independence""}, ""text"": ""{consumerName} wishes you a happy Juneteenth Independence day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Juneteenth Independence Day!""}"
        case 'holidayLabor': // Labor Day,"{""data"": {""url"": ""/holiday/labor""}, ""text"": ""{consumerName} wishes you a happy Labor day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Labor Day!""}"
        case 'holidayColumbus': // Columbus Day,"{""data"": {""url"": ""/holiday/columbus""}, ""text"": ""{consumerName} wishes you a happy Columbus day! Don''t forget to check our special promotion!"", ""subject"": ""Happy Columbus Day!""}"
        case 'holidayVeterans': // Veterans Day,"{""data"": {""url"": ""/holiday/veterans""}, ""text"": ""{consumerName} has a special promotion for you!"", ""subject"": ""Veterans Day""}"
        case 'holidayWinterPromo': // Winter Promo,"{"data": {"url": "/holiday/winter"}, "text": "Warm greetings from {consumerName}! Embrace the winter chill with our new Winter Promotion!", "subject": "Winter Promotion Inside!"}"
            return 'Open the gift!';

        case 'paymentPlanOffers': // "As long as there is no active payment plan, every 14 days","{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, We have new payment plan options to offer you. Check them out here!"", ""subject"": ""Loan collections""}"
            return 'Open my payment plan';

        case 'enterCollections': // Same day as the account enters collections,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName}, your loan in the amount of {currentBalance} was transferred for collection. Login to your Member's Area to claim a discount and set up a payment plan."", ""subject"": ""Loan collections""}"
        case 'applicationApproved': // Sent to Customer if browser is not open once the app is approved,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is approved!"", ""subject"": ""Loan application""}"
        case 'applicationDenied': // Sent to Customer if the browser is not open one the app is denied,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is denied. If you have any questions please contact our managers."", ""subject"": ""Loan application""}"
        case 'applicationPaidOff': // Sent to Customer at a reasonable time 3 days after the last payment for the loan clears.,"{""data"": {""url"": ""/app""}, ""text"": ""Dear {firstName} {lastName}, your loan application is paid off. Thanks for cooperation with us!"", ""subject"": ""Loan application""}"
            return 'Open my loan';

        case 'ibvRequired': // Sent to Customer daily if IBV is not completed and a browser is not open.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please complete the bank verification."", ""subject"": ""Application is not completed""}"
        case 'ibvStillRequired': // Sent to Customer Sent daily if browser is not open & IBV is completed & the answer for IBV is not Denied (Loan Status is not D),"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. Please contact our managers."", ""subject"": ""Application is not completed""}"
            return 'Complete the verification';

        case 'applicationNotCompleted': // Sent to Customer when 1 hour after the customer closed the browser without finishing all procedures for the app.,"{""data"": {""url"": ""/app?dialog=apply""}, ""text"": ""Dear {firstName} {lastName}, your loan application is not completed. If you have any questions please contact our managers."", ""subject"": ""Application is not completed""}"
            return 'Complete your loan application';

        default:
            return 'Open';
    }
}

export default NotificationsList;
