import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { buildMenuItem, isLight } from '../../../common/helpers';
import ThemeSwitcher from '../../../components/ThemeSwitcher';
import HeadphoneKidIcon from '../../../assets/SvgIcons/HeadphoneKidIcon';
import { ContentLayoutProps } from '../../../layouts/BaseLayout';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import { officeHoursFormat } from '../../../common/helpers/format';
import IsOfficeOpened from '../../../components/SupportInfo/IsOfficeOpened';
import MembersAreaIcon from '../../../assets/SvgIcons/MembersAreaIcon';
import ProfileOutlinedIcon from '../../../assets/SvgIcons/ProfileOutlinedIcon';
import SignButton from './Buttons/Sign';
import MobileSignInButton from '../Desktop/Buttons/SignIn';
import MobileMenu from '../../Menu/Mobile';
import { KnownBreakpoints } from '../../../common/constants';
import { MenuItem } from '../../Menu/types';
import { NamedRoute } from '../../../common/hooks/useNamedRoute';
import { useMenu } from '../../../common/hooks/MenuProvider';
import IosPwaMessage from '../IosPwaMessage';
import SmartLink from '../../SmartLink';
import HeaderLogo from '../Logo';
import useAuthorized from '../../../common/hooks/useAuthorized';
import { DialogMobileLayout } from '../../../layouts/DialogLayout/Mobile';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import NotificationsButton from '../Notifications/NotificationsButton';
import NotificationsUnreadFilter
    from '../Notifications/NotificationsUnreadFilter';
import NotificationsMarkAll from '../Notifications/NotificationsMarkAll';
import NotificationsTopicsFilterTitle
    from '../Notifications/NotificationsTopicsFilterTitle';
import NotificationsTopicsFilter
    from '../Notifications/NotificationsTopicsFilter';
import NotificationsList from '../Notifications/NotificationsList';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';

const styles = {
    headerTop: (_: Theme, disableSticky?: boolean) => ({
        position: disableSticky ? 'relative' : 'sticky',
        zIndex: 999,
        top: 0,
        padding: 0,
    }),
    headerLocation: {
        padding: '32px 20px 0',
    },
    headerContent: (theme: Theme, hideBorder: boolean) =>({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 0 20px',
        borderBottom: `1px solid ${ theme.palette.common.white }`,
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            margin: '0',
            borderBottom: hideBorder
                ? 'none'
                : `1px solid ${ theme.palette.common.white }`
            ,
        },
    }),
    headerHalf: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    headerHalfPointer: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft: '10px',
    },
    hamburgerMenu: (theme: Theme) => ({
        fontSize: '33px',
        color: theme.palette.common.white,
    }),
    hamburgerMenuContainer: (theme: Theme) => ({
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '50%',
        },
    }),
    hamburgerMenuLocation: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        cursor: 'pointer',
    },
    hamburgerMenuInfo: (theme: Theme, menuBackground?: string) => ({
        backgroundColor: menuBackground
            ? menuBackground
            : isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main,
        padding: '43px 0 40px 0',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
    }),
    hamburgerMenuInfoTitle: (theme: Theme, menuBackground?: string) => ({
        ...theme.typography.body1,
        lineHeight: '26px',
        color: menuBackground
            ? theme.palette.common.white
            : theme.palette.text.primary,
    }),
    hamburgerMenuInfoText: (theme: Theme, menuBackground?: string) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '16.41px',
        color: menuBackground
            ? theme.palette.common.white
            : theme.palette.text.primary,
    }),
    hamburgerMenuInfoTextBottom: (theme: Theme, menuBackground?: string) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '20px',
        color: menuBackground
            ? theme.palette.common.white
            : theme.palette.text.primary,
    }),
    hamburgerMenuInfoTextPhone: (theme: Theme, menuBackground?: string) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '20px',
        color: menuBackground
            ? theme.palette.common.white
            : theme.palette.text.primary,
        paddingTop: '20px',
        marginBottom: '27px',
    }),
    hamburgerMenuInfoRow:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '23px',
    },
    hamburgerMenuInfoLink: (theme: Theme, menuBackground?: string) => ({
        paddingLeft: '5px',
        color: menuBackground
            ? theme.palette.common.white
            : theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '20px',
        cursor: 'pointer',
        textDecorationColor: 'unset',
        '&:hover': {
            color: theme.palette.text.primary,
        },
    }),
    icon: (theme: Theme) => ({
        color: theme.palette.text.primary,
        width: '36px',
        height: '38px',
    }),
    menuWrapper: {
        textAlign: 'center',
        overflow: 'auto',
    },
    notificationsHeaderContent: {
        paddingTop: '16px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    notificationsListContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    topicsFilterContainer: {
        '&.MuiPaper-root': {
            padding: 0,
            border: '0',
            '&.Mui-expanded': {
                backgroundColor: 'unset',
            },
        },
    },
    topicsFilterContainerSummary: {
        padding: '0',
        flexDirection: 'row-reverse',
    },
    notificationsListSpacer: {
        minHeight: '4px',
    },
};

const MobileHeader: React.FunctionComponent<ContentLayoutProps> = props => {
    const {
        headerBackground,
        menuItems,
        hideHeaderBorder,
        menuBackground,
    } = props;
    const authorized = useAuthorized();
    const { open, setOpen } = useMenu();
    const [menu, setMenu] = useState<MenuItem[]>([]);
    const theme = useTheme();
    const { consumerName, consumerOperationsDetails } = useConfig();
    const {
        phoneNumber,
        supportEmail,
        officeHours,
    } = consumerOperationsDetails as any;
    const [notificationsOpened, setNotificationsOpened] = useState(false);
    const { handleScroll } = useNotificationsInfo();

    useEffect(() => {
        const mobileMenuItems = [
                buildMenuItem({
                    title: 'Members Area',
                    link: NamedRoute.HOME,
                    icon: <MembersAreaIcon />,
                }),
                ...(menuItems || []),
                ...(authorized
                    ? [
                        buildMenuItem({
                            title: 'My profile',
                            link: NamedRoute.PROFILE,
                            icon: <ProfileOutlinedIcon />,
                        }),
                    ]
                    : []),
            ];
        setMenu(mobileMenuItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorized]);

    useEffect(() => {
        props.setMenuOpen && props.setMenuOpen(open);

        if (open) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
                duration: 0,
            } as any);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleMenuClick = () => {
        setOpen(value => !value);
    };

    return <Box
        sx={{
            ...styles.headerTop(theme, open),
            background: headerBackground
                ? headerBackground
                : theme.palette.primary.main,
        }}
        id="header-top-mobile"
        data-id="mobile-layout"
    >
        <Box sx={{ ...styles.headerLocation }}>
            <Box sx={ styles.headerContent(theme, Boolean(hideHeaderBorder)) }>
                <HeaderLogo title={ consumerName } />
                <Box sx={ styles.headerHalf }>
                    { !authorized && <MobileSignInButton /> }
                    { !props.withoutThemeSwitcher && <ThemeSwitcher/> }
                    <NotificationsButton
                        onClick={ () => setNotificationsOpened(true) }
                    />
                    {/* TODO: move to component button */}
                    <Box sx={ styles.hamburgerMenuContainer }>
                        <Box
                            onClick={ handleMenuClick }
                            sx={ styles.hamburgerMenuLocation }
                        >
                            { open
                                ? <CloseIcon sx={ styles.hamburgerMenu } />
                                : <MenuIcon sx={ styles.hamburgerMenu }/>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Collapse in={ open }>
            <Box sx={ styles.menuWrapper }>
                <MobileMenu
                    menuItems={ menu }
                    backgroundColor={ props.menuBackground }
                />
                { authorized && <SignButton { ...props } /> }
                <Box sx={ styles.hamburgerMenuInfo(theme, menuBackground) } >
                    <Typography sx={ styles.hamburgerMenuInfoTitle(
                        theme,
                        menuBackground,
                    ) }>
                        Have&nbsp;questions? Contact&nbsp;us!
                    </Typography>
                    <Box sx={ styles.hamburgerMenuInfoRow } >
                        <Typography sx={ styles.hamburgerMenuInfoText(
                            theme,
                            menuBackground,
                        ) }>
                            { officeHoursFormat(
                                officeHours.start,
                                officeHours.end,
                            )}
                        </Typography>
                        <IsOfficeOpened menuBackground={ menuBackground }/>
                    </Box>
                    <Box sx={ styles.hamburgerMenuInfoRow } >
                        <Typography
                            sx={ styles.hamburgerMenuInfoTextBottom(
                                theme,
                                menuBackground,
                            ) }
                        >
                            By email:
                        </Typography>
                        <SmartLink href={ `mailto:${ supportEmail }` } style={
                            (styles.hamburgerMenuInfoLink as any)(
                                theme,
                                menuBackground,
                            )
                        }>
                            { supportEmail }
                        </SmartLink>
                    </Box>
                    <Typography
                        sx={ styles.hamburgerMenuInfoTextPhone(
                            theme,
                            menuBackground,
                        ) }
                    >
                        By phone: <SmartLink
                            href={ `tel:${ phoneNumber }` }
                            style={ (styles.hamburgerMenuInfoTextPhone as any)(
                                theme,
                                menuBackground,
                            )}
                        >
                            { phoneNumber }
                        </SmartLink>
                    </Typography>
                    <HeadphoneKidIcon sx={ styles.icon } fill={ menuBackground
                        ? theme.palette.common.white
                        : theme.palette.text.primary
                    }/>
                </Box>
            </Box>
        </Collapse>
        <IosPwaMessage />
        <DialogMobileLayout
            dialogProps={{ open: notificationsOpened }}
            contentProps={{
                title: 'Notifications',
                isFullHeight: true,
                withActions: true,
                closeAction: () => setNotificationsOpened(false),
                onScroll: handleScroll,
                headerContent: <Box sx={ styles.notificationsHeaderContent }>
                    <NotificationsUnreadFilter />
                    <NotificationsMarkAll />
                </Box>,
                children: <Box sx={ styles.notificationsListContainer }>
                    <Accordion sx={ styles.topicsFilterContainer }>
                        <AccordionSummary sx={
                            styles.topicsFilterContainerSummary
                        }>
                            <NotificationsTopicsFilterTitle />
                        </AccordionSummary>
                        <AccordionDetails>
                            <NotificationsTopicsFilter/>
                        </AccordionDetails>
                    </Accordion>
                    <NotificationsList
                        onActionTapped={ () => setNotificationsOpened(false) }
                    />
                    <Box sx={ styles.notificationsListSpacer }/>
                </Box>,
            }}
        />
    </Box>;
};

export default MobileHeader;
