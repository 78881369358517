import { NextRequest } from 'next/server';
import { getClientIp } from 'request-ip';
import { Networks } from '@imqueue/net';
import { AUTH_NETWORKS_CACHE_KEY, cacheScope } from './cluster';
import { CURRENT_CONSUMER_ID } from '../constants';
import asyncLogger from '../logger';

export interface FormValue {
    value: string;
    error?: boolean;
}

export interface FormState {
    email: FormValue;
    fullName: FormValue;
    phone: FormValue;
    state: FormValue;
    subject: FormValue;
    message: FormValue;
}

export const RX_EMAIL =
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
;
export const RX_BIRTHDATE
    = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
;
export const RX_PASSWORD = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
export const RX_SSN = /^\d{3}-?\d{2}-?\d{4}$/;
export const RX_NUMBER = /^\d+$/;
export const RX_PHONE_NUMBER = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

export function isDateOfBirth(input: any): boolean {
    return RX_BIRTHDATE.test(input + '');
}

export function isEmail(input: any): boolean {
    return RX_EMAIL.test(input + '');
}

export const hasError = (form: FormState): boolean => {
    if (!form) {
        return false;
    }
    return Object.values(form).some(({ error }) => error);
};

async function allowedNetworks(): Promise<string[]> {
    if (cacheScope[AUTH_NETWORKS_CACHE_KEY]) {
        return cacheScope[AUTH_NETWORKS_CACHE_KEY];
    }

    const query = `query AuthNetworks($filter: NetworkListFilter!) {
        authNetworks(filter: $filter) {
            edges {
                node {
                    id
                    cidr
                }
            }
        }
    }`;
    const variables = {
        filter: {
            allowed: true,
            labels: ['ws'],
            consumerIds: [CURRENT_CONSUMER_ID],
        },
    };

    const url = process.env.API_URL || '';
    const apiKey = process.env.API_KEY || '';
    const headers = {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey,
    };
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ query, variables }),
    });
    const result = await response.json();

    asyncLogger.log('authNetworks response:', result);

    if (!result) {
        asyncLogger.error('Auth Error: authNetworks response is empty!');
        return [];
    }

    const { authNetworks } = result.data;

    cacheScope[AUTH_NETWORKS_CACHE_KEY] = authNetworks.edges.map(
        (edge: any) => edge.node.cidr,
    );

    return cacheScope[AUTH_NETWORKS_CACHE_KEY];
}

export async function accessDenied(req: NextRequest): Promise<boolean> {
    const networks = await allowedNetworks();

    // if no network restriction set
    if (!networks.length) {
        return false;
    }

    const clientIp = getClientIp(req as any);

    if (clientIp) {
        const networksList = new Networks(networks);
        // if client ip is not a part of allowed networks
        return !networksList.includes(clientIp);
    }

    // if no network restriction set or no client IP defined (local)
    return false;
}
