import React, { FunctionComponent } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import { isLight } from '../common/helpers';
import { CONTENT_MARGIN_TOP } from '../config';

const styles = {
    paper: (theme: Theme): SxProps => ({
        marginTop: `-${ CONTENT_MARGIN_TOP }px`,
        paddingLeft: '40px',
        paddingRight: '40px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
    }),
};

const MainDesktopPaper: FunctionComponent<PaperProps> = props => <Paper
    { ...props }
    sx={ theme => ({
        ...styles.paper(theme),
        ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
    })}
>
    { props.children }
</Paper>;

export default MainDesktopPaper;
