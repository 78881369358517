import { formatInTimeZone } from 'date-fns-tz';
import { format } from 'date-fns';
import {
    CURRENCY,
    LAST_DAY_OF_MONTH,
    LOCALE,
    PAY_FREQUENCIES_MAP,
} from '../../config/membersArea';
import { getKeyByValue } from './getKeyByValue';
import { EmploymentType, HolidaySkipping } from '../types/enums';

export interface timeDetails {
    hour: number,
    minute: number,
}

export interface PayFrequencyInfo {
    frequency: string;
    days: number[];
    holidaySkipping: HolidaySkipping,
    nextPaymentDate?: string,
}

export function formatMoney(data: string | number): string {
    const value = Number(data);

    return Intl.NumberFormat(LOCALE, {
        style: 'currency',
        currency: CURRENCY,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}

export function fromFormatMoney(data: string): string {
    if (data === '—') {
        return '';
    }

    return data.replace('$', '');
}

export function formatDateTime(date: string, time?: boolean): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (time) {
        const toDate = new Date(date);

        return formatInTimeZone(toDate, timeZone, 'MM/dd/yyyy HH:mm a');
    }

    const [datePart] = date?.split('T') || [];

    if (!datePart) {
        return '';
    }

    const [year, month, day] = datePart.split('-');

    return `${ month }/${ day }/${ year }`;
}

export function additionalFormatDate(date: string): string {
    const toDate = new Date(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return formatInTimeZone(toDate, timeZone, 'yyyy-MM-dd');
}

export function formatDayToNumber(day: string): number[] {
    switch (day) {
        case ('Monday'):
            return [1];
        case ('Tuesday'):
            return [2];
        case ('Wednesday'):
            return [3];
        case('Thursday'):
            return [4];
        default:
            return [5];
    }
}

export function getDayFromDate(date: string): string {
    return format(new Date(date), 'EEEE');
}

export function toOrdinalNumber(num: number): string {
    const s = String(num);

    return s[s.length-2] === '1' && s.length !== 1
        ? s + 'th'
        : s + (s[s.length-1] === '1'
            ? 'st'
            : s[s.length-1] === '2'
                ? 'nd'
                : s[s.length-1] === '3'
                    ? 'rd'
                    : 'th'
        )
    ;
}

export function minuteFormat(minute: number): string | number {
    return minute.toString().length > 1
        ? minute
        : '0' + minute
    ;
}

export function officeHoursFormat(
    start: timeDetails,
    end: timeDetails,
): string {
    const startMinute = minuteFormat(start.minute);
    const endMinute = minuteFormat(end.minute);

    return `Office hours: ${ start.hour % 12 || 12 }-${ startMinute } ${ start.hour < 12 ? 'AM' : 'PM' } - ${ end.hour % 12 || 12 }-${ endMinute } ${ end.hour < 12 ? 'AM' : 'PM' } EST`;
}

export function formatScalar(value: any) {
    return value !== undefined && value !== null && value !== ''
        ? (value + '')
        : '—';
}

export function formatNullableScalar(value: string) {
    return value !== '—' ? value : '';
}

export function toIncomeType(id?: string): EmploymentType {
    switch (id) {
        case '1':
            return EmploymentType.Employment;
        case '2':
            return EmploymentType.SocialSecurity;
        case '3':
            return EmploymentType.Disability;
        case '4':
            return EmploymentType.Retirement;
        case '5':
            return EmploymentType.SelfEmployed;
        case '6':
            return EmploymentType.Other;
        default:
            return EmploymentType.Employment;
    }
}

export function toPayFrequency(state: any) {
    const payFrequencyInfo: PayFrequencyInfo = {
        frequency: getKeyByValue(
            PAY_FREQUENCIES_MAP,
            state.payFrequency || state.payDayFrequency,
        ),
        days: [0],
        holidaySkipping: HolidaySkipping.After,
    };

    if (payFrequencyInfo.frequency === 'Weekly') {
        payFrequencyInfo.days = formatDayToNumber(
            state.weeklyDayOfTheWeek as string,
        );
    }

    if (payFrequencyInfo.frequency === 'BiWeekly') {
        payFrequencyInfo.days = formatDayToNumber(
            state.biWeeklyDayOfTheWeek as string,
        );
        payFrequencyInfo.nextPaymentDate = additionalFormatDate(
            state.biWeeklyLastPayDay as string,
        );
    }

    if (payFrequencyInfo.frequency === 'Monthly') {
        let convertedDayOfMonth = state.monthlyDayOfTheMonth;

        if (convertedDayOfMonth === 'EOM') {
            convertedDayOfMonth = LAST_DAY_OF_MONTH;
        } else {
            convertedDayOfMonth = state.monthlyDayOfTheMonth?.substring(
                0,
                (state.monthlyDayOfTheMonth.length - 2),
            );
        }

        payFrequencyInfo.days = [Number(convertedDayOfMonth)];
    }

    if (payFrequencyInfo.frequency === 'SemiMonthly') {
        let convertedSecondDay = state.semiMonthlySecondDay;
        const convertedFirstDay = state.semiMonthlyFirstDay?.substring(
            0,
            (state.semiMonthlyFirstDay.length - 2),
        );

        if (convertedSecondDay === 'EOM') {
            convertedSecondDay = LAST_DAY_OF_MONTH;
        } else {
            convertedSecondDay = state.semiMonthlySecondDay?.substring(
                0,
                (state.semiMonthlySecondDay.length - 2),
            );
        }

        payFrequencyInfo.days = [
            Number(convertedFirstDay),
            Number(convertedSecondDay),
        ];
    }

    return payFrequencyInfo;
}

export function formatAccountNumber(accNumber: string): string {
    if (accNumber.length <= 4) {
        return '****' + accNumber;
    }

    return accNumber.replace(/.(?=.{4})/g, '*');
}

export function getAccountLastFourDigits(accNumber: string): string {
    const lastFour = accNumber.slice(-4);
    const padding = lastFour.length < 4 ? '#'.repeat(4 - lastFour.length) : '';
    return padding + lastFour;
}

export function formatUpdatedFieldsToEmailText(
    customerId: string,
    updatedFields: { [key: string]: any }[],
): string {
    let emailText = 'Customer #' + customerId + ' wants to update the next '
        + 'information on the profile: \n\n'
    ;

    for (const field of updatedFields) {
        emailText = emailText + Object.keys(field)[0] + ': ' + Object.values(field)[0] + '\n';
    }

    return emailText;
}

export function formatNumberWithCommas(
    value?: number | string,
    round?: boolean,
): string {
    if (!value) {
        return '';
    }

    const copy = round ? Number(value).toFixed() : value;
    const strNum = String(copy);
    const parts = strNum.split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}

export function pad(num: string | number, paddedLength = 2): string {
    const str = num + '';

    if (str.length >= paddedLength) {
        return str;
    }

    return '0'.repeat (paddedLength - str.length) + str;
}
