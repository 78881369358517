import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CloseIcon(props: SvgIconProps) {
    return <SvgIcon
        viewBox={ '0 0 14 14' }
        fill={ 'none' }
        xmlns={ 'http://www.w3.org/2000/svg' }
        { ...props }
    >
        <path
            d={ 'M6.99961 8.0501L1.74961 13.3001C1.59961 13.4501 1.42461 13.5251 1.22461 13.5251C1.02461 13.5251 0.849609 13.4501 0.699609 13.3001C0.549609 13.1501 0.474609 12.9751 0.474609 12.7751C0.474609 12.5751 0.549609 12.4001 0.699609 12.2501L5.94961 7.0001L0.699609 1.7501C0.549609 1.6001 0.474609 1.4251 0.474609 1.2251C0.474609 1.0251 0.549609 0.850098 0.699609 0.700098C0.849609 0.550098 1.02461 0.475098 1.22461 0.475098C1.42461 0.475098 1.59961 0.550098 1.74961 0.700098L6.99961 5.9501L12.2496 0.700098C12.3996 0.550098 12.5746 0.475098 12.7746 0.475098C12.9746 0.475098 13.1496 0.550098 13.2996 0.700098C13.4496 0.850098 13.5246 1.0251 13.5246 1.2251C13.5246 1.4251 13.4496 1.6001 13.2996 1.7501L8.04961 7.0001L13.2996 12.2501C13.4496 12.4001 13.5246 12.5751 13.5246 12.7751C13.5246 12.9751 13.4496 13.1501 13.2996 13.3001C13.1496 13.4501 12.9746 13.5251 12.7746 13.5251C12.5746 13.5251 12.3996 13.4501 12.2496 13.3001L6.99961 8.0501Z' }
        />
    </SvgIcon>;
}

export default CloseIcon;
