import React from 'react';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ContentLayoutProps } from '../../../../layouts/BaseLayout';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import SignOutIcon from '../../../../assets/SvgIcons/SignOutIcon';
import SignInIcon from '../../../../assets/SvgIcons/SignInIcon';
import SmartLink from '../../../SmartLink';
import useAuthorized from '../../../../common/hooks/useAuthorized';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    button: (theme: Theme) => ({
        color: theme.palette.warning.light,
        paddingRight: '6.5px',
        paddingTop: '23px',
        paddingBottom: '21px',
        cursor: 'pointer',
        ...theme.typography.body1,
        lineHeight: '26px',
        '&:hover': {
            color: '#e5ea08',
        },
    }),
};

const SignButton: React.FunctionComponent<ContentLayoutProps> = () => {
    const authorized = useAuthorized();
    const theme = useTheme();

    return <Box sx={ styles.container }>
        <Typography sx={ styles.button }>
            <SmartLink
                href={ authorized ? NamedRoute.LOGOUT : NamedRoute.LOGIN }
                style={{
                    color: theme.palette.warning.light,
                    whiteSpace: 'nowrap',
                }}
            >
                { authorized ? 'Sign out' : 'Sign in' }
            </SmartLink>
        </Typography>
        { authorized ? <SignOutIcon/> : <SignInIcon/> }
    </Box>;
};

export default SignButton;
