import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../../common/helpers';
import { MenuItemProps } from '../types';

const styles = {
    item: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        cursor: 'pointer',
        padding: '9px 15px',
        '&:hover': {
            backgroundColor: withoutThemeSwitcher
                ? theme.palette.common.white
                : isLight(theme)
                    ? theme.palette.common.white
                    : theme.palette.secondary.main
            ,
            borderRadius: '20px',
            '& > span': {
                color: withoutThemeSwitcher
                    ? theme.palette.common.black
                    : isLight(theme)
                        ? theme.palette.text.primary
                        : theme.palette.common.white,
            },
        },
    }),
    title: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: theme.palette.common.white,
    }),
};

const MainMenuItem: React.FunctionComponent<MenuItemProps> = props => {
    const theme = useTheme();

    return <Box sx={ styles.item(theme, props.withoutThemeSwitcher) }>
        <Typography
            component="span"
            sx={ styles.title }
            className="disable-text-selection"
        >
            { props.item.title }
        </Typography>
    </Box>;
};

export default MainMenuItem;
