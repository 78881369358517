import { EventEmitter } from 'events';
import deepEqual from './helpers/deepEqual';
import { RestMethod, ApiClient, ApiClientOptions } from './helpers';
import asyncLogger from './logger';

export interface AuthUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    resetToken: string;
    refreshToken: string | null;
    active: boolean;
    refreshedAt: string | null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    iat: number;
    customerId: string;
}

function getItem<T>(key: string): T | null {
    try {
        return JSON.parse(localStorage.getItem(key) as string);
    } catch (err) {
        return null;
    }
}

/**
 * Class Storage
 * Represents permanent Offline user's storage, to persist website data
 * and make its personal configurations more comfortable
 */
export class Storage extends EventEmitter {
    public static WATCH_TIMEOUT_MS = 500;

    public readonly USER_AUTHORIZED = 'userAuthorized';
    public readonly METADATA = 'websiteMetadata';
    public readonly THEME = 'theme';
    public readonly CREATE_APPLICATION_STEPS = 'createApplicationSteps';
    public readonly NOTIFICATIONS_FILTER = 'notificationsFilter';
    public readonly PERSONAL_INFO = 'personalInfo';
    public readonly ENABLE_BIOMETRIC_LOGIN = 'enableBiometricLogin';
    public readonly QA_TEST = 'qaTest';

    private _state: { [key: string]: any } = {};

    /**
     * @constructor
     */
    constructor() {
        super();

        const watchProps = Object.keys(this).filter(key =>
            key.charAt(0) !== '_',
        );

        for (const prop of watchProps) {
            const propName = (this as any)[prop];

            this._state[propName] = getItem(propName);
        }

        setInterval(() => {
            for (const prop of watchProps) {
                const propName = (this as any)[prop];
                const currValue = getItem(propName);
                const lastValue = this._state[propName];

                if (!deepEqual(currValue, lastValue)) {
                    this.emit('change', propName, currValue, lastValue);
                    this._state[propName] = currValue;
                }
            }
        }, Storage.WATCH_TIMEOUT_MS);
    }

    /**
     * Sets the value for a specified key. The value is automatically
     * synced to server's session storage, if sync param was not set to false.
     *
     * @param {string} key - key to persist data under
     * @param {any} jsonData - data to persist
     * @param {boolean} [sync] - if false, will not update server side session
     *                           storage
     * @return {Storage}
     */
    public set(key: string, jsonData: any, sync = false): Storage {
        const oldItem = getItem(key);
        const update = () => {
            jsonData = typeof jsonData === 'undefined' ? null : jsonData;
            localStorage.setItem(key, JSON.stringify(jsonData));
            this._state[key] = jsonData;

            this.emit('change', key, jsonData, oldItem);
            sync && this.emit('sync', key, jsonData, oldItem);
        };

        if (sync) {
            this.sync(key, jsonData).then(update);
        } else {
            update();
        }

        return this;
    }

    /**
     * Retrieves data from a storage, persisted under given key
     *
     * @param {string} key
     * @return {any | null}
     */
    public get<T>(key: string): T | null {
        if (!key) {
            return null;
        } else {
            return getItem(key);
        }
    }

    /**
     * Removes data from storage which persisted under given key. This will
     * automatically delete the data from a server side session storage if sync
     * parameter is not set to false.
     *
     * @param {string} key - key to delete data for
     * @param {boolean} [sync] - f false, will not update server side session
     *                           storage
     * @return {Storage}
     */
    public del(key: string, sync = false): Storage {
        const item = getItem(key);
        const update = () => {
            delete this._state[key];
            localStorage.removeItem(key);

            this.emit('change', key, undefined, item);
            sync && this.emit('sync', key, undefined, item);
        };

        if (sync) {
            this.sync(key).then(update);
        } else {
            update();
        }

        return this;
    }

    /**
     * Synchronizes clients local storage data to server side session storage
     *
     * @param {string} [key]
     * @param {any} [value]
     * @return {Promise<void>}
     */
    public async sync(key: string, value?: any): Promise<void> {
        const method = key && typeof value === 'undefined'
            ? RestMethod.DELETE
            : RestMethod.POST
        ;
        const options = { method } as ApiClientOptions;
        const endpoint = `/session/${ key }`;

        if (method === RestMethod.POST) {
            options.body = { [key]: value };
        }

        ApiClient.request(endpoint, options).catch(asyncLogger.error);
    }
}

const OfflineStore = new Storage();

OfflineStore.on('change', (
    key: string,
    data: string,
    oldData: string,
) => {
    const useStandardScrollbar = !window?.location?.pathname?.includes(
        'holiday',
    );

    if (key === 'theme' && data !== oldData && useStandardScrollbar) {
        const css = data === 'dark'
            ? `* {
                scrollbar-width: thin;
                scrollbar-color: rgba(56, 115, 28) #0d213a;
                scrollbar-gutter: auto;
                scroll-behavior: smooth;
            }`
            : `* {
                scrollbar-width: thin;
                scrollbar-color: rgba(56, 115, 28) transparent;
                scrollbar-gutter: auto;
                scroll-behavior: smooth;
            }`;
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.id='scrollbar-styles';

        head.appendChild(style);

        style.type = 'text/css';
        if ((style as any).styleSheet){
            // This is required for IE8 and below.
            (style as any).styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    }
});

export default OfflineStore;
