import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import {
    getCurrentMenuItem,
    handleMenuItemClick,
    isLight,
    prepareLink,
    setClickedMenuItem,
} from '../../../common/helpers';
import { DropdownType, MenuComponent, MenuItem, MenuProps } from '../types';
import Dropdown from './Dropdown';
import MainMenuItem from './Item';
import { useRouter } from 'next/router';
import SmartLink from '../../SmartLink';

const styles = {
    menu: (theme: Theme, backgroundColor?: string) => ({
        display: 'flex',
        backgroundColor: backgroundColor
            ? backgroundColor
            : theme.palette.primary.light
        ,
        boxShadow: backgroundColor ? 'unset' : theme.shadows[1],
        borderRadius: '20px',
        padding: '0 15px',
    }),
    selected: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.primary.dark
            : theme.palette.secondary.light,
        borderRadius: '20px',
    }),
};

const MainMenu: React.FunctionComponent<MenuProps> = (
    { menuItems, backgroundColor, withoutThemeSwitcher },
) => {
    const router = useRouter();
    const currentMenuItem = getCurrentMenuItem(router);
    const theme = useTheme();

    useEffect(() => {
        return () => {
            setClickedMenuItem('');
        };
    }, []);

    if (!menuItems || !menuItems.length) {
        return <></>;
    }

    function defineMenuItem(item: MenuItem, key: string) {
        const isSelected = currentMenuItem === item.link
            || currentMenuItem === '/' + item.link
        ;

        if (item.__component === MenuComponent.Item) {
            return <SmartLink
                style={ isSelected ? styles.selected(theme) : {} }
                key={ key }
                href={ prepareLink(item.link) }
                onClick={ (event: any) => {
                    handleMenuItemClick(
                        event,
                        isSelected,
                        item.link,
                    );
                }}
            >
                <MainMenuItem
                    item={ item }
                    withoutThemeSwitcher={ withoutThemeSwitcher }
                />
            </SmartLink>;
        }

        return <Box key={ key }>
            <Dropdown
                item={ item as DropdownType }
                withoutThemeSwitcher={ withoutThemeSwitcher }
            />
        </Box>;
    }

    return (
        <Box sx={ styles.menu(theme, backgroundColor) }>
            { menuItems.map((item: MenuItem) => {
                const key = `${item.id}-${item.title}`;

                return defineMenuItem(item, key);
            })}
        </Box>
    );
};

export default MainMenu;
