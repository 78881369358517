import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BarrierIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="224"
            height="154"
            viewBox="0 0 224 154"
            fill="none"
            { ...props }
        >
            <rect x="2" y="2" width="180" height="127" rx="8" stroke="#F39200" strokeWidth="4" fill="none"/>
            <path d="M1 10C1 5.02944 5.02944 1 10 1H174C178.971 1 183 5.02944 183 10V29H1V10Z" fill="#F39200" stroke="#F39200" strokeWidth="2"/>
            <circle cx="19" cy="15" r="4" fill="white"/>
            <circle cx="32" cy="15" r="4" fill="white"/>
            <circle cx="44" cy="15" r="4" fill="white"/>
            <rect x="68" y="59" width="154" height="34" fill="white" stroke="#F39200" strokeWidth="4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M70.9999 91.9999L84.9999 58.9999L100.5 59L86.5 91.9999L70.9999 91.9999Z" fill="#F39200"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M99.9999 91.9999L114 58.9999L129.5 59L115.5 91.9999L99.9999 91.9999Z" fill="#F39200"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M129 91.9999L143 58.9999L158.5 59L144.5 91.9999L129 91.9999Z" fill="#F39200"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M158 91.9999L172 58.9999L187.5 59L173.5 91.9999L158 91.9999Z" fill="#F39200"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M187 91.9999L201 58.9999L216.5 59L202.5 91.9999L187 91.9999Z" fill="#F39200"/>
            <rect x="75" y="94" width="8" height="60" fill="#F39200"/>
            <rect x="205" y="94" width="8" height="60" fill="#F39200"/>
        </SvgIcon>
    );
}

export default BarrierIcon;
