import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { MenuItemProps } from '../types';
import {
    getClickedMenuItem,
    getCurrentMenuItem,
    prepareLink,
    setClickedMenuItem,
} from '../../../common/helpers';
import { useMenu } from '../../../common/hooks/MenuProvider';
import { useRouter } from 'next/router';
import SmartLink from '../../SmartLink';

const styles = {
    item: (theme: Theme) => ({
        cursor: 'pointer',
        borderBottom: `1px solid ${ theme.palette.common.white }`,
        padding: '20px 0',
    }),
    title: (theme: Theme) => ({
        color: theme.palette.common.white,
        ...theme.typography.body1,
        lineHeight: '26px',
        '&:hover': {
            color: '#e8e8e8',
        },
    }),
};

const MobileMenuItem: React.FunctionComponent<MenuItemProps> = props => {
    const router = useRouter();
    const currentMenuItem = getCurrentMenuItem(router);
    const isMenuItemCurrentPage = currentMenuItem === props.item.link;
    const { setOpen } = useMenu();

    useEffect(() => {
        return () => {
            setClickedMenuItem('');
        };
    }, []);

    const handleMenuItemClick = (itemLink: string) => {
        setClickedMenuItem(itemLink);

        return true;
    };

    return <SmartLink
        href={ prepareLink(props.item.link) }
        onClick={ event => {
            setOpen(false);

            isMenuItemCurrentPage || props.item.link === getClickedMenuItem()
                ? event.preventDefault()
                : handleMenuItemClick(props.item.link);
        }}
    >
        <Box sx={ styles.item }>
            <Typography
                component="span"
                sx={ styles.title }
                className="disable-text-selection"
            >
                { props.item.title }
            </Typography>
        </Box>
    </SmartLink>;
};

export default MobileMenuItem;
