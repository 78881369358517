import { FC } from 'react';
import { SxProps, Theme, Typography, useTheme } from '@mui/material';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';

const styles = {
    title: (theme: Theme) => ({
        ...theme.typography.h6,
        fontWeight: 'normal',
    }),
};

const NotificationsTopicsFilterTitle: FC<{sx?: SxProps<Theme>}> = ({ sx }) => {
    const theme = useTheme();
    const {
        filter: { topicIds: { length: selected } },
        subscriptions: { length: total },
    } = useNotificationsInfo();

    return <Typography sx={{
        ...styles.title(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
    }}>
        <b>Events filter</b>
        {` (selected ${ selected } from ${ total })`}
    </Typography>;
};

export default NotificationsTopicsFilterTitle;
