export function genRecaptchaSessionId(): string {
    return Date.now() + Math.random() + '';
}

export function arrayRange(length: number): number[] {
    return new Array(length).fill(0).map((_, i) => i);
}

type BackgroundPositionsProps = {
    count: number;
    screenWidth: number;
    screenHeight: number;
    excludeWidthPercent: number;
    excludeHeightPercent: number;
};

/**
 * Generates random background positions outside of an excluded zone.
 *
 * @param count - The number of background image positions to generate.
 * @param screenWidth - The width of the screen in pixels.
 * @param screenHeight - The height of the screen in pixels.
 * @param excludeWidthPercent - The percentage of the screen width to exclude
 *        from generating positions.
 * @param excludeHeightPercent - The percentage of the screen height to exclude
 *        from generating positions.
 * @returns A string of image positions in the format 'x1% y1%, x2% y2%, ...'.
 */
export function backgroundPositions({
    count,
    screenWidth,
    screenHeight,
    excludeWidthPercent,
    excludeHeightPercent,
}: BackgroundPositionsProps): string {
    // Convert percents to pixels
    const excludeWidthPx = screenWidth * excludeWidthPercent / 100;
    const excludeHeightPx = screenHeight * excludeHeightPercent / 100;

    // Calculate the dimensions and position of the excluded rectangle in pixels
    const excludeX = (screenWidth - excludeWidthPx) / 2;
    const excludeY = (screenHeight - excludeHeightPx) / 2;
    let x, y;

    // Generate random coordinates until they are outside the excluded zone,
    // convert them to percentages and save as array of strings 'x% y%'
    const result = new Array(count).fill(0).map(() => {
        do {
            x = Math.random() * screenWidth;
            y = Math.random() * screenHeight;
        } while (
            x >= excludeX &&
            x <= excludeX + excludeWidthPx &&
            y >= excludeY &&
            y <= excludeY + excludeHeightPx
        );

        // Convert coordinates to percentages
        const xPercent = Number(((x / screenWidth) * 100).toFixed(1));
        const yPercent = Number(((y / screenHeight) * 100).toFixed(1));

        return `${ xPercent }% ${ yPercent }%`;
    });

    // return result as string of positions: 'x1% y1%, x2% y2%, ...'
    return result.join(', ');
}
