import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import errors from '../errors.json';
import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { isLight } from '../common/helpers';
import SadSmileIcon from '../assets/SvgIcons/SadSmileIcon';

export interface ErrorProps {
    code: string;
    showRefresh?: boolean;
    footer?: JSX.Element;
}

const styles = {
    errorOuter: () => ({
        display: 'table',
        width: '100%',
        height: '100%',
        minHeight: '500px',
    }),
    errorContainer: () => ({
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
    }),
    errorIcon: {
        width: '150px',
        height: '110px',
        marginBottom: '35px',
    },
    errorTitle: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.error.main
            : theme.palette.error.dark
        ,
        fontSize: '41px',
        fontWeight: 700,
        lineHeight: '38px',
        marginBottom: '15px',
    }),
    errorText: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: 'normal',
    }),
};

const Error: FunctionComponent<ErrorProps> = ({
    code,
    showRefresh,
    footer,
}) => {
    const error = errors[code as keyof typeof errors] as any;

    // noinspection JSUnresolvedReference
    return <Box
        sx={ styles.errorOuter }
        id={ code === '404' ? 'no-match-page-not-found' : undefined }
    >
        <Box sx={ styles.errorContainer }>
            <SadSmileIcon sx={ styles.errorIcon }/>

            <Typography
                component="h1"
                sx={ styles.errorTitle }
            >
                { error.h1 }
            </Typography>
            <Typography component="p" sx={ styles.errorText }>
                { error.p }
            </Typography>

            {( error.refreshCaption && showRefresh !== false &&
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: '64px', padding: '0 32px' }}
                    onClick={() => window.location.reload()}
                    data-testid="btn-error-page-reload"
                >
                    { error.refreshCaption }
                </Button>
            )}
            { footer }
        </Box>
    </Box>;
};

export default Error;
