/**
 * Creates and returns array of the given length filled from 0 to length -1
 * as values
 *
 * @param {number} length
 * @return {number[]}
 */
export default function arrayRange(length: number): number[] {
    return new Array(length).fill(0).map((v, i) => i);
}
