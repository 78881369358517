import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';

const styles = {
    officeStatus: (theme: Theme, isOfficeOpened: boolean) => ({
        display: 'inline-block',
        backgroundColor: isOfficeOpened
            ? isLight(theme)
                ? theme.palette.primary.main
                : theme.palette.primary.dark
            : theme.palette.error.main
        ,
        borderRadius: '6px',
        marginLeft: '3px',
        lineHeight: '14px',
        fontSize: '12px',
    }),
    officeStatusText: (theme: Theme) => ({
        display: 'inline-block',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '14px',
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        padding: '2px 5px 2px 5px',
    }),
};

const IsOfficeOpenedChip: FC<
    { isOfficeOpened: boolean }
> = ({ isOfficeOpened }) => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.officeStatus(theme, isOfficeOpened) }>
        <Typography component="span" sx={ styles.officeStatusText }>
            { isOfficeOpened ? 'open' : 'closed' }
        </Typography>
    </Box>;
};

export default IsOfficeOpenedChip;
