import React, { FunctionComponent } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { isLight } from '../../common/helpers';
import { useConfig } from '../../common/hooks/ConfigProvider';
import IsOfficeOpenedChip from './IsOfficeOpenedChip';
import { isOfficeOpened } from '../../common/helpers/isOfficeOpened';

const styles = {
    statusBox: (theme: Theme, topPadding?: string) => ({
        display: 'block',
        fontSize: '12px',
        paddingTop: topPadding ? topPadding : '7px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
    }),
    statusLabel: (
        theme: Theme,
        menuBackground?: string,
        textColor?: string,
    ) => ({
        fontSize: '12px',
        color: textColor
            ? textColor
            : menuBackground
                ? theme.palette.common.white
                : isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.common.white,
    }),
};

const IsOfficeOpened: FunctionComponent<{
    menuBackground?: string,
    topPadding?: string,
    textColor?: string,
}> = (
    { menuBackground, topPadding, textColor },
) => {
    const theme = useTheme();
    const { consumerOperationsDetails: { officeHours }} = useConfig() as any;
    const opened = isOfficeOpened(officeHours);

    return <Typography
        component="span"
        sx={ styles.statusBox(theme, topPadding) }
    >
        <Typography
            component="span"
            sx={ styles.statusLabel(theme, menuBackground, textColor) }
        >
            Currently&nbsp;
        </Typography>
        <IsOfficeOpenedChip isOfficeOpened={ opened }/>
    </Typography>;
};

export default IsOfficeOpened;
