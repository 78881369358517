export const paletteLight = {
    palette: {
        mode: 'light',
        primary: {
            main: '#68A34C',
            light: '#7BC159',
            dark: '#5E9A42',
        },
        success: {
            main: '#68A34C',
            light: '#71B153',
        },
        secondary: {
            main: '#13345E',
            light: '#164580',
            dark: '#0D213A',
        },
        divider: '#BCD1D9',
        action: {
            active: '#E7ECEE',
            selected: '#D5ECF4',
            disabled: '#BCD1D9',
        },
        info: {
            main: '#7899A6',
            light: '#BCD1D9',
        },
        warning: {
            main: '#EB9D06',
            dark: '#DA9205',
            light: '#FAFF00',
        },
        error: {
            main: '#EF4B4B',
            light: '#F65046', // ?
        },
        text: {
            secondary: '#4D4C4C',
            primary: '#0D213A',
        },
        loyalty: {
            bronze: '#C9B483',
            silver: '#A1A1A1',
            gold: '#F9A528',
            vip: '#0D213A',
            platinum: '#7B8695',
        },
    },
};

export const paletteDark = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#5E9A42',
            light: '#7BC159',
            dark: '#68A34C',
        },
        success: {
            main: '#5E9A42',
            light: '#71B153',
        },
        secondary: {
            main: '#13345E',
            light: '#164580',
            dark: '#0D213A',
        },
        divider: '#7899A6',
        action: {
            active: '#E7ECEE',
            disabled: '#BCD1D9',
            selected: '#D5ECF4',
        },
        info: {
            main: '#7899A6',
            light: '#BCD1D9',
        },
        warning: {
            main: '#FFAD0E',
            dark: '#DA9205',
            light: '#FAFF00',
        },
        error: {
            main: '#F75046',
            dark: '#EF4B4B',
            light: '#F65046',
        },
        text: {
            secondary: '#7899A6',
            primary: '#CCE5EE',
        },
        loyalty: {
            bronze: '#C9B483',
            silver: '#A1A1A1',
            gold: '#D9A032',
            vip: '#164580',
            platinum: '#7B8695',
        },
    },
};


