import React, { MouseEvent, useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Theme, useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    getCurrentMenuItem,
    handleMenuItemClick,
    isLight,
} from '../../../common/helpers';
import { DropdownType } from '../types';
import { useRouter } from 'next/router';
import SmartLink from '../../SmartLink';

const styles = {
    dropdown: (
        theme: Theme,
        open: boolean,
        withoutThemeSwitcher?: boolean,
    ) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        padding: '9px 15px',
        boxShadow: 'none',
        backgroundColor: open ?
            withoutThemeSwitcher
                ? theme.palette.common.white
                : isLight(theme)
                    ? theme.palette.common.white
                    : theme.palette.secondary.main
            : 'inherit',
        '&:hover': {
            backgroundColor: withoutThemeSwitcher
                ? theme.palette.common.white
                : isLight(theme)
                    ? theme.palette.common.white
                    : theme.palette.secondary.main
            ,
            borderRadius: '20px',
            '& > div, span, svg': {
                color: withoutThemeSwitcher
                    ? theme.palette.common.black
                    : isLight(theme)
                        ? theme.palette.text.primary
                        : theme.palette.common.white
                ,
            },
        },
        '& .MuiButton-endIcon': {
            marginLeft: 0,
        },
    }),
    title: (theme: Theme, open: boolean, withoutThemeSwitcher?: boolean) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: open
            ? isLight(theme)
                ? theme.palette.text.primary
                : withoutThemeSwitcher
                    ? theme.palette.common.black
                    : theme.palette.common.white
            : theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        },
    }),
    menuPaper: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        mt: '11px',
        '& > ul': {
            backgroundColor: isLight(theme) || withoutThemeSwitcher
                ? theme.palette.common.white
                : theme.palette.secondary.main,
        },
    }),
    icon: (theme: Theme, open: boolean, withoutThemeSwitcher?: boolean) => ({
        color: open
            ? isLight(theme)
                ? theme.palette.text.primary
                : withoutThemeSwitcher
                    ? theme.palette.common.black
                    : theme.palette.common.white
            : theme.palette.common.white
        ,
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    menuItem: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        flexGrow: 1,
        padding: '0 32px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : withoutThemeSwitcher
                ? theme.palette.common.black
                : theme.palette.common.white
        ,
        '&:hover': {
            backgroundColor: isLight(theme) || withoutThemeSwitcher
                ? theme.palette.primary.light
                : theme.palette.secondary.light,
            color: theme.palette.common.white,
        },
    }),
    menuItemTitle: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '36px',
    }),
    divider: (theme: Theme, withoutThemeSwitcher?: boolean) => ({
        margin: '0 24px !important',
        borderColor: isLight(theme) || withoutThemeSwitcher
            ? theme.palette.divider
            : theme.palette.secondary.light,
    }),
    opened: (theme: Theme) => ({
        backgroundColor: theme.palette.common.white,
    }),
    selected: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.primary.dark
            : theme.palette.secondary.light,
        borderRadius: '20px',
    }),
    selectedItem: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.primary.light
            : theme.palette.secondary.light,
    }),
};

export interface DropdownProps {
    item: DropdownType;
    withoutThemeSwitcher?: boolean;
}

const Dropdown: React.FunctionComponent<DropdownProps> = props => {
    const router = useRouter();
    const theme = useTheme<Theme>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [isCurrentSection, setIsCurrentSection] = useState<boolean>(false);
    const currentItem = getCurrentMenuItem(router);
    const items = props.item.items.map(item => item.link);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setIsCurrentSection(
            items.includes(currentItem) ||
            items.includes('/' + currentItem),
        );
    }, [currentItem, items]);

    return <React.Fragment>
        <Button
            variant='text'
            endIcon={ <ArrowDropDownIcon sx={ styles.icon(
                theme,
                open,
                props.withoutThemeSwitcher,
            ) } /> }
            sx={{
                ...styles.dropdown(theme, open, props.withoutThemeSwitcher),
                ...(isCurrentSection ? styles.selected(theme) : {}),
            }}
            onClick={ handleClick }
        >
            <Typography
                component="span"
                sx={ styles.title(theme, open, props.withoutThemeSwitcher) }
                className="disable-text-selection"
            >
                { props.item.title }
            </Typography>
        </Button>
        <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            PaperProps={{ sx: {
                ...styles.menuPaper(
                    theme,
                    props.withoutThemeSwitcher,
                ),
            }}}
            disableScrollLock={ true }
        >
            { props.item.items.map((item, idx) => (
                <Box key={`${ item.id }-${ item.title }`}>
                    <SmartLink
                        key={`${ item.id }-${ item.title }`}
                        href={ `/${ item.link }` }
                        onClick={ ((event: any) => {
                            handleMenuItemClick(
                                event,
                                getCurrentMenuItem(router) === item.link,
                                item.link,
                            );
                        })}
                    >
                        <MenuItem
                            sx={{
                                ...styles.menuItem(
                                    theme,
                                    props.withoutThemeSwitcher,
                                ),
                                ...(currentItem === item.link ||
                                    currentItem === '/' + item.link
                                        ? styles.selectedItem(theme)
                                        : {}
                                ),
                            }}
                            onClick={ handleClose }
                        >
                            <Typography
                                component="span"
                                sx={ styles.menuItemTitle }
                                className="disable-text-selection"
                            >
                                { item.title }
                            </Typography>
                        </MenuItem>
                    </SmartLink>
                    { (idx < props.item.items.length - 1) &&
                        <Divider sx={ styles.divider(
                            theme,
                            props.withoutThemeSwitcher,
                        ) } />
                    }
                </Box>
            )) }
        </Menu>
    </React.Fragment>;
};

export default Dropdown;
