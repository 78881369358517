import axios from 'axios';
import cctlds from './cctlds';
import getConfigWithRequest from './getConfigWithRequest';
import refineUrl from './refineUrl';

const otherTlds = ['.net', '.org', '.biz', '.info', '.name'];

/**
 * Generates a string of wildcard domains with the given domain and top-level
 * domains (TLDs).
 * Example: "*.google.af *.google.ax ... *.google.uk *.google.us ..."
 *
 * @param domain - The base domain.
 * @returns A string of wildcard domains.
 */
const makeTldsString = (domain: string) => {
    // ['.af', '.ax', ... '.uk', '.us' ...]
    const countryTlds = cctlds.reduce(
        (agg, next) => agg.concat(next.tlds),
        [] as string[],
    );
    const tlds = countryTlds.concat(otherTlds);

    return tlds.map(zone => `*.${ domain }${ zone }`).join(' ');
};

const googleTlds = makeTldsString('google');

/**
 * Generates Content Security Policy (CSP) headers based on the specified
 * domains.
 *
 * @param domains - The domains to include in the CSP headers.
 * @returns The generated CSP headers.
 */
const makeCspHeaders = (domains: string) => {
    const hasGoogle = domains.includes('*.google.com');
    const scriptDomains = `${ domains } ${ hasGoogle ? googleTlds : '' }`;

    return "default-src * 'unsafe-inline' 'unsafe-eval';" +
        `script-src 'self' ${ scriptDomains } 'unsafe-inline' 'unsafe-eval';` +
        "connect-src * 'unsafe-inline';" +
        "img-src * data: blob: 'unsafe-inline';" +
        'frame-src *;' +
        "style-src * 'unsafe-inline';";
};

/**
 * Assigns the Content-Security-Policy header to the response based on
 * the provided origin. If the CSP header is not already set, it fetches
 * the CSP domains from the API and generates the CSP header.
 *
 * @param req - The request object.
 * @param res - The response object.
 * @param origin - The origin for which the CSP header is being assigned.
 */
const assignDomainString = async (req: any, res: any, origin: string) => {
    const scope = global as any;

    if (!scope.cspHeader) {
        const { apiUrl } = await getConfigWithRequest(req);
        const url = refineUrl(origin);
        const fullUrl = `${apiUrl}/consumer-id/${encodeURIComponent(url)}`;

        const { data } = await axios(fullUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const cspDomains = (data.cspDomains || '').replace(/,/g, '');
        scope.cspHeader = makeCspHeaders(cspDomains);
    }

    res.setHeader('Content-Security-Policy', scope.cspHeader);
};

export default assignDomainString;
