import React, { FunctionComponent } from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useConfig } from '../common/hooks/ConfigProvider';

const styles = {
    badge: (theme: Theme) => ({
        fontFamily: 'inter',
        fontWeight: 700,
        fontSize: '12px',
        textAlign: 'center',
        lineHeight: '16px',
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        cursor: 'pointer',
        textTransform: 'upperCase',
        backgroundColor: theme.palette.secondary.main,
        width: 108,
        height: 108,
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    }),
    badgeContent: (theme: Theme): SxProps<Theme> => ({
        '& .MuiBadge-badge': {
            width: '26px',
            height: '26px',
            padding: 'unset',
            borderRadius: '50%',
            border: `2px solid ${ theme.palette.common.white }`,
        },
    }),
    waButton: () => ({
        position: 'fixed',
        bottom: 0,
        marginLeft: '80%',
        marginBottom: '6.5%',
        zIndex: 200,
    }),
};

const ChatWithManager: FunctionComponent<BadgeProps> = props => {
    const { whatsAppSupportPhoneNumber } = useConfig();

    if (!whatsAppSupportPhoneNumber) {
        return null;
    }

    return <IconButton
        href={ `https://wa.me/${ whatsAppSupportPhoneNumber }` }
        sx={ props.sx || styles.waButton }>
        <Badge
            color="primary"
            overlap="circular"
            badgeContent=""
            sx={ (theme: Theme) => ({
                ...styles.badgeContent(theme),
                ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
            }) }
        >
            <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={ styles.badge }
            >
                Chat with agent
            </Box>
        </Badge>
    </IconButton>;
};

export default ChatWithManager;
