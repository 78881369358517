import { FC } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, SxProps, Typography } from '@mui/material';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';
import { useTheme, Theme } from '@mui/material/styles';

const styles = {
    link: (theme: Theme) => ({
        ...theme.typography.h6,
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
        '&:hover': {
            textDecoration: 'underline',
        },
        '.MuiSvgIcon-root': {
            margin: '0 4px',
        },
    }),
    text: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block',
    },
};

const NotificationsMarkAll: FC<{sx?: SxProps<Theme>}> = ({ sx }) => {
    const { markAllNotificationsAsRead } = useNotificationsInfo();
    const theme = useTheme();

    return <Typography
        component={'span'}
        sx={{
            ...styles.link(theme),
            ...(typeof sx === 'function' ? sx(theme) : sx),
        }}
        onClick={ markAllNotificationsAsRead }
    >
        <DoneAllIcon />
        <Box sx={ styles.text }>
            Mark all as read
        </Box>
    </Typography>;
};

export default NotificationsMarkAll;
