export interface ILogger {
    info(...args: any[]): void | Promise<void>;
    log(...args: any[]): void | Promise<void>;
    warn(...args: any[]): void | Promise<void>;
    error(...args: any[]): void | Promise<void>;
    debug(...args: any[]): void | Promise<void>;
    ignore(...args: any[]): void | Promise<void>;
}

/**
 * Primitive async logger
 *
 * @type {{
 *   warn(...args: any[]): void,
 *   log(...args: any[]): void,
 *   error(...args: any[]): void,
 *   info(...args: any[]): void
 *   debug(...args: any[]): void
 * }}
 */
const asyncLogger: ILogger = {
    info(...args: any[]) {
        setTimeout(() => console.info(...args));
    },
    log(...args: any[]) {
        setTimeout(() => console.log(...args));
    },
    warn(...args: any[]) {
        setTimeout(() => console.warn(...args));
    },
    error(...args: any[]) {
        setTimeout(() => console.error(...args));
    },
    debug(...args: any[]) {
        setTimeout(() => console.debug(...args));
    },
    ignore(..._: any[]) {}
};

export default asyncLogger;
