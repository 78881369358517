import { SvgIcon, SvgIconProps } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Theme, useTheme } from '@mui/material/styles';

function SignOutIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        component={ LogoutOutlinedIcon }
        { ...props }
        sx={{
            color: theme.palette.warning.light,
            ...props.sx,
        }}
    />;
}

export default SignOutIcon;
