import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function RadioCheckedIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 22 22'}
            width='22'
            height='22'
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <circle
                cx='11'
                cy='11'
                r='10.5'
                stroke={theme.palette.text.primary}
                fill='transparent'
            />
            <circle cx='11' cy='11' r='7' fill={theme.palette.text.primary} />
        </SvgIcon>
    );
}

export default RadioCheckedIcon;
