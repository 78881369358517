import { SvgIcon, SvgIconProps } from '@mui/material';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { Theme, useTheme } from '@mui/material/styles';

function ProfileOutlinedIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        component={ AccountCircleOutlined }
        { ...props }
        sx={{
            color: props.fill || theme.palette.common.white,
            fontSize: '20px',
            ...props.sx,
        }}
    />;
}

export default ProfileOutlinedIcon;
