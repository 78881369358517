import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Theme, useTheme } from '@mui/material/styles';
import { HEADER_BUTTON_HOVER_COLOR } from '../../../../config';

const styles = {
    button: (theme: Theme, withoutBurgerBackground?: boolean) => ({
        display: 'flex',
        alignItems: 'center',
        padding: '0px',
        cursor: 'pointer',
        backgroundColor: withoutBurgerBackground
            ? 'unset'
            : theme.palette.primary.light
        ,
        boxShadow: withoutBurgerBackground ? 'unset' : theme.shadows[1],
        borderRadius: '20px',
        height: '40px',
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: HEADER_BUTTON_HOVER_COLOR,
        },
    }),
    icon: (theme: Theme) => ({
        fontSize: '33px',
        color: theme.palette.common.white,
    }),
};

type BurgerMenuButtonProps = ButtonProps & {
    open: boolean;
    withoutBurgerBackground?: boolean;
};

const BurgerMenuButton: React.FunctionComponent<BurgerMenuButtonProps> = ({
    open,
    onClick,
    withoutBurgerBackground,
}) => {
    const theme = useTheme<Theme>();

    return <Button
        variant="contained"
        sx={ styles.button(theme, withoutBurgerBackground) }
        onClick={ onClick }
        data-testid="btn-menu-burger"
    >
        { open
            ? <CloseIcon sx={ styles.icon } />
            : <MenuIcon sx={ styles.icon }/>
        }
    </Button>;
};

export default BurgerMenuButton;
