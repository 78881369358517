import {
    Breakpoints,
    Components,
    createTheme,
    Shadows,
    Theme,
} from '@mui/material/styles';
import type { TypographyOptions } from '@mui/material/styles/createTypography';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import WarningSharpIcon from '@mui/icons-material/WarningSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarMonthOutlinedIcon from
    '@mui/icons-material/CalendarMonthOutlined';
import arrayRange from './helpers/arrayRange';
import { THEME_BREAKPOINTS } from './constants';
import CheckboxIcon from '../assets/SvgIcons/CheckboxIcon';
import CheckboxCheckedIcon from '../assets/SvgIcons/CheckboxCheckedIcon';
import RadioIcon from '../assets/SvgIcons/RadioIcon';
import RadioCheckedIcon from '../assets/SvgIcons/RadioCheckedIcon';
import { isDark, isLight } from './helpers';
import { paletteDark, paletteLight } from './palettes';
import { useEffect } from 'react';
import CheckboxIndeterminateIcon
    from '../assets/SvgIcons/CheckboxIndeterminateIcon';

export interface LoyaltyColor {
    bronze: string,
    silver: string,
    gold: string,
    vip: string,
    platinum: string,
}

declare module '@mui/material/styles' {
    // noinspection JSUnusedGlobalSymbols
    interface BreakpointOverrides {
        xs: false; // removes the `xs` breakpoint
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }

    // noinspection JSUnusedGlobalSymbols
    interface Palette {
        loyalty: LoyaltyColor,
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOverrides {
        loyalty: true;
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOptions {
        loyalty: LoyaltyColor,
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOptionsOverrides {
        loyalty: true;
    }
}

const components: Components<Theme> = {
    MuiAccordion: {
        defaultProps: {
            elevation: 0,
            disableGutters: true,
        },
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: 'none',
                '&:before': {
                    display: 'none',
                },
                width: '100%',
                '&.MuiPaper-root': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${ isLight(theme)
                        ? theme.palette.info.main
                        : theme.palette.info.light
                    }`,
                    borderRadius: '20px',
                    marginBottom: '8px',
                    '&.Mui-expanded': {
                        marginBottom: '8px',
                        backgroundColor: isLight(theme)
                            ? theme.palette.common.white
                            : theme.palette.secondary.main
                        ,
                    },
                },
            }),
        },
    },
    MuiAccordionSummary: {
        defaultProps: {
            disableRipple: true,
            disableTouchRipple: true,
            expandIcon: <ExpandMoreIcon sx={ ({ palette }) => ({
                color: palette.text.primary,
            }) } />,
        },
        styleOverrides: {
            root: {
                padding: '0 18.5px 0 25px',
                margin: 0,
            },
            content: {
                margin: 0,
                '&.Mui-expanded': {
                    margin: 0,
                },
            },
        },
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                padding: '10px 18.5px 18px 25px',
            },
        },
    },
    MuiAlert: {
        defaultProps: {
            iconMapping: {
                info: <InfoSharpIcon/>,
                warning: <WarningSharpIcon/>,
                error: <ErrorSharpIcon/>,
                success: <CheckCircleSharpIcon/>,
            },
        },
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                borderRadius: 0,
                minHeight: '40px',
                color: theme.palette.common.white,
                fontSize: theme.typography.fontSize,
                padding: 'unset',
                marginBottom: '10px',
                ...(ownerState.severity === 'error' && {
                    backgroundColor: theme.palette.error.main,
                }),
                ...(ownerState.severity === 'success' && {
                    backgroundColor: theme.palette.success.main,
                }),
                ...(ownerState.severity === 'warning' && {
                    backgroundColor: theme.palette.warning.main,
                }),
                '& .MuiAlert-icon': {
                    '& svg': {
                        fontSize: '18px',
                    },
                    color: theme.palette.common.white,
                    padding: '11px 0 11px 15px',
                },
                '& .MuiAlert-message': {
                    paddingTop: '11px',
                },
            }),
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            grouped: {
                borderRadius: 0,
                height: '50px',
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                textTransform: 'none',
                color: palette.text.primary,
                borderColor: isDark(palette)
                    ? palette.text.secondary
                    : palette.text.primary,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                    color: palette.text.primary,
                    backgroundColor: isDark(palette)
                        ? palette.secondary.main
                        : palette.action.selected,
                    outline: `4px solid ${isDark(palette)
                        ? palette.text.secondary
                        : palette.text.primary}`,
                    '&:hover': {
                        backgroundColor: isDark(palette)
                            ? palette.secondary.main
                            : palette.action.selected,
                    },
                },
            }),
        },
    },
    MuiButton: {
        defaultProps: {
            disableElevation: true,
        },
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                height: '50px',
                borderRadius: '20px',
                textTransform: 'none',
                boxShadow: theme.shadows[1],
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.primary.light,
                    boxShadow: theme.shadows[1],
                },
                '&:active': {
                    boxShadow: theme.shadows[0],
                },
                '&:disabled': {
                    backgroundColor: theme.palette.action.disabled,
                    color: theme.palette.info.main,
                },
                ...(ownerState.size === 'small' && {
                    height: '32px',
                }),
                ...(ownerState.size === 'large' && {
                    height: '80px',
                    fontSize: '24px',
                    borderRadius: '30px',
                }),
                ...(isDark(theme.palette) && {
                    '&:disabled': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.info.main,
                    },
                }),
            }),
            contained: ({ ownerState, theme: { palette } }) => ({
                ...(ownerState.color === 'secondary' && {
                    backgroundColor: palette.secondary.main,
                    color: palette.common.white,
                    '&:hover': {
                        backgroundColor: palette.secondary.light,
                    },
                }),
            }),
            outlined: ({ theme: { palette } }) => ({
                ...(isDark(palette) ? {
                    borderColor: palette.text.primary,
                    color: palette.text.primary,
                    '&:hover': {
                        backgroundColor: palette.secondary.light,
                        borderColor: palette.text.primary,
                    },
                    '&:disabled': {
                        backgroundColor: palette.secondary.main,
                        color: palette.info.main,
                    },
                } : {
                    borderColor: palette.secondary.dark,
                    color: palette.secondary.dark,
                    '&:hover': {
                        backgroundColor: palette.action.active,
                        borderColor: palette.secondary.dark,
                        color: palette.text.primary,
                    },
                    '&:disabled': {
                        backgroundColor: palette.action.active,
                        borderColor: palette.action.disabled,
                        color: palette.action.disabled,
                    },
                }),
            }),
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                height: '26px',
                borderRadius: '10px',
            },
            icon: ({ theme }) => ({
                color: theme.palette.common.white,
                width: theme.typography.fontSize + 2,
                margin: '7px 0 7px 7px',
            }),
            label: ({ theme }) => ({
                color: theme.palette.common.white,
                fontSize: theme.typography.fontSize,
                fontWeight: 700,
                paddingLeft: '6px',
            }),
        },
    },
    MuiCheckbox: {
        defaultProps: {
            icon: <CheckboxIcon />,
            checkedIcon: <CheckboxCheckedIcon />,
            indeterminateIcon: <CheckboxIndeterminateIcon />,
        },
    },
    MuiRadio: {
        defaultProps: {
            icon: <RadioIcon />,
            checkedIcon: <RadioCheckedIcon />,
        },
    },
    MuiDatePicker: {
        defaultProps: {
            slots: {
                openPickerIcon: CalendarMonthOutlinedIcon,
            },
        },
    },
    MuiDateCalendar: {
        styleOverrides: {
            // TODO: needs clarification from the designer
            root: ({ theme: { palette } }: { theme: Theme }) => ({
                color: palette.text.primary,
                backgroundColor: isDark(palette)
                    ? palette.secondary.main
                    : palette.common.white,
                '& .MuiPickersArrowSwitcher-button': {
                    color: palette.text.primary,
                },
            }),
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                borderColor: isDark(palette)
                    ? palette.info.light
                    : palette.info.main
                ,
            }),
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: ({ theme: { palette, typography } }) => ({
                '&:hover': {
                    '& label': {
                        color: palette.text.primary,
                    },
                },
                '& label': {
                    color: isDark(palette)
                        ? palette.text.secondary
                        : palette.info.main
                    ,
                    fontSize: typography.fontSize,
                    lineHeight: '16px',
                    paddingLeft: '7px',
                },
                '& label.MuiInputLabel-shrink': {
                    transform: 'translate(12px, -6px) scale(0.85)',
                },
                '& label.MuiInputLabel-shrink.Mui-focused': {
                    color: palette.text.primary,
                },
                '& label.Mui-disabled': {
                    WebkitTextFillColor: (isDark(palette)
                        ? palette.text.secondary
                        : palette.info.main) + ' !important',
                },
            }),
        },
    },
    MuiMenu: {
        styleOverrides: {
            list: ({ theme }) => ({
                boxShadow: theme.shadows[1],
                backgroundColor: theme.palette.common.white,
            }),
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            divider: ({ theme }) => ({
                borderColor: theme.palette.info.light,
            }),
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: ({ theme: { palette, typography } }) => ({
                height: '50px',
                color: isDark(palette)
                    ? palette.text.secondary
                    : palette.text.primary,
                '&:hover': {
                    color: palette.text.primary,
                },
                '& fieldset': {
                    borderRadius: 0,
                },
                '& input': {
                    padding: '17px 24px 17px 21px',
                    color: isLight(palette)
                        ? palette.secondary.dark
                        : palette.info.light
                    ,
                    fontSize: typography.fontSize,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: palette.text.primary,
                },
                '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: isDark(palette)
                        ? palette.text.secondary
                        : palette.info.main,
                },
                '&.Mui-disabled > svg': {
                    color: isDark(palette)
                        ? palette.text.secondary
                        : palette.info.main,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: palette.text.primary,
                },
                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '2px',
                    borderColor: palette.error.main,
                },
            }),
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: theme.shadows[1],
                borderRadius: '20px',
            }),
        },
    },
    MuiSelect: {
        defaultProps: {
            MenuProps: {
                PaperProps: {
                    // TODO: needs clarification from the designer
                    sx: theme => ({
                        backgroundColor: isDark(theme)
                            ? 'secondary.main'
                            : 'common.white',
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                backgroundColor: isDark(theme)
                                    ? 'secondary.light'
                                    : 'action.selected',
                            },
                        },
                        '& .MuiMenu-list': {
                            backgroundColor: isDark(theme)
                                ? 'secondary.main'
                                : 'action.selected',
                        },
                    }),
                },
            },
            IconComponent: props => {
                return <KeyboardArrowDownIcon { ...props } />;
            },
        },
        styleOverrides: {
            icon: ({ theme: { palette } }) => ({
                right: '10px',
                fontSize: '32px',
                color: palette.text.primary,
            }),
        },
    },
    MuiSwitch: {
        defaultProps: {
            focusRipple: false,
            disableTouchRipple: true,
            disableFocusRipple: true,
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                width: 37,
                height: 20,
                padding: 0,
                borderRadius: '20px',
                marginRight: '9px',
                display: 'flex',
            },
            switchBase: ({ theme }) => ({
                padding: 2,
                color: theme.palette.secondary.dark,
                '&.Mui-checked': {
                    transform: 'translateX(17px)',
                    color: theme.palette.common.white,
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            }),
            thumb: ({ theme }) => ({
                marginLeft: 1,
                marginTop: 1,
                boxShadow: theme.shadows[1],
                width: 14,
                height: 14,
            }),
            track: ({ theme }) => ({
                opacity: 1,
                backgroundColor: theme.palette.info.light,
                boxSizing: 'border-box',
            }),
        },
    },
    MuiTableContainer: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: isLight(palette)
                    ? palette.text.primary
                    : palette.info.main
                ,
            }),
        },
    },
    MuiTableHead: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                backgroundColor: isLight(palette)
                    ? palette.action.selected
                    : palette.secondary.main
                ,
            }),
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                '&:last-child th': {
                    borderTop: 0,
                    borderBottom: `1px solid ${
                        isLight(palette)
                            ? palette.action.selected
                            : palette.info.main
                    }`,
                },
                '&, td:last-child': {
                    borderRight: 0,
                },
                '&:last-child td': {
                    borderBottom: 0,
                },
                '&, td:first-of-type': {
                    borderLeft: 0,
                },
            }),
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: ({ theme: { palette, typography } }) => ({
                fontSize: typography.fontSize,
                color: palette.text.primary,
                lineHeight: '16.41px',
                padding: '11px 0 13px 20px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: isLight(palette)
                    ? palette.info.light
                    : palette.info.main
                ,
            }),
            head: ({ theme: { typography } }) => ({
                ...typography.body2,
                lineHeight: '14px',
                padding: '10px 0 6px 20px',
                '&, td:first-of-type': {
                    borderLeft: 0,
                },
                '&:last-child': {
                    borderRight: 0,
                },
            }),
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ theme: { palette } }) => ({
                '& .Mui-disabled': {
                    WebkitTextFillColor: (isDark(palette)
                        ? palette.text.secondary
                        : palette.info.main) + ' !important',
                },
                '& .MuiInputBase-input' : {
                    fontSize: '16px',
                },
                '& .MuiInputAdornment-root': {
                    '& .MuiIconButton-root': {
                        color: palette.text.primary,
                    },
                    '& .Mui-disabled': {
                        color: isDark(palette)
                            ? palette.text.secondary
                            : palette.info.main,
                    },
                },
            }),
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: ({ theme: { palette } }) => ({
                color: palette.text.primary,
                backgroundColor: isDark(palette)
                    ? palette.secondary.main
                    : palette.action.active,
            }),
        },
    },
} as Components<Theme> & {
    MuiDatePicker: any;
    MuiDateCalendar: any;
};

const shadows: Shadows = [
    'none', ...arrayRange(24).map(
        i => `0px ${ (i + 1) * 2 }px ${ (i + 1) * 2 }px rgba(40, 61, 91, 0.2)`,
    ),
] as Shadows;

const typography: TypographyOptions = {
    subtitle1: {
        color: '#68A34C',
        fontSize: '14px',
        fontWeight: 600,
    },
    h1: {
        fontSize: '74px',
        fontWeight: 800,
    },
    h2: {
        fontSize: '41px',
        fontWeight: 600,
    },
    h3: {
        fontSize: '30px',
        fontWeight: 700,
    },
    h4: {
        fontSize: '24px',
        fontWeight: 600,
    },
    h5: {
        fontSize: '16px',
        fontWeight: 700,
    },
    h6: {
        fontSize: '14px',
        fontWeight: 600,
    },
    fontSize: 14,
    fontWeightRegular: 500,
    body1: {
        fontSize: '18px',
        fontWeight: 500,
    },
    body2: {
        fontSize: '12px',
        fontWeightRegular: 500,
    },
    button: {
        fontSize: '14px',
    },
};

const breakpoints: Breakpoints = {
    values: THEME_BREAKPOINTS,
} as Breakpoints;

export const themeLight = createTheme({
    ...paletteLight,
    typography,
    shadows,
    breakpoints,
    components,
} as any);

export const themeDark = createTheme({
    ...paletteDark,
    typography,
    shadows,
    breakpoints,
    components,
} as any);

export enum KnownThemes {
    light = 'light',
    dark = 'dark',
}
export interface AvailableThemes {
    [KnownThemes.light]: Theme;
    [KnownThemes.dark]: Theme;
}

const themes = {
    [KnownThemes.light]: themeLight,
    [KnownThemes.dark]: themeDark,
} as AvailableThemes;

export default themes;

type MediaPrintStyleOptions = {
    dialog?: boolean
};

export function useMediaPrintStyle({ dialog }: MediaPrintStyleOptions) {
    useEffect(() => {
        const parent = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        style.innerText = `@media print {
            * { visibility: hidden; }
            .printable, .printable * {
                visibility: visible;
                color: #000000 !important;
                background-color: #ffffff !important;
            }
        }`;

        if (dialog) {
            style.innerText += `@media print {
                div :not(.printable, .printable *) {
                    visibility: hidden;
                    height: 0;
                }
                #__next > div > div :not(.printable, .printable *) {
                    display: none;
                }
            }`;
        }

        parent.appendChild(style);

        return () => {
            parent.removeChild(style);
        };
    }, [dialog]);
}
