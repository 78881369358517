import asyncLogger from '../../logger';
import {
    onBackgroundMessage,
    onForegroundMessage,
    resolvePostMessage,
    resolvePushEnabled,
    setDeviceToken,
    updateMessages,
} from '../pushNotifications';
import { ApiClient } from '../../clients/ApiClient';
import { initializeApp } from 'firebase/app';
import {
    getMessaging,
    isSupported,
    getToken,
    onMessage,
} from 'firebase/messaging';
import firebaseConfig from '../../../firebase-config.json';
import firebaseTokenOptions from '../../../firebase-token-options.json';
import { SwResources } from './swCommon';
import {
    ConsoleMessage,
    setConsoleHook,
    toConsoleMessage,
} from '../consoleHook';
import { platformHelper } from '../platformHelper';
import { RuntimeEnvironmentType } from '../../hooks/useRuntimeEnvironmentType';
import { EventEmitter } from 'events';

const queryString = require('querystring');

export class ServiceWorkerRegistration extends EventEmitter {
    private readonly buildId = process.env.NEXT_BUILD_ID || '';

    registerWrapper() {
        (window as any).resolvePushEnabled = resolvePushEnabled;
        (window as any).setDeviceToken = setDeviceToken;
        (window as any).onForegroundMessage = onForegroundMessage;
        (window as any).updateMessages = updateMessages;
        (window as any).flutter_inappwebview.callHandler('init');

        setConsoleHook((message) => {
            message.origin!.apply(console, message.args);
            this.onConsole('site', toConsoleMessage(message));
        });
    }

    async registerServiceWorker(
        swResources: SwResources,
        runtimeEnvironmentType: RuntimeEnvironmentType,
    ): Promise<void> {
        try {
            await this.registerServiceWorkerImpl(
                swResources,
                runtimeEnvironmentType,
            );
        }
        catch (e) {
            asyncLogger.warn(e);
        }
    }

    private async registerServiceWorkerImpl(
        swResources: SwResources,
        runtimeEnvironmentType: RuntimeEnvironmentType,
    ): Promise<void> {
        navigator.serviceWorker.onmessage = event => {
            switch (event.data.type) {
                case 'backgroundMsg': {
                    onBackgroundMessage(event.data.message);
                    break;
                }
                case 'console': {
                    this.onConsole('sw', event.data.message);
                    break;
                }
                case 'refresh': {
                    this.emit('refresh');
                    break;
                }
                case 'fetch': {
                    this.emit('fetch');
                    break;
                }
                case 'invalidated': {
                    const { data } = event.data;
                    this.emit('invalidated', data.urls, data.id);
                    break;
                }
            }
        };

        let registrationRequired = true;
        let registration = await navigator.serviceWorker.getRegistration();

        if (registration) {
            const serviceWorker = registration.active;

            if (serviceWorker) {
                const query = queryString
                    .parse(serviceWorker.scriptURL.split('?')[1]);

                registrationRequired =
                    decodeURIComponent(query.buildId) !== this.buildId;
            }
        }

        const initializedUrl: string[] = [];

        const initialize = () => {
            const serviceWorker =
                registration!.installing ||
                registration!.waiting ||
                registration!.active!;

            if (initializedUrl.indexOf(serviceWorker.scriptURL) !== -1) {
                return;
            }
            initializedUrl.push(serviceWorker.scriptURL);

            serviceWorker.postMessage(
                { type: 'init', runtimeEnvironmentType, ...swResources },
            );

            if (window.matchMedia) {
                const mediaQuery = window.matchMedia(
                    '(display-mode: standalone)',
                );

                mediaQuery.addEventListener('change', () => {
                    if (mediaQuery.matches) {
                        sessionStorage.setItem('env', 'pwa');
                        serviceWorker.postMessage({ type: 'prefetch' });
                    }
                });
            }
        };

        if (registrationRequired) {
            const updating = !!registration;

            registration = await navigator.serviceWorker.register(
                `/firebase-messaging-sw.js?buildId=${
                    encodeURIComponent(this.buildId)
                }`,
            );

            if (updating) {
                registration.addEventListener(
                    'updatefound',
                    initialize,
                    { once: true },
                );
            }
        }

        initialize();

        resolvePostMessage(
            message => registration!.active?.postMessage(message),
        );

        if (runtimeEnvironmentType !== RuntimeEnvironmentType.wrapper) {
            const pushEnabled = await this.isPushEnabled();
            ApiClient.request('/trackNotification', { body: {
                clientDate: new Date(),
                enabled: pushEnabled,
            } }).catch();

            resolvePushEnabled(pushEnabled);

            if (pushEnabled) {
                const app = initializeApp(firebaseConfig);
                const messaging = getMessaging(app);
                const token = await getToken(
                    messaging,
                    {
                        ...firebaseTokenOptions,
                        serviceWorkerRegistration: registration,
                    },
                );

                setDeviceToken('Pwa', token);
                onMessage(messaging, onForegroundMessage);
            }
        }
    }

    private async isPushEnabled(): Promise<boolean> {
        return !platformHelper.isIos &&
            await isSupported() &&
            (await Notification.requestPermission() === 'granted');
    }

    private onConsole(source: string, message: ConsoleMessage) {
        (window as any).flutter_inappwebview.callHandler(
            'console',
            source,
            message,
        );
    }
}

const serviceWorkerRegistration = new ServiceWorkerRegistration();

export { serviceWorkerRegistration };
