import React, { FunctionComponent, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useRouter } from 'next/router';

const AppPageLoadingBar: FunctionComponent = () => {
    const router = useRouter();
    const loader = (): HTMLElement =>
        (document.getElementById('linear-page-loader')
            || { style: {} }
        ) as HTMLElement;

    useEffect(() => {
        const enableLoading = () => {
            loader().style.display = 'block';
        };
        const disableLoading = () => {
            loader().style.display = 'none';
        };

        router.events.on('routeChangeStart', enableLoading);
        router.events.on('routeChangeComplete', disableLoading);
        router.events.on('routeChangeError', disableLoading);

        return () => {
            router.events.off('routeChangeStart', enableLoading);
            router.events.off('routeChangeComplete', disableLoading);
            router.events.off('routeChangeError', disableLoading);
        };
        // eslint-disable-next-line
    }, []);

    return <LinearProgress id="linear-page-loader" sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 9999999,
        display: 'none',
    }} />;
};

export default AppPageLoadingBar;
