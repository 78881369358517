import asyncLogger from '../../logger';

/**
 * Atomic locks for Service Workers based on IndexedDB
 * @example
 *   const lock = new SwLock('my-lock');
 *   if (await lock.acquire()) {
 *     // do something
 *     await lock.release();
 *   }
 *
 * Default deadlock timeout is 10 seconds.
 * To change it use
 * @example
 *   lock.timeout = 30; // 30 seconds
 *
 * To enable debug mode on particular lock instance use
 * @example
 *   lock.debug = true;
 *
 * To enable debug mode on all locks use
 * @example
 *   SwLock.debug = true;
 */
export default class SwLock {
    public static debug = false;
    public debug = false;
    public timeout = 10;

    private static readonly storeName = 'lock-store';
    private static readonly dbName = 'ws-sw-lock'
    private db?: IDBDatabase;
    private connecting?: Promise<IDBDatabase>;

    public constructor(
        private readonly name: string,
    ) {
        this.debug = this.debug || SwLock.debug;
    }

    public async acquire(): Promise<boolean> {
        const db = await this.connect();
        const tx = db.transaction(
            SwLock.storeName,
            'readwrite',
        );
        const store = tx.objectStore(SwLock.storeName);

        return new Promise<boolean>(resolve => {
            tx.oncomplete = () => {
                this.debug && asyncLogger.log('lock acquired');
                resolve(true);
            };
            tx.onerror = (e) => {
                this.debug && asyncLogger.log('lock error', e);
                const _tx = db.transaction(
                    SwLock.storeName,
                    'readonly',
                );
                const _store = _tx.objectStore(SwLock.storeName);

                _store.get(this.name).onsuccess = async (event: any) => {
                    const record = event.target.result;
                    this.debug && asyncLogger.log('lock record', record);

                    if (record && this.isOutdated(record)) {
                        this.debug && asyncLogger.log(
                            'lock record is outdated',
                        );
                        await this.release();

                        return resolve(await this.acquire());
                    }

                    resolve(false);
                }

                _tx.commit();
            };
            store.add({
                acquire: this.name,
                date: new Date().toISOString(),
            });
            tx.commit();
        });
    }

    public async release(): Promise<boolean> {
        const db = await this.connect();
        const tx = db.transaction(
            SwLock.storeName,
            'readwrite',
        );
        const store = tx.objectStore(SwLock.storeName);

        return new Promise<boolean>(resolve => {
            tx.oncomplete = () => {
                this.debug && asyncLogger.log('lock released');
                resolve(true);
            };
            tx.onerror = tx.onabort = () => {
                this.debug && asyncLogger.log('lock release error');
                resolve(false);
            };
            store.delete(this.name);
            tx.commit();
        });
    }

    private async connect(): Promise<IDBDatabase> {
        if (this.db) {
            return this.db;
        }

        if (this.connecting) {
            return await this.connecting;
        }

        return this.connecting = new Promise((resolve, reject) => {
            const request = indexedDB.open(SwLock.dbName);

            request.onupgradeneeded = () => {
                request.result.createObjectStore(
                    SwLock.storeName,
                    { keyPath: 'acquire' },
                );
            };
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    private isOutdated({ date }: { date: string }): boolean {
        if (!date) {
            return true;
        }

        return (Date.now() - new Date(date).getTime()) >= (this.timeout * 1000);
    }
}
