import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DropdownType } from '../types';
import Collapse from '@mui/material/Collapse';
import MobileMenuItem from './Item';
import { isLight } from '../../../common/helpers';

const styles = {
    dropdown: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '20px 0',
        cursor: 'pointer',
        boxShadow: 'none',
        backgroundColor: 'inherit',
        borderRadius: 0,
        borderBottom: `1px solid ${ theme.palette.common.white }`,
        '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
            '& > div, span, svg': {
                color: '#e8e8e8',
            },
        },
        '& .MuiButton-endIcon': {
            marginLeft: 0,
        },
    }),
    title: (theme: Theme) => ({
        color: theme.palette.common.white,
        ...theme.typography.body1,
        lineHeight: '26px',
    }),
    icon: (theme: Theme, open: boolean) => ({
        color: theme.palette.common.white,
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    list: (theme: Theme, withoutBackground?: boolean) => ({
        backgroundColor: withoutBackground
            ? 'unset'
            : isLight(theme)
                ? '#73A85B'
                : '#6C9F56'
        ,
        textAlign: 'center',
    }),
};

export interface DropdownProps {
    item: DropdownType;
    backgroundColor?: string;
}

const Dropdown: React.FunctionComponent<DropdownProps> = props => {
    const theme = useTheme<Theme>();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(() => !open);
    };

    return <Box>
        <Button
            variant='text'
            disableRipple
            endIcon={ <ArrowDropDownIcon sx={ styles.icon(theme, open) } /> }
            sx={ styles.dropdown }
            onClick={ handleClick }
        >
            <Typography
                component="span"
                sx={ styles.title(theme) }
                className="disable-text-selection"
            >
                { props.item.title }
            </Typography>
        </Button>
        <Collapse in={ open }>
            <Box sx={ styles.list(theme, !!props.backgroundColor) }>
                { props.item.items.map((item) => (
                    <Box key={`${ item.id }-${ item.title }`} >
                        <MobileMenuItem item={ item } />
                    </Box>
                )) }
            </Box>
        </Collapse>
    </Box>;
};

export default Dropdown;
