import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import { useConfig } from '../../../common/hooks/ConfigProvider';
import ThemeSwitcher from '../../../components/ThemeSwitcher';
import HeaderLogo from '../Logo';
import LoanIdButton from './Buttons/LoanId';
import { KnownBreakpoints } from '../../../common/constants';
import MyProfileButton from './Buttons/MyProfile';
import SignOutButton from './Buttons/SignOut';
import MainMenu from '../../Menu/Main';
import { MenuItem } from '../../Menu/types';
import MembersAreaButton from './Buttons/MembersArea';
import MobileMenu from '../../Menu/Mobile';
import BurgerMenuButton from './Buttons/BurgerMenu';
import { useMenu } from '../../../common/hooks/MenuProvider';
import { useAccountInfo } from '../../../common/hooks/AccountInfoProvider';
import Button from '@mui/material/Button';
import { currentPage, NamedRoute } from '../../../common/hooks/useNamedRoute';
import SignInIcon from '../../../assets/SvgIcons/SignInIcon';
import Tooltip from '@mui/material/Tooltip';
import { useRouter } from 'next/router';
import IosPwaMessage from '../IosPwaMessage';
import SmartLink from '../../SmartLink';
import { isLight, useIsTransform } from '../../../common/helpers';
import useAuthorized from '../../../common/hooks/useAuthorized';
import NotificationsButton from '../Notifications/NotificationsButton';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ClickAwayListener,
    Popover,
    Typography,
} from '@mui/material';
import NotificationsUnreadFilter
    from '../Notifications/NotificationsUnreadFilter';
import NotificationsMarkAll from '../Notifications/NotificationsMarkAll';
import NotificationsTopicsFilterTitle
    from '../Notifications/NotificationsTopicsFilterTitle';
import NotificationsTopicsFilter
    from '../Notifications/NotificationsTopicsFilter';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';
import NotificationsList from '../Notifications/NotificationsList';
import { LoanStatus } from '../../../common/types/enums';

const styles = {
    header: {
        display: 'flex',
        paddingTop: '29px',
        alignItems: 'center',
    },
    logo: {
        display: 'flex',
        flexGrow: 1,
        paddingRight: '20px',
    },
    rightContainer: (theme: Theme) => ({
        display: 'flex',
        flexGrow: 1,
        gap: '20px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingLeft: '10px',
        [theme.breakpoints.down(820)]: {
            gap: '10px',
        },
    }),
    menuWrapper: {
        height: 'auto',
        overflow: 'auto',
    },
    menu: (theme: Theme) => ({
        textAlign: 'center',
        marginTop: '20px',
        borderTop: `1px solid ${ theme.palette.common.white }`,
    }),
    button: (theme: Theme, open?: boolean) => ({
        height: '40px',
        width: '40px',
        padding: 'unset',
        minWidth: 'unset',
        boxShadow: 'none',
        backgroundColor: open ? theme.palette.primary.light : 'inherit',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: theme.shadows[1],
        },
    }),
    signIn: {
        fontSize: '26px',
        transform: 'translate(-3px)',
    },
    popover: (theme: Theme) => ({
        width: '100%',
        maxWidth: '500px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '8px',
        left: 'initial',
        right: '0',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            padding: '19px 25px 20px 29px',
            width: '100%',
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main
            ,
            backgroundImage: 'none',
        },
    }),
    notificationsTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    notificationsTitle: (theme: Theme) => ({
        ...theme.typography.h5,
        padding: '0 0 12px 0',
        color: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.text.primary,
    }),
    notificationsMarkAll: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.text.primary,
        '.MuiSvgIcon-root': {
            color: theme.palette.primary.dark,
            margin: '0 4px',
        },
    }),
    notificationsFiltersContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginTop: '16px',
    },
    notificationsUnreadFilter: (theme: Theme) => ({
        '.MuiToggleButtonGroup-grouped': {
            borderRadius: '19px',
            borderWidth: '1px',
            borderColor: isLight(theme)
                ? theme.palette.info.main
                : theme.palette.text.primary,
            padding: '0 16px',
            height: '38px',
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '.Mui-selected': {
            padding:  isLight(theme) ? '0 13px' : '0 16px',
            backgroundColor: `${isLight(theme)
                ? theme.palette.action.selected
                : theme.palette.text.primary} !important`,
            outline: 'none !important',
            color: `${isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.secondary.main} !important`,
            '&:hover': {
                backgroundColor: isLight(theme)
                    ? theme.palette.action.selected
                    : theme.palette.text.primary,
            },
            ...( isLight(theme)
                ? {
                    '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                        borderLeft: `4px solid ${theme.palette.info.main}`,
                    },
                    borderWidth: '4px',
                }
                : {}
            ),
        },
    }),
    notificationsTopicsFilter: (theme: Theme) => ({
        '&.MuiPaper-root': {
            padding: 0,
            margin: '0 0 0 16px',
            borderRadius: '19px',
            boxShadow: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            maxWidth: '260px',
            background: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main,
            '.MuiAccordionSummary-root': {
                minHeight: '38px',
            },
            '&.Mui-expanded': {
                backgroundColor: isLight(theme)
                    ? theme.palette.common.white
                    : theme.palette.secondary.dark,
            },
            zIndex: 999,
        },
    }),
    notificationsTopicsFilterTitle: {
        width: '100%',
        textAlign: 'center',
    },
    notificationsListContainer: {
        height: '360px',
        overflowY: 'auto',
        marginTop: '16px',
        paddingRight: '8px',
    },
    burgerMenuContainer: {
        paddingLeft: '3px',
    },
};

interface DesktopHeaderProps {
    menuItems: MenuItem[];
    setMenuOpen?: (open: boolean) => void;
    menuBackgroundColor?: string;
    withoutThemeSwitcher?: boolean;
    maintenanceCode?: string;
}

const DesktopHeader: React.FunctionComponent<DesktopHeaderProps> = props => {
    const authorized = useAuthorized();
    const theme = useTheme<Theme>();
    const { open, setOpen } = useMenu();
    const { consumerName } = useConfig();
    const { customer } = useAccountInfo();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );
    const isTransform = useIsTransform(theme);
    const currPage = currentPage(useRouter());

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const [ filterOpened, setFilterOpened ] = useState(false);

    const { handleScroll } = useNotificationsInfo();

    const loanId = customer?.currentLoan?.id;
    const meaningfulLoans = (customer?.currentLoans || [])?.filter(
        l => [
            LoanStatus.Active,
            LoanStatus.Pending,
            LoanStatus.PastDue,
        ].includes(l.status),
    );
    const initialLoans = meaningfulLoans?.sort((
        a,
        b,
    ) => a?.status?.localeCompare(b?.status || ''));
    const customerLoans = initialLoans.filter(i => i.id !== loanId);
    const customerLoansIds = [
        ...(customerLoans.map(i => i.id) || []),
        loanId || '',
    ];
    const hasMenu = props.menuItems && !!props.menuItems.length;

    useEffect(() => {
        if (!isTransform) {
            setOpen(false);
        }
    }, [isTransform, setOpen]);

    useEffect(() => {
        props.setMenuOpen && props.setMenuOpen(open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleClick = () => {
        setOpen(!open);
    };

    return <React.Fragment>
        <Box sx={ styles.header }>
            <Box sx={ styles.logo }>
                <HeaderLogo
                    title={ consumerName }
                    maintenanceCode={ props?.maintenanceCode }
                />
            </Box>

            { !isTransform && props.menuItems && !!props.menuItems.length &&
                <Box>
                    <MainMenu
                        menuItems={ props.menuItems }
                        backgroundColor={ props.menuBackgroundColor }
                        withoutThemeSwitcher={ props.withoutThemeSwitcher }
                    />
                </Box>
            }

            <Box sx={ styles.rightContainer }>
                { !props?.maintenanceCode && (!hasMenu
                    ? authorized
                        ? <React.Fragment>
                            { isDesktop && loanId &&
                                <LoanIdButton
                                    id={ loanId }
                                    additionalLoansIds={ customerLoansIds }
                                    customerLoans={ customerLoans }
                                />
                            }
                            <MyProfileButton />
                            <SignOutButton />
                        </React.Fragment>
                        : currPage !== NamedRoute.LOGIN
                            && <Tooltip
                                title="Sign In"
                                enterTouchDelay={ 0 }
                            >
                            <Button
                                variant='text'
                                sx={ styles.button(theme) }
                                component={ SmartLink }
                                href={ NamedRoute.LOGIN }
                                data-testid="btn-menu-sign-in"
                            >
                                <SignInIcon sx={ styles.signIn } />
                            </Button>
                        </Tooltip>
                    : <MembersAreaButton user={ Boolean(authorized) } />
                )}
                { !props.withoutThemeSwitcher && <ThemeSwitcher/> }
                { !props?.maintenanceCode && <NotificationsButton
                    selected={ !!anchorEl }
                    onClick={ e => setAnchorEl(e.currentTarget) }
                /> }
                <Popover
                    open={ !!anchorEl }
                    anchorEl={ anchorEl }
                    onClose={ () => setAnchorEl(null) }
                    disableScrollLock
                    anchorPosition={{ left: 0, top: 89 }}
                    anchorReference={ 'anchorPosition' }
                    sx={ styles.popover }
                >
                    <Box sx={ styles.notificationsTitleContainer }>
                        <Typography sx={ styles.notificationsTitle }>
                            Notifications
                        </Typography>
                        <NotificationsMarkAll
                            sx={ styles.notificationsMarkAll }
                        />
                    </Box>

                    <Box sx={ styles.notificationsFiltersContainer }>
                        <NotificationsUnreadFilter
                            sx={ styles.notificationsUnreadFilter }
                        />

                        <ClickAwayListener
                            onClickAway={ () => setFilterOpened(false) }
                        >
                            <Accordion
                                expanded={ filterOpened }
                                onChange={
                                    (_, expanded) => setFilterOpened(expanded)
                                }
                                sx={ styles.notificationsTopicsFilter }
                            >
                                <AccordionSummary sx={{ padding: '0 8px' }}>
                                    <NotificationsTopicsFilterTitle
                                        sx={
                                            styles
                                                .notificationsTopicsFilterTitle
                                        }
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <NotificationsTopicsFilter/>
                                </AccordionDetails>
                            </Accordion>
                        </ClickAwayListener>
                    </Box>

                    <Box
                        sx={ styles.notificationsListContainer }
                        onScroll={ handleScroll }
                    >
                        <NotificationsList
                            onActionTapped={ () => setAnchorEl(null) }
                        />
                    </Box>
                </Popover>
                { isTransform && props.menuItems && !!props.menuItems.length &&
                <Box sx={ styles.burgerMenuContainer }>
                    <BurgerMenuButton
                        open={ open }
                        onClick={ handleClick }
                        withoutBurgerBackground={ !!props.menuBackgroundColor }
                    />
                </Box> }
            </Box>
        </Box>
        { isTransform && <Collapse in={ open }>
            <Box sx={ styles.menuWrapper }>
                <Box sx={ styles.menu } >
                    <MobileMenu
                        menuItems={ props.menuItems }
                        backgroundColor={ props.menuBackgroundColor }
                    />
                </Box>
            </Box>
        </Collapse> }
        <IosPwaMessage />
    </React.Fragment>;
};

export default DesktopHeader;
