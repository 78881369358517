import React, {
    FunctionComponent,
    PropsWithChildren,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import themes, { AvailableThemes } from '../common/themes';
import OfflineStore from '../common/OfflineStore';
import Box from '@mui/material/Box';
import UAParser from 'ua-parser-js';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../common/constants';
import mediaQuery from 'css-mediaquery';
import { NextFont } from 'next/dist/compiled/@next/font';
import asyncLogger from '../common/logger';
import AppPageLoadingBar from './AppPageLoadingBar';

export type ThemeComponentProps = PropsWithChildren<{
    [name: string]: any;
    initMode: keyof AvailableThemes;
    font?: NextFont;
    userAgent?: string;
    ww?: number;
    wh?: number;
}>;

const ThemeComponent: FunctionComponent<ThemeComponentProps> = props => {
    const {
        children,
        initMode,
        font,
        userAgent,
        ww,
        wh,
        ...themeProps
    } = props;
    const [theme, setTheme] = useState<Theme>(themes[initMode]);
    const [mode, setMode] = useState<keyof AvailableThemes>(initMode);

    if (typeof process !== 'undefined' && process.env?.DEBUG) {
        asyncLogger.log(
            'ThemeComponent (referring window width, height):',
            ww,
            wh,
        );
    }

    const ssrMatchMedia = useCallback((query: any) => {
        const deviceType = (UAParser(userAgent).device.type ||
            KnownBreakpoints.desktop
        ) as KnownBreakpoints;

        return {
            matches: mediaQuery.match(query, {
                width: ww || THEME_BREAKPOINTS[deviceType] - 1,
            }),
        };
    }, [userAgent, ww]);

    Object.assign((theme.components || {}) as any, {
        MuiUseMediaQuery: { defaultProps: { ssrMatchMedia } },
    });

    const handleUpdate = async (key: string, value: keyof AvailableThemes) => {
        if (key === OfflineStore.THEME) {
            setMode(value || initMode);
            setTheme(themes[value || initMode]);
        }
    };

    useEffect(() => {
        OfflineStore.on('change', handleUpdate);

        const currentMode = OfflineStore.get<keyof AvailableThemes | null>(
            OfflineStore.THEME,
        );

        if (currentMode && mode !== currentMode) {
            setMode(currentMode);
            setTheme(themes[currentMode]);
            OfflineStore.sync(OfflineStore.THEME, currentMode)
                .catch(asyncLogger.error);
        }

        return () => {
            OfflineStore.off('change', handleUpdate);
        };
        // eslint-disable-next-line
    }, []);

    // noinspection TypeScriptValidateTypes
    return <ThemeProvider theme={ theme } { ...themeProps }>
        <Box className={ `theme ${ mode } ${ font?.className }` }>
            <AppPageLoadingBar />
            { children }
        </Box>
    </ThemeProvider>;
};

export default ThemeComponent;
