import React, { FunctionComponent } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme, useTheme } from '@mui/material/styles';
import SimplePage from '../../layouts/SingleBlockPage/SimplePage';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { KnownBreakpoints } from '../../common/constants';
import ConnectIcon from '../../assets/SvgIcons/ConnectIcon';
import { isLight } from '../../common/helpers';
import { useConfig } from '../../common/hooks/ConfigProvider';

export const styles = {
    loaderContainer: (theme: Theme, margin?: string) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            margin: margin ? margin : '100px 0px 0px 0px',
        },
    }),
    processLoaderContainer: (theme: Theme, margin?: string) => ({
        textAlign: 'center',
        marginTop: margin ? margin : '100px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            display: 'block',
            textAlign: 'center',
        },
    }),
    processLoaderIcon: {
        fontSize: '90px',
        animation: '2s linear 0s infinite normal none running spin',
    },
    loaderProgress: (theme: Theme) => ({
        transform: 'translateY(-50%)',
        color: theme.palette.primary.light,
        alignSelf: 'center',
    }),
    loaderTitle: (theme: Theme) => ({
        ...theme.typography.h3,
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
    }),
    processLoaderTitle: (theme: Theme) => ({
        ...theme.typography.body1,
        lineHeight: '24px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        marginTop: '10px',
    }),
    wrapper: {
        position: 'absolute',
        textAlign: 'center',
        zIndex: 0,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        padding: 0,
        margin: 0,
    },
    progress: (theme: Theme) => ({
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.primary.light,
        zIndex: Number.MAX_SAFE_INTEGER,
    }),
};

const LOADING_TEXT = 'Loading...';

export interface PageLoaderProps {
    title?: string;
    margin?: string;
    zIndex?: string | number;
}

export const ProcessLoader: FunctionComponent<PageLoaderProps> = props => {
    const theme = useTheme();
    const title = props.title || 'Loading...';

    return <Box sx={ styles.processLoaderContainer(theme, props.margin) }>
        <ConnectIcon sx={ styles.processLoaderIcon }/>
        <Typography sx={ styles.processLoaderTitle }>
            { title }
        </Typography>
    </Box>;
};

export const Loader: FunctionComponent<PageLoaderProps> = props => {
    const theme = useTheme();
    const title = props.title || LOADING_TEXT;

    return <Box sx={ styles.loaderContainer(theme, props?.margin) }>
        <Typography
            component="h3"
            textAlign="center"
            sx={ styles.loaderTitle }
        >
            { title }
        </Typography>
        <CircularProgress
            sx={{
                ...styles.loaderProgress(theme),
                zIndex: props.zIndex || Number.MAX_SAFE_INTEGER,
            }}
            size={ 80 }
        />
    </Box>;
};

const PageLoader: FunctionComponent<PageLoaderProps> = props => {
    const theme = useTheme();
    const title = props.title || LOADING_TEXT;
    const { consumerName } = useConfig();

    return <SimplePage title={ consumerName } withActions={ true }>
        <Typography
            component="h2"
            textAlign="center"
            sx={{
                marginBottom: '100px',
                color: isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.common.white,
            }}
        >
            { title }
        </Typography>
        <Box sx={ styles.wrapper }>
            <CircularProgress sx={ styles.progress } />
        </Box>
    </SimplePage>;
};

export default PageLoader;
