import { serviceWorkerRegistration as swRegistration } from './swRegistration';
import uuid from '../uuid';

export async function invalidateSwCache(urls: string[]): Promise<string[]> {
    return new Promise((resolve, reject) => {
        const id = uuid();
        const invalidateHandler = (
            invalidatedUrls: string[],
            invalidatedId: string,
        ) => {
            if (id === invalidatedId) {
                resolve(invalidatedUrls);
                swRegistration.off('invalidated', invalidateHandler);
            }
        };

        swRegistration.on('invalidated', invalidateHandler);

        navigator.serviceWorker.ready.then(registration => {
            const serviceWorker =
                registration!.installing ||
                registration!.waiting ||
                registration!.active!;

            serviceWorker.postMessage({ type: 'invalidate', urls, id });
        }).catch(reject);
    });
}
