import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function SadSmileIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="112"
            viewBox="0 0 150 112"
            fill="none"
            { ...props }
        >
            <rect x="2" y="2" width="146" height="102" rx="8" fill="white" stroke="#F65046" strokeWidth="4"/>
            <path d="M1 10C1 5.02944 5.02944 1 10 1H140C144.971 1 149 5.02944 149 10V23H1V10Z" fill="#F65046" stroke="#F65046" strokeWidth="2"/>
            <circle cx="14" cy="12" r="4" fill="white"/>
            <circle cx="26" cy="12" r="4" fill="white"/>
            <circle cx="38" cy="12" r="4" fill="white"/>
            <circle cx="54" cy="48" r="7" fill="#F65046"/>
            <circle cx="96" cy="48" r="7" fill="#F65046"/>
            <path d="M103.615 83.3848C88.024 67.7935 62.7455 67.7935 47.1543 83.3848" stroke="#F65046" strokeWidth="4" strokeLinecap="round" fill="white"/>
        </SvgIcon>
    );
}

export default SadSmileIcon;
