import { MenuComponent, MenuItemType } from '../../components/Menu/types';
import { NextRouter } from 'next/router';

const getRandomId = (max = 100) => Math.floor(Math.random() * max);

type BuildMenuItemProps = {
    title: string,
    link: string,
    icon?: JSX.Element,
}

export const buildMenuItem = (props: BuildMenuItemProps): MenuItemType => {
    const { title, link, icon } = props;
    return {
        id: getRandomId(),
        __component: MenuComponent.Item,
        title,
        link,
        icon,
    };
};

export const prepareLink = (link?: string): string => {
    if (!link) return '';
    return link.startsWith('/') ? link : `/${ link }`;
};

export let clickedMenuItem = '';
const RX_SLUG_CLEAN = /^\//;

export function getCurrentSlug(router: NextRouter): string {
    return (router?.query?.slug
        ? router?.query?.slug instanceof Array
            ? router?.query?.slug[0] : router?.query?.slug
        : router?.route) || ''
    ;
}

export function getCurrentMenuItem(router: NextRouter): string {
    return getCurrentSlug(router).replace(RX_SLUG_CLEAN, '');
}

export function setClickedMenuItem(slug: string) {
    clickedMenuItem = slug;
}

export function getClickedMenuItem(): string {
    return clickedMenuItem;
}

export const handleMenuItemClick = (
    event: any,
    isMenuItemCurrentPage: boolean,
    itemLink: string,
) => {
    if (isMenuItemCurrentPage || itemLink === getClickedMenuItem()) {
        event.preventDefault();

        return false;
    }

    setClickedMenuItem(itemLink);

    return true;
};

