import { parse } from 'cookie';

export function parseSetCookieHeader(headers: any) {
    if (!headers) {
        return {};
    }

    const cookie = headers['cookie'];
    const cookies = headers['set-cookie'] || [];

    if (!cookies?.length) {
        return {};
    }

    const cookiesObjects = Array.isArray(cookies)
        ? (cookies || [])?.map((i: any) => parse(i))
        : []
    ;

    return Object.assign({}, ...cookiesObjects, cookie ? parse(cookie) : {});
}
