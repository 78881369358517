import React, { Fragment, useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ThemeSwitcher from '../../components/ThemeSwitcher';
import CloseIcon from '../../assets/SvgIcons/CloseIcon';
import { ContentLayoutProps } from '../BaseLayout';
import SmartLink from '../../components/SmartLink';
import HeaderLogo from '../../components/Header/Logo';
import { useRouter } from 'next/router';

const styles = {
    headerTop: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
    },
    headerLocation: (theme: Theme) => ({
        backgroundColor: theme.palette.primary.main,
        padding: '34px 0 0',
    }),
    headerContent: (withSteps: boolean) => ({
        padding: `0 20px ${ withSteps ? '15px' : '20px' } 20px`,
    }),
    headerContentTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerContentSubTitle: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '16.41px',
        color: theme.palette.warning.light,
        marginTop: '3px',
        marginLeft: '40px',
    }),
    headerHalf: {
        display: 'flex',
        alignItems: 'center',
    },
    closeIconContainer: (theme: Theme) => ({
        marginLeft: '1.5vw',
        padding: '10px 10px 5px 10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '50%',
        },
    }),
    closeIcon: (theme: Theme) => ({
        fontSize: '14px',
        color: theme.palette.common.white,
    }),
    paymentIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paymentIcon: (theme: Theme) => ({
        color: theme.palette.common.white,
        width: '40px',
        height: '30px',
    }),
};

const MobileHeader: React.FunctionComponent<ContentLayoutProps> = props => {
    const router = useRouter();
    const currentPage = router.asPath;
    const isVerificationPage = currentPage.includes('/app/verify');
    const closeLink = isVerificationPage ? '/app/profile' : '/';

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                props.closeAction!();
            }
        };

        if (props.closeAction) {
            window.document.body.addEventListener('keydown', onKeyDown);
        }

        return () => window.document.body
            .removeEventListener('keydown', onKeyDown);

    }, [props.closeAction]);

    return <Box sx={ styles.headerTop }>
        <Box sx={ theme => ( !props.headerBackground
            ? { ...(styles.headerLocation as any)(theme) }
            : {
                ...(styles.headerLocation as any)(theme),
                backgroundColor: props.headerBackground,
            }
        )}>
            <Box sx={ styles.headerContent(
                !!props.subtitle && typeof props.subtitle !== 'string',
            ) }>
                <Box sx={ styles.headerContentTitle }>
                    <HeaderLogo
                        title={ props.title }
                        maintenanceCode={ props?.maintenanceCode }
                    />
                    <Box sx={ styles.headerHalf }>
                        { props.icon &&
                            <Box sx={ styles.paymentIconContainer }>
                                <Box sx={ styles.paymentIcon }>
                                    { props.icon }
                                </Box>
                            </Box>
                        }
                        <ThemeSwitcher />
                        { props.withActions &&
                            <Box
                                sx={ styles.closeIconContainer }
                                onClick={ props.closeAction }
                            >
                                { props.closeAction
                                    ? <CloseIcon sx={ styles.closeIcon } />
                                    : <SmartLink href={ closeLink }>
                                        <CloseIcon sx={ styles.closeIcon }/>
                                    </SmartLink>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
                { props.subtitle &&
                    <Fragment>
                        { typeof props.subtitle === 'string'
                            ? <Typography sx={ styles.headerContentSubTitle }>
                                { props.subtitle }
                            </Typography>
                            : <Box marginTop="5px">
                                { props.subtitle }
                            </Box> }
                    </Fragment>
                }
                { props.headerContent }
            </Box>
        </Box>
    </Box>;
};

const DialogLayoutMobileHeader: React.FunctionComponent<ContentLayoutProps>
    = props =>
{
    return <MobileHeader { ...props } />;
};

export default DialogLayoutMobileHeader;
