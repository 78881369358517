import { FunctionComponent, useState } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { Button } from '@mui/material';

interface ThrottlingButtonProps extends ButtonProps {
    timeout: number,
}

const ThrottlingButton: FunctionComponent<ThrottlingButtonProps> = props => {
    const { timeout, onClick, ...otherProps } = props;

    const [busy, setBusy] = useState(false);

    const handleClick = (event: any) => {
        if (busy) {
            event.stopPropagation();
            event.preventDefault();

            return ;
        }

        setBusy(true);
        setTimeout(() => { setBusy(false); }, timeout);

        onClick?.(event);
    };

    return <Button
        { ...(otherProps || {}) }
        onClick={ handleClick }
    >

    </Button>;
};

export default ThrottlingButton;
