import { IncomingMessage } from 'http';
import absoluteUrl from 'next-absolute-url';
import getRuntimeConfig from '../../config/runtimeConfig';

export default function getConfigWithRequest(req?: IncomingMessage) {
    const { origin } = absoluteUrl(req);

    const host = req?.headers['host'];
    const [, version] = host && host.split('.') || [];

    return getRuntimeConfig(origin, version);
}
