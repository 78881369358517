/**
 * Returns true if two given objects equal structure and values,
 * false - otherwise. Works for any input including objects and arrays.
 * Arrays comparison is sort insensitive.
 *
 * @param {any} x
 * @param {any} y
 * @return {boolean}
 */
export default function deepEqual(x: any, y: any): boolean {
    if (x instanceof Array && y instanceof Array) {
        if (x.length !== y.length) {
            return false;
        }

        const ax = [...x].sort();
        const ay = [...y].sort();

        for (let i = 0; i < ax.length; i++) {
            if (!deepEqual(ax[i], ay[i])) {
                return false;
            }
        }

        return true;
    }

    const keys = Object.keys;
    const typeofX = typeof x;
    const typeofY = typeof y;

    return x && y && typeofX === 'object' && typeofX === typeofY
        ? (
            keys(x).length === keys(y).length &&
            keys(x).every(key => deepEqual(x[key], y[key]))
        )
        : (x === y);
}
