const updaterHtml = `
<style>
    .outer {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .inner {
        color: #fff;
        font-size: 1.4rem;
        font-family: Roboto, arial, sans-serif;
        text-align: center;
        width: 25%;
        min-width: 220px;
    }
    .progress {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        height: .3rem;
        color: rgb(var(--primary-rgb, 40, 87, 18));
        background-color: rgb(var(--primary-rgb, 74, 133, 46));
        font-size: 16px;
        display: block;
        margin: 20px auto;
        width: 100%
    }
    .progress::-webkit-progress-bar {
        background-color: transparent;
    }
    /* Determinate */
    .progress::-webkit-progress-value {
        background-color: currentColor;
        transition: all 0.2s;
    }
    .progress::-moz-progress-bar {
        background-color: currentColor;
        transition: all 0.2s;
    }
    .progress::-ms-fill {
        border: none;
        background-color: currentColor;
        transition: all 0.2s;
    }
    /* Indeterminate */
    .progress:indeterminate {
        background-size: 200% 100%;
        background-image: linear-gradient(
            to right,
            transparent 50%,
            currentColor 50%,
            currentColor 60%,
            transparent 60%,
            transparent 71.5%,
            currentColor 71.5%,
            currentColor 84%,
            transparent 84%
        );
        animation: progress 2s infinite linear;
    }
    .progress:indeterminate::-moz-progress-bar {
        background-color: transparent;
    }
    .progress:indeterminate::-ms-fill {
        animation-name: none;
    }
    @keyframes progress {
        0% {
            background-size: 200% 100%;
            background-position: left -31.25% top 0%;
        }
        50% {
            background-size: 800% 100%;
            background-position: left -49% top 0%;
        }
        100% {
            background-size: 400% 100%;
            background-position: left -102% top 0%;
        }
    }
</style>
<div class="outer">
    <div class="inner">
        <span class="blinking">Updating&hellip;</span>
        <progress class="progress" />
    </div>
</div>
`;

// to debug this screen comfortably, uncomment code below and reload page
// in the browser
/*if (typeof window !== 'undefined') {
    window.onload = () => showUpdaterScreen();
}*/

export default function showUpdaterScreen() {
    if (!(document.body as any).outdated) {
        (document.body as any).outdated = true;
        document.body.innerHTML = updaterHtml;
        document.body.style.backgroundColor = '#5e9a42';
    }
}
