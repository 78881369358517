import React from 'react';
import DesktopHeaderButton from '.';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import SignOutIcon from '../../../../assets/SvgIcons/SignOutIcon';
import SmartLink from '../../../SmartLink';

const SignOutButton: React.FunctionComponent = () => {
    return <DesktopHeaderButton
        endIcon={ <SignOutIcon /> }
        component={ SmartLink }
        sx={ theme => ({
            width: '150px',
            padding: '12px 33.5px 12px 42px',
            color: theme.palette.warning.light,
            lineHeight: '16.41px',
            '& .MuiButton-endIcon': { marginLeft: '5.5px' },
            '&:hover': { color: theme.palette.primary.contrastText },
            whiteSpace: 'nowrap',
        })}
        href={ NamedRoute.LOGOUT }
        data-testid="btn-sign-out"
    >
        Sign Out
    </DesktopHeaderButton>;
};

export default SignOutButton;
