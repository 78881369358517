import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MoonIcon from '@mui/icons-material/DarkModeOutlined';
import LightIcon from '@mui/icons-material/LightModeOutlined';
import React, { FunctionComponent, useEffect, useState } from 'react';
import OfflineStore from '../common/OfflineStore';
import { AvailableThemes, KnownThemes } from '../common/themes';
import { isDark as isDarkTheme } from '../common/helpers';
import { Theme, useTheme } from '@mui/material/styles';

const styles = {
    themeButton: (theme: Theme) => ({
        ...theme.typography.body1,
        color: theme.palette.primary.contrastText,
        borderRadius: '50%',
        padding: 'unset',
        height: '40px',
        minWidth: 'unset',
        width: '40px',
        boxShadow: theme.shadows[0],
        '&:hover': {
            boxShadow: theme.shadows[0],
        },
    }),
};

const ThemeSwitcher: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    const [isDark, setIsDark] = useState<boolean>(isDarkTheme(theme));

    const handleUpdate = (key: string, value: any) => {
        if (key === OfflineStore.THEME) {
            setIsDark(value === KnownThemes.dark);
        }
    };

    useEffect(() => {
        OfflineStore.on('change', handleUpdate);

        setIsDark(OfflineStore.get<keyof AvailableThemes | null>(
            OfflineStore.THEME,
        ) === KnownThemes.dark);

        return () => {
            OfflineStore.off('change', handleUpdate);
        };
    }, []);

    const toggleTheme = () => {
        OfflineStore.set(
            OfflineStore.THEME,
            isDark ? KnownThemes.light : KnownThemes.dark,
            true,
        );
    };

    return <Tooltip
        title={ `Switch to ${ isDark ? 'Day' : 'Night' } mode` }
        enterTouchDelay={ 0 }
    >
        <Button
            onClick={ toggleTheme }
            sx={ styles.themeButton }
            data-testid="btn-switch-theme"
        >
            { isDark ? <LightIcon /> : <MoonIcon /> }
        </Button>
    </Tooltip>;
};

export default ThemeSwitcher;
