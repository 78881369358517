import React from 'react';
import Box from '@mui/material/Box';
import DesktopFooter from './DesktopFooter';
import BaseLayout, { ContentLayoutProps } from '../BaseLayout';

const styles = {
    footer: {
        marginTop: 'auto',
    },
};

export const Footer: React.FunctionComponent<
    { withChatWithManager: boolean; maintenanceCode?: string }
> = props => <Box sx={ styles.footer }>
    <DesktopFooter
        withChatWithManager={ props.withChatWithManager || false }
        maintenanceCode={ props?.maintenanceCode }
    />
</Box>;

const DesktopLayout: React.FunctionComponent<ContentLayoutProps> = props => {
    return <BaseLayout
        { ...props }
        footer={
            <Footer
                withChatWithManager={ props.withChatWithManager || false }
                maintenanceCode={ props?.maintenanceCode }
            />
        }
    >
        { props.children }
    </BaseLayout>;
};

export default DesktopLayout;
