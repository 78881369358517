export class PlatformHelper {
    private _isIos: boolean | undefined;
    private _isAndroid: boolean | undefined;
    private _isStandalone: boolean | undefined;

    get isIos(): boolean {
        if (this._isIos === undefined) {
            this._isIos = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes((navigator as any).platform) || (
                // iPad on iOS 13 detection
                navigator.userAgent.includes('Mac') && 'ontouchend' in document
            );
        }

        return this._isIos;
    }

    get isAndroid(): boolean {
        if (this._isAndroid === undefined) {
            const userAgent = navigator.userAgent;
            this._isAndroid = /Android/.test(userAgent)
                || /Android/.test(navigator.platform)
                || /android/i.test(userAgent)
                || /(android)/i.test(navigator.userAgent)
                || /Android/.test(navigator.userAgent);
        }
        return this._isAndroid;
    }

    get isStandalone(): boolean {
        if (this._isStandalone === undefined) {
            // noinspection JSUnresolvedReference
            this._isStandalone = !!(
                // (for iOS)
                (window.navigator as any).standalone ||
                // (for Desktop)
                (window.matchMedia &&
                    window.matchMedia('(display-mode: standalone)').matches) ||
                // (for Android)
                (
                    navigator.userAgent.toLowerCase().indexOf('android') > -1 &&
                    window.matchMedia &&
                    window.matchMedia('(display-mode: fullscreen)').matches
                )
            );
        }

        return this._isStandalone;
    }
}

const platformHelper = new PlatformHelper();

export { platformHelper };
