export type ThrottleCallback = (...args: any[]) => any;
export type AsyncThrottledCallback = (...args: any[]) => Promise<any>;

export interface DelayedThrottleContext {
    timeout: any;
}

// noinspection JSUnusedGlobalSymbols
/**
 * This throttle function fires callback after delay period. If any new call
 * made during delay period, it will abort previous upcoming call and installs
 * new call to be executed after delay (timer restarted).
 *
 * @param {ThrottleCallback} fn - callback to throttle
 * @param {number} delay - delay in ms
 * @return {{
 *   context: DelayedThrottleContext;
 *   callback: AsyncThrottledCallback;
 * }} - throttled context and callback (returns async version of the
 *      given callback)
 */
export const delayedThrottle = (
    fn: ThrottleCallback,
    delay: number,
): {
    context: DelayedThrottleContext;
    callback: AsyncThrottledCallback;
}  => {
    const context: DelayedThrottleContext = { timeout: null };
    const callback = async (...args: any[]): Promise<any> => {
        return new Promise((resolve) => {
            context.timeout && clearTimeout(context.timeout);
            context.timeout = setTimeout(() => resolve(fn(...args)), delay);
        });
    };

    return { context, callback };
};

// noinspection JSUnusedGlobalSymbols
/**
 * This throttle function fires callback immediately and then ignores other
 * calls during delay period.
 *
 * @param {ThrottleCallback} fn - callback to throttle
 * @param {number} delay - delay in ms
 * @return {ThrottleCallback} - throttled callback (returns the sane value as
 *                              the given callback)
 */
export const throttleIgnore = (
    fn: ThrottleCallback,
    delay: number,
): ThrottleCallback => {
    let waiting = false;

    return (...args: any[]): any => {
        if (!waiting) {
            const res = fn(...args);

            waiting = true;
            setTimeout(() => waiting = false, delay);

            return res;
        }
    };
};

// noinspection JSUnusedGlobalSymbols
/**
 * Best suitable for user-input events. Only last call will be executed.
 *
 * @param {ThrottleCallback} fn - callback to throttle
 * @param {number} delay - delay in ms
 * @return {ThrottleCallback} - throttled callback
 */
export const throttle = (
    fn: ThrottleCallback,
    delay: number,
): ThrottleCallback => {
    let callTimeout: any = null;

    return (...args: any[]): Promise<any> => {
        callTimeout && clearTimeout(callTimeout);

        return new Promise(resolve => {
            callTimeout = setTimeout(() => resolve(fn(...args)), delay);
        });
    };
};
