import React, { Fragment, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import LogoIcon from '../../assets/SvgIcons/LogoIcon';
import SmartLink from '../SmartLink';
import { getCurrentMenuItem, handleMenuItemClick } from '../../common/helpers';
import { useRouter } from 'next/router';
import {
    EXTRA_SMALL,
    KnownBreakpoints,
    MAINTENANCE_CONSUMER_WEBSITE,
} from '../../common/constants';

const styles = {
    headerHalfPointer: (notClickable?: boolean) => ({
        display: 'flex',
        alignItems: 'center',
        cursor: notClickable ? 'auto' : 'pointer',
    }),
    logoIcon: (theme: Theme) => ({
        width: '35px',
        height: '40.39px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            width: '26px',
            height: '30px',
        },
    }),
    logoTitle: (theme: Theme) => ({
        fontSize: '18px',
        fontWeight: 600,
        color: theme.palette.common.white,
        lineHeight: '21.09px',
        marginLeft: '12px',
        [theme.breakpoints.down(EXTRA_SMALL)]: {
            display: 'none',
        },
    }),
};

interface HeaderLogoProps {
    title: string;
    maintenanceCode?: string;
}

const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = (
    { title, maintenanceCode },
) => {
    const router = useRouter();
    const currentItem = getCurrentMenuItem(router);
    const [isSelected, setIsSelected] = React.useState(false);
    const commonChildren = <Fragment>
        <LogoIcon sx={styles.logoIcon} />
        <Typography sx={styles.logoTitle} className="disable-text-selection">
            {title}
        </Typography>
    </Fragment>;
    const notClickable = maintenanceCode === MAINTENANCE_CONSUMER_WEBSITE;

    useEffect(() => {
        setIsSelected(currentItem === '' || currentItem === '/');
    }, [currentItem]);

    return notClickable
        ? <text
            style={ styles.headerHalfPointer(notClickable) as any }
        >
            { commonChildren }
        </text>
        : <SmartLink
            style={ styles.headerHalfPointer(notClickable) as any }
            href="/"
            onClick={ (event: any) => {
                handleMenuItemClick(
                    event,
                    isSelected,
                    '/',
                );
            }}
        >
            { commonChildren }
    </SmartLink>;
};

export default HeaderLogo;
