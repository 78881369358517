import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Button } from '@mui/material';
import { platformHelper } from '../../common/helpers/platformHelper';
import CloseIcon from '@mui/icons-material/Close';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import React from 'react';

const hidePwaInfoKey = 'hidePwaInfo';

const styles = {
    pwaInfo: {
        marginTop: '4pt',
        backgroundColor: '#ffedb3',
        color: '#13345E',
        height: 'initial',
        width: '100%',
        '&:hover': {
            backgroundColor: '#ffe89e',
            color: '#13345E',
        },
        borderRadius: 0,
        boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.1)',
    },
    pwaInfoIcon: { width: '64px' },
    pwaInfoText: {
        width: '100%',
        fontSize: '14px',
        textAlign: 'center',
    },
    pwaInfoClose: {
        backgroundColor: 'transparent',
        color: '#13345E',
        boxShadow: 'none',
        width: '64px',
        paddingTop: '7px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#13345E',
            boxShadow: 'none',
            width: '64px',
        },
    },
};

const IosPwaMessage: React.FunctionComponent = () => {
    const router = useRouter();
    const [ showPwaInfo, setShowPwaInfo ] = useState<boolean>(false);

    useEffect(() => {
        setShowPwaInfo(
            platformHelper.isIos &&
            !platformHelper.isStandalone &&
            localStorage.getItem(hidePwaInfoKey)?.toLowerCase() !== 'true' &&
            sessionStorage.getItem(hidePwaInfoKey)?.toLowerCase() !== 'true',
        );
    }, []);

    return <React.Fragment>
        { showPwaInfo && <Button sx={ styles.pwaInfo } onClick={ () => {
            setShowPwaInfo(false);
            sessionStorage.setItem(hidePwaInfoKey, 'true');
            router.push('/pwa').catch();
        }}>
            <AppShortcutIcon sx={ styles.pwaInfoIcon } />
            <Box sx={ styles.pwaInfoText }>
                Click here to install application
            </Box>
            <Box
                onMouseDown={ (e) => e.stopPropagation() }
                onTouchStart={ (e) => e.stopPropagation() }
                onClick={(e) => {
                    e.stopPropagation();
                    setShowPwaInfo(false);
                    localStorage.setItem(hidePwaInfoKey, 'true');
                }}
                sx={ styles.pwaInfoClose }
            >
                <CloseIcon />
            </Box>
        </Button> }
    </React.Fragment>;
};

export default IosPwaMessage;
