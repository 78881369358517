import { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import SignInIcon from '../../../../assets/SvgIcons/SignInIcon';
import SmartLink from '../../../SmartLink';

const MobileSignInButton: FC = () => {
    return <Tooltip
        title="Sign In"
        enterTouchDelay={ 0 }
    >
        <SmartLink
            href={ NamedRoute.LOGIN }
            style={{
                height: '40px',
                width: '40px',
                padding: 'unset',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'inherit',
            }}
            data-testid="btn-sign-in"
        >
            <SignInIcon sx={{ fontSize: '26px' }} />
        </SmartLink>
    </Tooltip>;
};

export default MobileSignInButton;
