import asyncLogger from "./logger";
import { DEBUG } from './helpers/cluster';

/**
 * This is client-side cookie manipulation only
 */
export interface CookieOptions {
    expires?: Date;
    path?: string;
    domain?: string;
    secure?: boolean;
}

export default class Cookie {
    public static get(key: string, cookie?: string): string | undefined {
        if (!cookie && typeof document === 'undefined') {
            DEBUG && asyncLogger.error(
                'Cookie.get() is only available in browser',
            );
            return;
        }

        const splat = (cookie || document.cookie).split(/;\s*/);

        for (let i = 0; i < splat.length; i++) {
            const ps = splat[i].split('=');
            const k = decodeURIComponent(ps[0]);

            if (k === key) {
                return decodeURIComponent(ps[1]);
            }
        }
    }

    public static set(
        key: string,
        value: string,
        opts?: CookieOptions,
    ): string {
        if (typeof document === 'undefined') {
            DEBUG && asyncLogger.error(
                'Cookie.set() is only available in browser',
            );
            return '';
        }

        if (!opts) {
            opts = {};
        }

        let s = encodeURIComponent(key) +
            '=' +
            encodeURIComponent(value)
        ;

        if (opts.expires) {
            s += '; expires=' + opts.expires;
        }

        if (opts.path) s += '; path=' + encodeURIComponent(opts.path);
        if (opts.domain) s += '; domain=' + encodeURIComponent(opts.domain);
        if (opts.secure) s += '; secure';

        document.cookie = s;

        return s;
    }
}
