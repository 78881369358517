import { useEffect, useState } from 'react';

export enum RuntimeEnvironmentType { web, pwa, wrapper }

function detectAppType() {
    if (typeof window === 'undefined') { // not in browser
        return RuntimeEnvironmentType.web;
    }

    // noinspection JSUnresolvedReference
    if (
        window.matchMedia && (
            window.matchMedia('(display-mode: standalone)').matches ||
            window.matchMedia('(display-mode: fullscreen)').matches ||
            window.matchMedia('(display-mode: minimal-ui)').matches
        ) ||
        (window.navigator as any).standalone
    ) {
        return RuntimeEnvironmentType.pwa;
    }

    return RuntimeEnvironmentType.web;
}

export function getRuntimeEnvironmentType() {
    let env = sessionStorage.getItem('env');

    if (!env) {
        const params = new URLSearchParams(
            window.location.search,
        );

        env = (params.get('env')?.toLowerCase() || detectAppType()) as string;
        sessionStorage.setItem('env', env);
    }

    switch (env) {
        case 'pwa':
            return RuntimeEnvironmentType.pwa;
        case 'wrap':
            return RuntimeEnvironmentType.wrapper;
        default:
            return RuntimeEnvironmentType.web;
    }
}

export function useRuntimeEnvironmentType() {
    const [runtimeEnvironmentType, setRuntimeEnvironmentType] =
        useState<RuntimeEnvironmentType>(RuntimeEnvironmentType.web);

    useEffect(() => {
        setRuntimeEnvironmentType(
            getRuntimeEnvironmentType(),
        );
    }, []);

    return runtimeEnvironmentType;
}
