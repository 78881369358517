import {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import useAuthorized from './useAuthorized';
import { ApiClient, RestMethod } from '../helpers';
import asyncLogger from '../logger';
import OfflineStore from '../OfflineStore';
import {
    isPushEnabled,
    messaging,
} from '../../common/helpers/pushNotifications';
import { MessagePayload } from 'firebase/messaging';

const pageSize = 20;
const poolingTimeout = 30000;

export class Notification {
    constructor (notification: any) {
        this.assign(notification);
    }

    public readonly id!: string;
    public readonly title!: string;
    public readonly text!: string;
    public readonly url?: string;
    public sentAt!: Date;
    public isRead!: boolean;
    public eventName!: string;

    public assign(notification: any) {
        Object.assign(this, notification);
        this.sentAt = new Date(notification.sentAt);
    }

    public get sentAtDisplay(): string {
        const date = new Date(this.sentAt);
        const today = new Date();

        const secondsAgo =
            Math.floor((today.getTime() - date.getTime()) / 1000);
        const minutesAgo = Math.trunc(secondsAgo / 60);
        const hoursAgo = Math.trunc(minutesAgo / 60);

        if (hoursAgo < 6) {
            if (hoursAgo > 0) {
                return hoursAgo === 1
                    ? '1 hour ago'
                    : `${ hoursAgo } hours ago`;
            } else {
                if (minutesAgo > 0) {
                    return minutesAgo === 1
                        ? '1 minute ago'
                        : `${ minutesAgo } minutes ago`;
                } else {
                    return 'Now';
                }
            }
        }

        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() -1);

        const displayDate = date.getTime() === today.getTime()
            ? 'Today'
            : date.getTime() === yesterday.getTime()
                ? 'Yesterday'
                : `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

        const displayTime = this.sentAt.toLocaleTimeString(
            'en-us',
            { hour: '2-digit', minute: '2-digit' },
        );

        return `${displayDate} ${displayTime}`;
    }
}

export interface NotificationsFilter {
    unreadOnly: boolean;
    topicIds: string[];
}

export interface Subscription {
    id: string;
    displayName: string;
}

export interface NotificationsInfoState {
    visible: boolean;
    initializing: boolean;
    hasUnread: boolean;
    subscriptions: Subscription[];
    filter: NotificationsFilter;
    notifications: Notification[];
    timeKey: string;
    unreadKey: string;
    showLoader: boolean;
    setFilter: (filter: NotificationsFilter) => void;
    markRead: (notification: Notification) => Promise<void>;
    markAllNotificationsAsRead: () => void;
    handleScroll: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

const NotificationsInfoContext = createContext<NotificationsInfoState>(
    {} as NotificationsInfoState,
);

let showLoaderCount = 0;

const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const authorized = useAuthorized();
    const [initializing, setInitializing] = useState(true);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasUnread, setHasUnread] = useState(false);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [cursor, setCursor] = useState<string | undefined>();
    const [filter, setFilter] = useState<NotificationsFilter>({
        unreadOnly: false,
        topicIds: [],
    });
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [timeKey, setTimeKey] = useState(new Date().getTime().toString());
    const [unreadKey, setUnreadKey] = useState(new Date().getTime().toString());

    const updateUnread = async () => {
        try {
            const {
                data: { hasUnreadNotifications } =
                    { hasUnreadNotifications: false },
            } = await ApiClient.request(
                '/hasUnreadNotifications',
                { method: 'get' },
            );

            setHasUnread(hasUnreadNotifications);
        }
        catch (err) {
            asyncLogger.error(err);
        }
    };

    const updateTopics = async () => {
        try {
            const { data: { notificationSubscriptions: subscriptions } =
                    { notificationSubscriptions: [] } }:
                { data: { notificationSubscriptions: Subscription[] } } =
                await ApiClient.request(
                    '/getSubscriptions',
                    { method: RestMethod.GET },
                );

            setSubscriptions(subscriptions);
        }
        catch (err) {
            asyncLogger.error(err);
        }
    };

    const loadNotifications = async (
        cursor: string | undefined,
        filter: NotificationsFilter,
        previous: Notification[],
        showLoader: boolean = true,
    ) => {
        try {
            setNotificationsLoading(true);
            if (showLoader) {
                setShowLoader(!!++showLoaderCount);
            }

            const { data } = await ApiClient.request(
                '/getNotifications',
                {
                    method: 'get',
                    query: {
                        pageSize,
                        cursor,
                        unreadOnly: filter.unreadOnly,
                        topicIds: filter.topicIds,
                    },
                },
            );

            if (!data) {
                return;
            }

            const {
                notifications: {
                    pageInfo: {
                        endCursor,
                        hasNextPage,
                    },
                    edges,
                },
            } = data;

            setHasNextPage(hasNextPage);
            setCursor(endCursor);

            setNotifications([
                ...previous,
                ...edges.map((e: any) => new Notification(e.node)),
            ]);

        }
        catch (err) {
            asyncLogger.error(err);
        }
        finally {
            setNotificationsLoading(false);
            if (showLoader) {
                setShowLoader(!!--showLoaderCount);
            }
        }
    };

    const shiftCursor = (count: number) => {
        if (!cursor) {
            return;
        }

        const parsed = atob(cursor).split(':');
        const updated = `${ parsed[0] }:${ +parsed[1] + count }`;
        setCursor(btoa(updated));
    };

    const updateNotifications = async () => {
        try {
            const { data } = await ApiClient.request(
                '/getLatestNotifications',
                {
                    method: 'get',
                    query: {
                        lastMessageId: notifications.length
                            ? notifications[0].id
                            : undefined,
                        unreadOnly: filter.unreadOnly,
                        topicIds: filter.topicIds,
                    },
                },
            );

            if (!data) {
                return;
            }

            const { latestNotifications } = data;
            const count = latestNotifications.length;

            if (count) {
                setNotifications([
                    ...latestNotifications
                        .map((e: any) => new Notification(e)),
                    ...(notifications || []),
                ]);
                shiftCursor(count);
                await updateUnread();
            }
        } catch (e) {
            asyncLogger.error(e);
        }
    };

    useEffect(() => {
        // time labels updating
        const timer = setInterval(
            () => setTimeKey(new Date().getTime().toString()),
            30 * 1000,
        );

        return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (authorized) {
            Promise
                .all([updateUnread(), updateTopics()])
                .finally(() => setInitializing(false));

            const savedFilter = OfflineStore.get<NotificationsFilter>(
                OfflineStore.NOTIFICATIONS_FILTER,
            );

            if (savedFilter) {
                setFilter(savedFilter);
            }

            loadNotifications(cursor, savedFilter || filter, []);
        } else {
            setInitializing(true);
            setHasNextPage(false);
            setHasUnread(false);
            setSubscriptions([]);
            setCursor(undefined);
            setNotifications([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorized]);

    useEffect(() => {
        if (subscriptions?.length) {
            const topicIds = filter.topicIds;
            const updatedTopicIds = topicIds.filter(
                id => subscriptions.some(s => s.id === id),
            );
            if (topicIds.length !== updatedTopicIds.length) {
                const updatedFilter = { ...filter, topicIds: updatedTopicIds };
                setFilter(updatedFilter);
                OfflineStore.set(
                    OfflineStore.NOTIFICATIONS_FILTER,
                    updatedFilter,
                );
            }
        }
    }, [filter, subscriptions]);

    useEffect(() => {
        const listener = (message: MessagePayload) => {
            if (message?.data?.id) {
                setHasUnread(true);

                if (filter.topicIds.length &&
                    !filter.topicIds
                        .map(p => atob(p).split(':')[1])
                        .includes(message.data.topicId)) {
                    return;
                }

                const notification = new Notification({
                    id: message.data.id,
                    title: message.notification?.title,
                    text: message.notification?.body,
                    url: message.data.url,
                    isRead: false,
                    sentAt: new Date(),
                    eventName: message.data.eventName,
                });
                setNotifications([notification, ...(notifications || [])]);
                shiftCursor(1);
            }
        };

        let timer: any = null;
        let closed = false;

        (async () => {
            if (!await isPushEnabled() && !closed) {
                timer = setInterval(updateNotifications, poolingTimeout);
            }
        })();

        messaging.on('message', listener);
        messaging.on('update', updateNotifications);

        return () => {
            closed = true;

            if (timer) {
                clearInterval(timer);
            }

            messaging.removeListener('message', listener);
            messaging.removeListener('update', updateNotifications);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    const onFilterChange = (filter: NotificationsFilter) => {
        setFilter(filter);
        OfflineStore.set(OfflineStore.NOTIFICATIONS_FILTER, filter);
        setCursor(undefined);
        loadNotifications(undefined, filter, []);
    };

    const markAllNotificationsAsRead = async () => {
        if (!notifications){
            return;
        }

        try {
            await ApiClient.request('/markAllNotificationsAsRead');

            notifications.forEach(notification => notification.isRead = true);
            setUnreadKey(new Date().getTime().toString());
            setHasUnread(false);
        }
        catch (err) {
            asyncLogger.error(err);
        }
    };

    const markRead = async (notification: Notification) => {
        if (notification.isRead) {
            return;
        }

        notification.isRead = true;
        setUnreadKey(new Date().getTime().toString());

        try {
            const {
                data: { markNotification } = { markNotification: undefined },
            } = await ApiClient.request(
                '/markNotification',
                { body: { messageId: notification.id, isRead: true } },
            );

            if (!markNotification) {
                return;
            }

            notification.assign(markNotification);
            updateUnread();
        }
        catch (err) {
            asyncLogger.error(err);
        }
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target as any;

        if (scrollHeight - scrollTop - clientHeight < clientHeight &&
            !notificationsLoading && cursor && hasNextPage) {
            loadNotifications(cursor, filter, notifications, false);
        }
    };

    return <NotificationsInfoContext.Provider value={{
        visible: authorized,
        initializing,
        hasUnread,
        subscriptions,
        filter,
        notifications,
        timeKey,
        unreadKey,
        showLoader,
        setFilter: onFilterChange,
        markRead,
        markAllNotificationsAsRead,
        handleScroll,
    }}>
        { children }
    </NotificationsInfoContext.Provider>;
};

export function useNotificationsInfo(): NotificationsInfoState {
    return useContext(NotificationsInfoContext);
}

export default NotificationsProvider;
