import { SvgIcon, SvgIconProps } from '@mui/material';
import Icon from '@mui/icons-material/BusinessCenterOutlined';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';

function MembersAreaIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        component={ Icon }
        { ...props }
        sx={{
            fontSize: '24px',
            color: isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.secondary.dark
            ,
            ...props.sx,
        }}
    />;
}

export default MembersAreaIcon;
