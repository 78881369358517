import { utcToZonedTime } from 'date-fns-tz';
import { getHours, isWeekend } from 'date-fns';
import { isHoliday } from 'date-fns-holiday-us';

export interface Time {
    hour: number,
    minute: number,
}

export interface OfficeHours {
    start: Time,
    end: Time,
    timeZone: string,
}

export function isOfficeOpened(officeHours: OfficeHours): boolean {
    const timeZone = officeHours.timeZone;
    const officeStartTime = officeHours.start;
    const officeEndTime = officeHours.end;
    const currentDate = new Date();
    const timeZonedDate = utcToZonedTime(currentDate, timeZone);
    let isOfficeOpened = true;
    const timeZonedDateHours = getHours(timeZonedDate);

    if (isWeekend(timeZonedDate) || isHoliday(timeZonedDate)) {
        isOfficeOpened = false;
    }

    if (timeZonedDateHours < officeStartTime.hour
        ||  timeZonedDateHours >= officeEndTime.hour
    ) {
        isOfficeOpened = false;
    }

    return isOfficeOpened;
}
