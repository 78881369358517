import {
    createContext,
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ApiClient, RestMethod } from '../helpers';
import asyncLogger from '../logger';
import useAuthorized from './useAuthorized';
import { NextRouter, useRouter } from 'next/router';
import { invalidateSwCache } from '../helpers/service-worker/swCache';
import OfflineStore from '../OfflineStore';
import { PersonalInfo } from '../../components/HolidayMock/types';
import { NamedRoute } from './useNamedRoute';
import { Customer } from '../types/Customer';
import { Loan } from '../types/Loan';

export interface AccountInfoState {
    customer: Customer | null;
    loading: boolean;
    reload: (unsetCurrentLoanId?: boolean, dialog?: boolean) => Promise<void>;
    currentLoanId: string | null;
    setCurrentLoanId: Dispatch<SetStateAction<string | null>>;
}

const withAccountInfoRoutes = [
    NamedRoute.PROFILE,
    NamedRoute.SIGN_AGREEMENT,
    NamedRoute.BANK_VERIFICATION,
    NamedRoute.LOAN_HISTORY,
    '/app?dialog=apply',
];

const AccountInfoContext = createContext<AccountInfoState>(
    {} as AccountInfoState,
);

// noinspection JSUnusedLocalSymbols
function reloadWithoutParam(router: NextRouter, param: string) {
    const { pathname, query } = router;

    delete query[param];

    return router.replace({ pathname, query });
}

const AccountInfoProvider: FunctionComponent<PropsWithChildren & {
    onChange?: (customer: Customer | null) => void;
}> = ({
    children,
    onChange,
}) => {
    const authorized = useAuthorized();
    const [loading, setLoading] = useState<boolean>(true);
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [currentLoanId, setCurrentLoanId] = useState<string | null>(null);
    const router = useRouter();

    function apiUrls() {
        return [
            '/api/getAccountInfo',
            `/api/getAccountInfo?loanId=${ currentLoanId }`,
        ];
    }

    const reload = async (unsetCurrentLoanId?: boolean) => {
        const storeAuth = OfflineStore.get(OfflineStore.USER_AUTHORIZED);

        if (typeof storeAuth === 'boolean' ? !storeAuth : !authorized) {
            return setLoading(false);
        }

        setLoading(true);

        try {
            // await invalidateSwCache(apiUrls()).catch();
            const { data } = await ApiClient.request<{ data: { customer: Customer } }>(
                '/getAccountInfo',
                {
                    method: RestMethod.GET,
                    query: { loanId: unsetCurrentLoanId
                        ? undefined
                        : currentLoanId,
                    },
                },
            );

            if (data?.customer) {
                const info = data?.customer;

                setCustomer(info);

                OfflineStore.set(
                    OfflineStore.PERSONAL_INFO,
                    {
                        firstName: info.firstName,
                        lastName: info.lastName,
                        dateOfBirth: info.birthDate,
                        creditLimit: info.creditLimit,
                    } as PersonalInfo,
                );

                if ((info.currentLoan?.id && !currentLoanId)
                    || unsetCurrentLoanId
                ) {
                    setCurrentLoanId(info?.currentLoan?.id || null);
                }
            }
        } catch (err) {
            asyncLogger.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!authorized) {
            setCustomer(null);
        }
    }, [authorized]);

    useEffect(() => {
        onChange?.(customer);
    }, [customer]);

    useEffect(() => {
        const needReload = withAccountInfoRoutes.some(
            i => router.asPath.startsWith(i),
        ) || router.asPath === NamedRoute.HOME;

        if (!needReload) {
            return ;
        }

        if (currentLoanId !== customer?.currentLoan?.id) {
            (async () => await reload())();
        }

        // eslint-disable-next-line
    }, [authorized, currentLoanId, router.asPath]);

    return <AccountInfoContext.Provider value={{
        customer,
        loading,
        reload: async (unsetCurrentLoanId, dialog) => {
            const url = new URL(location.href);

            await invalidateSwCache([
                `${ url.pathname }${ url.search }`,
                url.pathname,
                ...apiUrls(),
            ]).catch();

            if (!dialog && url.searchParams.has('dialog')) {
                url.searchParams.delete('dialog');
                await router.replace({
                    pathname: url.pathname,
                    query: url.searchParams.toString(),
                });
            }

            return reload(unsetCurrentLoanId);
        },
        currentLoanId,
        setCurrentLoanId,
    }}>
        { children }
    </AccountInfoContext.Provider>;
};

export function useAccountInfo(): AccountInfoState {
    return useContext(AccountInfoContext);
}

export function useLoan(): Loan | null {
    const { customer } = useAccountInfo();

    return customer?.currentLoan || null;
}

export function useCustomer(): Customer | null {
    const { customer } = useAccountInfo();

    return customer;
}

export function useAccountInfoCacheUrls() {
    const { currentLoanId } = useAccountInfo();

    return [
        '/app',
        '/api/getAccountInfo',
        `/api/getAccountInfo?loanId=${ currentLoanId }`,
    ];
}

export default AccountInfoProvider;
