import axios, { AxiosRequestHeaders, Method } from 'axios';
import OfflineStore from '../OfflineStore';
import { isAuthError } from './common';

export interface ApiClientOptions {
    method?: Method;
    headers?: AxiosRequestHeaders;
    body?: any;
    query?: any;
    withAuth?: boolean;
}

export class ApiClient {
    public static async request<T = any>(
        url: string,
        options?: ApiClientOptions,
    ) : Promise<T> {
        const withAuth = options?.withAuth !== false;
        const { data } = await axios({
            url: '/api' + url,
            method: options?.method || 'post',
            headers: options?.headers,
            data: options?.body,
            params: options?.query,
            paramsSerializer: { indexes: null },
            withCredentials: true,
        });

        if (withAuth && isAuthError(data.errors)) {
            OfflineStore.set(OfflineStore.USER_AUTHORIZED, false);
        }

        return data;
    }
}
