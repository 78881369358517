import React from 'react';
import DesktopHeaderButton from '.';
import ProfileOutlinedIcon from '../../../../assets/SvgIcons/ProfileOutlinedIcon';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import SmartLink from '../../../SmartLink';

const MyProfileButton: React.FunctionComponent = () => {
    return <DesktopHeaderButton
        timeout={ 1000 }
        component={ SmartLink }
        startIcon={ <ProfileOutlinedIcon /> }
        sx={{
            width: '150px',
            padding: '10px 32px 10px 28px',
            lineHeight: '16.41px',
            '& .MuiButton-startIcon': {
                marginRight: '6px',
            },
        }}
        href={ NamedRoute.PROFILE }
        data-testid="btn-my-profile"
    >
        My Profile
    </DesktopHeaderButton>;
};

export default MyProfileButton;
