import { SvgIcon, SvgIconProps } from '@mui/material';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { Theme, useTheme } from '@mui/material/styles';

function SignInIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        component={ LoginOutlinedIcon }
        { ...props }
        sx={{
            color: theme.palette.warning.light,
            ...props.sx,
        }}
    />;
}

export default SignInIcon;
