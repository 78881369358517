import { Theme } from '@mui/material/styles';
import OfflineStore from '../OfflineStore';
import {
    createContext,
    FunctionComponent,
    PropsWithChildren,
    useContext,
    useEffect,
} from 'react';
import { paletteDark, paletteLight } from '../palettes';
import { KnownThemes } from '../themes';
import { Customer } from '../types/Customer';
import { LoanStatus } from '../types/enums';

export function updatePwaThemeColor(color: string) {
    if (typeof document === 'undefined') {
        return ;
    }

    const htmlColor = document.querySelector(
        'meta[name=theme-color]',
    );

    if (htmlColor) {
        htmlColor.setAttribute('content', color);
    }
}

export const mobileBgColor = (
    theme?: Theme,
    data?: Customer,
    isMobile = true,
): string => {
    const palette = theme
        ? theme.palette
        : OfflineStore.get(OfflineStore.THEME) === KnownThemes.dark
            ? paletteDark.palette
            : paletteLight.palette;

    const primaryColor = palette.primary.main;

    if (!(data && data.currentLoan && isMobile)) {
        return primaryColor;
    }

    const statusColorMap: {[key: string]: string} = {
        [LoanStatus.Pending]: palette.info.main,
        [LoanStatus.PastDue]: palette.warning.dark,
        [LoanStatus.Denied]: palette.info.main,
        [LoanStatus.Withdrawn]: palette.info.main,
        [LoanStatus.Reversed]: palette.info.main,
        [LoanStatus.WrittenOff]: palette.error.light,
    };
    const { paymentPlan, collections, status } = data.currentLoan;
    const hasPaymentPlan = (paymentPlan || []).length;

    if (collections && !hasPaymentPlan && status !== LoanStatus.Paid) {
        return palette.error.light;
    }

    return status ? statusColorMap[status] || primaryColor : primaryColor;
};

export interface MobileBgColorState {
    color: string;
}

export const MobileBgColorContext = createContext<MobileBgColorState>({
    color: mobileBgColor(),
});

export const MobileBgProvider: FunctionComponent<PropsWithChildren & {
    color: string;
}> = props => {
    useEffect(() => {
        updatePwaThemeColor(props.color);
    }, [props.color]);

    useEffect(() => {
        return () => {
            updatePwaThemeColor(mobileBgColor());
        };
    }, []);

    return <MobileBgColorContext.Provider value={{ color: props.color }}>
        { props.children }
    </MobileBgColorContext.Provider>;
};

export const useMobileBgColor = () => useContext(MobileBgColorContext);

export const useMobileBgColorActions = (...actions: any[]) => {
    const { color } = useMobileBgColor();

    for (const action of actions) {
        useEffect(() => {
            if (action) {
                updatePwaThemeColor(mobileBgColor());
            } else {
                updatePwaThemeColor(color);
            }
        }, [action]);
    }
};
