import {
    createContext,
    FunctionComponent,
    ReactNode,
    useContext,
} from 'react';
import { ConfigRecord } from '../../config/runtimeConfig';

export interface ConfigProviderProps {
    config: ConfigRecord;
    children?: ReactNode | undefined;
}

const ConfigContext = createContext<ConfigRecord>({} as ConfigRecord);

const ConfigProvider: FunctionComponent<ConfigProviderProps> = ({
    config,
    children,
}) => {
    return (
        <ConfigContext.Provider value={ config }>
            { children }
        </ConfigContext.Provider>
    );
};

export const useConfig = () => {
    return useContext(ConfigContext);
};

export default ConfigProvider;
