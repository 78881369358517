import React, { MouseEvent, useState } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import { buildMenuItem, isLight } from '../../../../common/helpers';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import SignInIcon from '../../../../assets/SvgIcons/SignInIcon';
import ProfileOutlinedIcon from '../../../../assets/SvgIcons/ProfileOutlinedIcon';
import MembersAreaIcon from '../../../../assets/SvgIcons/MembersAreaIcon';
import SignOutIcon from '../../../../assets/SvgIcons/SignOutIcon';
import SmartLink from '../../../SmartLink';

const styles = {
    button: (theme: Theme, open?: boolean) => ({
        height: '40px',
        width: '40px',
        padding: 'unset',
        minWidth: 'unset',
        boxShadow: 'none',
        backgroundColor: open
            ? isLight(theme)
                ? theme.palette.primary.light
                : theme.palette.secondary.main
            : 'inherit',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            boxShadow: theme.shadows[1],
        },
    }),
    signIn: {
        fontSize: '26px',
        transform: 'translate(-3px)',
    },
    item: (theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: '3px 32px 0',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        '&:hover': {
            backgroundColor: isLight(theme)
                ? theme.palette.primary.light
                : theme.palette.secondary.light,
            '& > div, span, svg': {
                color: theme.palette.common.white,
            },
        },
    }),
    itemIconContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '8px',
    },
    menuPaper: (theme: Theme) => ({
        mt: '11px',
        '& > ul': {
            backgroundColor: isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main,
        },
    }),
    itemIcon: (theme: Theme) => ({
        fontSize: '24px',
        transform: 'translate(0, -1px)',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white
        ,
    }),
    title: (theme: Theme, open: boolean) => ({
        ...theme.typography.h6,
        lineHeight: '36px',
        color: open
            ? isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.common.white
            : theme.palette.common.white,
        '&:hover': {
            color: theme.palette.common.white,
        },
    }),
    divider: (theme: Theme) => ({
        margin: '0 24px !important',
        borderColor: isLight(theme)
            ? theme.palette.divider
            : theme.palette.secondary.light,
    }),
    opened: (theme: Theme) => ({
        backgroundColor: theme.palette.common.white,
    }),
};

type MembersAreaButtonProps = ButtonProps & { user: boolean };

const MembersAreaButton: React.FunctionComponent<
    MembersAreaButtonProps
> = props => {
    const theme = useTheme<Theme>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const menuItems = [
        buildMenuItem({
            title: 'Members Area',
            link: NamedRoute.HOME,
            icon: <MembersAreaIcon sx={{ ...styles.itemIcon(theme) }} />,
        }),
        buildMenuItem({
            title: 'My profile',
            link: NamedRoute.PROFILE,
            icon: <ProfileOutlinedIcon sx={{ ...styles.itemIcon(theme) }} />,
        }),
        buildMenuItem({
            title: 'Sign Out',
            link: NamedRoute.LOGOUT,
            icon: <SignOutIcon sx={{ ...styles.itemIcon(theme) }} />,
        }),
    ];

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <React.Fragment>
        { props.user
            ? <Tooltip title="Members Area menu" enterTouchDelay={ 0 }>
                <Button
                    variant='text'
                    sx={ styles.button(theme, open) }
                    onClick={ handleClick }
                    data-testid="btn-menu-profile"
                >
                    <ProfileOutlinedIcon sx={{ fontSize: '24px' }} />
                </Button>
            </Tooltip>
            : <Tooltip title="Sign In" enterTouchDelay={ 0 }>
                <Button
                    variant='text'
                    sx={ styles.button(theme, open) }
                    component={ SmartLink }
                    href={ NamedRoute.LOGIN }
                    data-testid="btn-menu-sign-in"
                >
                    <SignInIcon sx={ styles.signIn } />
                </Button>
            </Tooltip>
        }
        <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            PaperProps={{ sx: { ...styles.menuPaper(theme)}}}
            disableScrollLock={ true }
        >
            { menuItems.map((item, idx) => (
                <Box key={`${ item.id }-${ item.title }`}>
                    <SmartLink href={ item.link }>
                        <MenuItem
                            sx={ styles.item(theme) }
                            onClick={ handleClose }
                        >
                            <Box sx={ styles.itemIconContainer }>
                                { item.icon }
                            </Box>
                            <Typography
                                component="span"
                                sx={ styles.title(theme, open) }
                            >
                                { item.title }
                            </Typography>
                        </MenuItem>
                    </SmartLink>
                    { (idx < menuItems.length - 1) &&
                        <Divider sx={ styles.divider } />
                    }
                </Box>
            ))}
        </Menu>
    </React.Fragment>;
};

export default MembersAreaButton;
