/**
 * Generates a random color from the provided colors array or
 * a preset array of colors. If no colors are provided, a preset array of
 * colors will be used.
 * @param colors - Optional array of colors to choose from.
 * @returns A randomly selected color as a string.
 */
export const getRandomColor = (colors?: string[]): string => {
    const colorsPreset = [
        '#FFFFFF',
        '#FAFF00',
        '#DA3826',
        '#73CA1C',
        '#2643DA',
        '#A5A2A2',
    ];
    const cs = colors && colors.length ? colors : colorsPreset;
    const randomIndex = Math.floor(Math.random() * cs.length);
    return cs[randomIndex];
};
