import { Box, Theme, ToggleButton } from '@mui/material';
import { FC } from 'react';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';
import { isLight } from '../../../common/helpers';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
    },
    button: (theme: Theme) => ({
        height: '38px',
        borderRadius: '19px',
        borderColor: isLight(theme)
            ? theme.palette.info.main
            : theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: '13px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline',
        whiteSpace: 'nowrap',
        padding: '0 12px',
        '&.Mui-selected': {
            outline: 'none',
            borderColor: 'transparent',
        },
    }),
};

const NotificationsTopicsFilter: FC = () => {
    const { subscriptions, filter, setFilter } = useNotificationsInfo();

    return <Box sx={ styles.container }>
        { subscriptions.map(subscription => {
            return <ToggleButton
                sx={ styles.button }
                key={ subscription.id }
                value={ subscription.id }
                selected={ filter.topicIds.includes(subscription.id) }
                onChange={ () => {
                    setFilter({
                        ...filter,
                        topicIds: filter.topicIds
                            .includes(subscription.id)
                            ? filter.topicIds
                                .filter(p => p != subscription.id)
                            : [...filter.topicIds, subscription.id],
                    });
                } }
            >
                { subscription.displayName }
            </ToggleButton>;
        })}
    </Box>;
};

export default NotificationsTopicsFilter;
