import { useEffect } from 'react';
import { useAuthorizedContext } from './useAuthorizedContext';

export default function useAuthorized(onEmptyToken?: () => void): boolean {
    const authorized = useAuthorizedContext();

    useEffect(() => {
        if (!authorized && onEmptyToken) {
            onEmptyToken();
        }
    }, [authorized, onEmptyToken]);

    return authorized;
}
