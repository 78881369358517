import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function BellIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        viewBox={ '0 0 22 28' }
        fill={ 'none' }
        xmlns={ 'http://www.w3.org/2000/svg' }
        { ...props }
    >
        <path
            d={ 'M1.3335 23.3327C1.04461 23.3327 0.805718 23.2382 0.61683 23.0494C0.427941 22.8605 0.333496 22.6216 0.333496 22.3327C0.333496 22.0438 0.427941 21.8049 0.61683 21.616C0.805718 21.4271 1.04461 21.3327 1.3335 21.3327H3.1335V11.1327C3.1335 9.31046 3.6835 7.66046 4.7835 6.18268C5.8835 4.7049 7.3335 3.77713 9.1335 3.39935V2.43268C9.1335 1.92157 9.31683 1.49935 9.6835 1.16602C10.0502 0.832682 10.4891 0.666016 11.0002 0.666016C11.5113 0.666016 11.9502 0.832682 12.3168 1.16602C12.6835 1.49935 12.8668 1.92157 12.8668 2.43268V3.39935C14.6668 3.77713 16.1224 4.7049 17.2335 6.18268C18.3446 7.66046 18.9002 9.31046 18.9002 11.1327V21.3327H20.6668C20.9557 21.3327 21.1946 21.4271 21.3835 21.616C21.5724 21.8049 21.6668 22.0438 21.6668 22.3327C21.6668 22.6216 21.5724 22.8605 21.3835 23.0494C21.1946 23.2382 20.9557 23.3327 20.6668 23.3327H1.3335ZM11.0002 27.3327C10.2891 27.3327 9.66683 27.0716 9.1335 26.5494C8.60016 26.0271 8.3335 25.3993 8.3335 24.666H13.6668C13.6668 25.3993 13.4057 26.0271 12.8835 26.5494C12.3613 27.0716 11.7335 27.3327 11.0002 27.3327ZM5.1335 21.3327H16.9002V11.1327C16.9002 9.46602 16.3391 8.06046 15.2168 6.91602C14.0946 5.77157 12.7113 5.19935 11.0668 5.19935C9.40016 5.19935 7.99461 5.77157 6.85016 6.91602C5.70572 8.06046 5.1335 9.46602 5.1335 11.1327V21.3327Z' }
            fill={ theme.palette.common.white }
        />
    </SvgIcon>;
}

export default BellIcon;
