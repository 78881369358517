import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import OfflineStore from '../OfflineStore';
import { updatePwaThemeColor } from '../helpers/themeBgColor';
import { KnownThemes } from '../themes';
import { paletteDark, paletteLight } from '../palettes';

export function useRepaint() {
    const theme = useTheme();

    useEffect(() => {
        const repaint = (key: string, value: KnownThemes) => {
            if (key === OfflineStore.THEME) {
                setTimeout(() => {
                    updatePwaThemeColor(value === KnownThemes.dark
                        ? paletteDark.palette.primary.main
                        : paletteLight.palette.primary.main,
                    );
                });
            }
        };

        OfflineStore.on('change', repaint);

        return () => {
            OfflineStore.off('change', repaint);
        };
    }, []);
}
