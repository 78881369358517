import {
    createContext,
    Dispatch,
    FunctionComponent,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState,
} from 'react';

export interface MenuState {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const MenuContext = createContext<MenuState>({} as MenuState);

const MenuProvider: FunctionComponent<PropsWithChildren> = ({
    children,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <MenuContext.Provider value={{ open, setOpen }}>
            { children }
        </MenuContext.Provider>
    );
};

export const useMenu = () => {
    return useContext(MenuContext);
};

export default MenuProvider;
