import React from 'react';
import Box from '@mui/material/Box';
import { DropdownType, MenuComponent, MenuItem, MenuProps } from '../types';
import Dropdown from './Dropdown';
import MobileMenuItem from './Item';
import { Theme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../../common/constants';

const styles = {
    menu: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        borderRadius: '20px',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            padding: '0 20px',
        },
    }),
};

const MobileMenu: React.FunctionComponent<MenuProps> = (
    { menuItems, backgroundColor },
) => {
    if (!menuItems.length) {
        return <></>;
    }

    return (
        <Box sx={ styles.menu }>
            { menuItems.map((item: MenuItem) => {
                const key = `${item.id}-${item.title}`;

                return <Box key={ key }>
                    { item.__component === MenuComponent.Item
                        ? <MobileMenuItem item={ item } />
                        : <Dropdown
                            item={ item as DropdownType }
                            backgroundColor={ backgroundColor }
                        />
                    }
                </Box>;
            })}
        </Box>
    );
};

export default MobileMenu;
