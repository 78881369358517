import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LogoIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return <SvgIcon
        viewBox={ '0 0 35 40.39' }
        fill={ 'none' }
        xmlns={ 'http://www.w3.org/2000/svg' }
        { ...props }
    >
        <path
            d={ 'M17.5 0.333008L34.9996 9.49967L17.5 18.6663L0.000406262 9.49968L17.5 0.333008Z' }
            fill={ theme.palette.common.white }
        />
        <path
            d={ 'M35 12.833L19.1667 21.1267V40.7229L35 32.4293V28.6663L22.5 35.214V23.1436L35 16.596V12.833Z' }
            fill={ '#94CD5B' }
        />
        <path
            d={ 'M15.8333 21.1267L0 12.833V32.4293L15.8333 40.7229V36.96L3.33333 30.4124V18.342L15.8333 24.8896V21.1267Z' }
            fill={ '#94CD5B' }
        />
    </SvgIcon>;
}

export default LogoIcon;
