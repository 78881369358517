import React, {
    ComponentProps,
    JSXElementConstructor,
    PropsWithChildren,
} from 'react';
import NextLink, { LinkProps } from 'next/link';

const Link = React.forwardRef<
    HTMLAnchorElement,
    LinkProps &
    PropsWithChildren &
    ComponentProps<JSXElementConstructor<any>>
>((props, ref) =>
    <NextLink { ...props } ref={ ref } />,
);

Link.displayName = 'Link';

export default Link;
