import { FC, Fragment } from 'react';
import { ErrorScreen } from '../config/runtimeConfig';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import SimplePage from '../layouts/SingleBlockPage/SimplePage';
import BarrierIcon from '../assets/SvgIcons/BarrierIcon';
import Typography from '@mui/material/Typography';
import { DEFAULT_MAINTENANCE_DESCRIPTION } from '../common/constants';
import { isLight } from '../common/helpers';
import { useConfig } from '../common/hooks/ConfigProvider';
import SmartLink from './SmartLink';

const styles = {
    contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '500px',
    },
    icon: {
        width: '222px',
        height: '154px',
        marginBottom: '35px',
    },
    title: {
        color: '#F39200',
        fontSize: '41px',
        fontWeight: 700,
        lineHeight: '38px',
        marginBottom: '20px',
    },
    subtitle: (theme: Theme, isLink?: boolean) => ({
        color: isLight(theme)
            ? theme.palette.secondary.main
            : theme.palette.common.white
        ,
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '28px',
        display: 'flex',
        flexWrap: isLink ? 'nowrap' : 'wrap',
    }),
};

export interface MaintenanceComponentProps extends ErrorScreen{
    phoneNumber?: string;
}

const MaintenanceComponent: FC<MaintenanceComponentProps> = props => {
    const { name, description, phoneNumber, code } = props;
    const { consumerName } = useConfig();
    const info = description ? description : DEFAULT_MAINTENANCE_DESCRIPTION;
    const theme = useTheme<Theme>();

    return <SimplePage
        title={ consumerName }
        withActions={ false }
        fullPaperWidth={ true }
        maintenanceCode={ code }
    >
        <Box sx={ styles.contentWrapper } textAlign="center">
            <BarrierIcon sx={ styles.icon }/>
            <Typography sx={ styles.title } component="h1">
                { name }
            </Typography>
            <Typography sx={ styles.subtitle }>
                { info }
            </Typography>
            <Fragment>
                <Typography sx={ styles.subtitle }>
                    { 'For further assistance you can contact us at ' }
                </Typography>
                <Typography>
                    <SmartLink
                        href={ `tel:${ phoneNumber }` }
                        style={{ ...(styles.subtitle as any)(theme, true) }}
                    >
                        { phoneNumber }
                    </SmartLink>
                </Typography>
            </Fragment>
        </Box>
    </SimplePage>;
};

export default MaintenanceComponent;
