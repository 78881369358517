import { MessagePayload } from 'firebase/messaging';
import { ApiClient } from '../clients/ApiClient';
import { EventEmitter } from 'events';

let resolvePushEnabled: (pushEnabled: boolean) => void;
const pushEnabledPromise = new Promise<boolean>(
    resolve => resolvePushEnabled = resolve,
);
let deviceType: string;
let resolveToken: (token: string) => void;
const tokenPromise = new Promise<string>(resolve => resolveToken = resolve);
let resolvePostMessage: (postMessage: (message: any) => void) => void;
const postMessagePromise = new Promise<(message: any) => void>(
    resolve => resolvePostMessage = resolve,
);

const isPushEnabled = () => pushEnabledPromise;

const setDeviceToken = (device: string, token: string) => {
    deviceType = device;
    resolveToken(token);
    registerDevice().catch();
};

const registerDevice = async () => {
    const token = await tokenPromise;
    return ApiClient.request(
        '/registerDevice',
        { body: { token, deviceType } },
    );
};

const postMessage = async (message: any) =>
    (await postMessagePromise)(message)
;

const onForegroundMessage = (message: MessagePayload) => {
    messaging.emit('foregroundMessage', message);
    messaging.emit('message', message);
};

const onBackgroundMessage = (message: MessagePayload) => {
    messaging.emit('backgroundMessage', message);
    messaging.emit('message', message);
};

const updateMessages = () => messaging.emit('update');

export class Messaging extends EventEmitter { }

const messaging = new Messaging();

export {
    resolvePushEnabled,
    isPushEnabled,
    setDeviceToken,
    resolvePostMessage,
    registerDevice,
    postMessage,
    onForegroundMessage,
    onBackgroundMessage,
    updateMessages,
    messaging,
};
