import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import SpaLink from '../../../../components/Link';
import { HEADER_BUTTON_HOVER_COLOR } from '../../../../config';
import Link from '../../../../components/Link';
import { LinkProps } from 'next/link';
import SmartLink from '../../../SmartLink';
import ThrottlingButton from '../../../ThrottlingButton';

const styles = {
    desktopHeaderButton: (theme: Theme) => ({
        backgroundColor: theme.palette.primary.light,
        borderRadius: '20px',
        height: '40px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: HEADER_BUTTON_HOVER_COLOR,
            color: theme.palette.warning.light,
        },
    }),
};

type DesktopHeaderButtonProps = ButtonProps
    & {
        component?: typeof Link | typeof SpaLink | typeof SmartLink;
        timeout?: number;
    }
    & Partial<LinkProps>;

const DesktopHeaderButton: React.FunctionComponent<
    DesktopHeaderButtonProps
> = props => {
    const { timeout, ...otherProps } = props;

    const buttonProps: ButtonProps = {
        disableElevation: true,
        variant: 'contained',
        ...otherProps,
        sx: (theme: Theme) => ({
            ...(styles.desktopHeaderButton as any)(theme) as SxProps,
            ...(typeof props.sx === 'function' ? props.sx(theme) : props.sx),
        }),
    };

    if (typeof timeout === 'number') {
        return <ThrottlingButton { ...buttonProps } timeout={ timeout }>
            { props.children }
        </ThrottlingButton>;
    }

    return <Button { ...buttonProps } >
        { props.children }
    </Button>;
};

export default DesktopHeaderButton;
