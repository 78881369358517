// noinspection JSUnusedGlobalSymbols
export enum CustomLoanStatusCode {
    APPROVED = 'Approved',
    DENIED = 'Denied',
    REVIEW = 'Review',
    AA_COMPLETED = 'AACompleted',
    AA_NOT_COMPLETED = 'AANotCompleted',
    AA_IN_PROGRESS = 'AAInProgress',
    AUTO_PP = 'AutoPP',
}

// noinspection JSUnusedGlobalSymbols
export enum IncomeFrequency {
    Daily = 'Daily',
    Weekly = 'Weekly',
    BiWeekly = 'BiWeekly',
    SemiMonthly = 'SemiMonthly',
    Monthly = 'Monthly',
    Other = 'Other',
}

// noinspection JSUnusedGlobalSymbols
export enum PaymentType {
    Cash = 'Cash',
    Check = 'Check',
    Ach = 'Ach',
    DebitCard = 'DebitCard',
    MoneyOrder = 'MoneyOrder',
    PrepaidCard = 'PrepaidCard',
    ECheck = 'ECheck',
    LoanTransfer = 'LoanTransfer',
}

export enum FundingType {
    // noinspection JSUnusedGlobalSymbols
    Cash = 'Cash',
    Check = 'Check',
    Ach = 'Ach',
    PrepaidCard = 'PrepaidCard',
    MixedPrepaidCardCash = 'MixedPrepaidCardCash',
    CardDeposit = 'CardDeposit',
    Wire = 'Wire',
}

// noinspection JSUnusedGlobalSymbols
export enum LoanStatus {
    Pending = 'Pending',
    Paid = 'Paid',
    Active = 'Active',
    Denied = 'Denied',
    PastDue = 'PastDue',
    Withdrawn = 'Withdrawn',
    WrittenOff = 'WrittenOff',
    Reversed = 'Reversed',
    Other = 'Other',
}

// noinspection JSUnusedGlobalSymbols
export enum AddressType {
    Common = 'Common',
    Home = 'Home',
    Mailing = 'Mailing',
}

// noinspection JSUnusedGlobalSymbols
export enum PhoneType {
    Common = 'Common',
    Cell = 'Cell',
    Home = 'Home',
}

// noinspection JSUnusedGlobalSymbols
export enum HolidaySkipping {
    After = 'After',
    Before = 'Before',
}

// noinspection JSUnusedGlobalSymbols
export enum PayrollType {
    Direct = 'Direct',
    Check = 'Check',
    Cash = 'Cash',
    Other = 'Other',
}

// noinspection JSUnusedGlobalSymbols
export enum EmploymentType {
    Employment = 'Employment',
    SocialSecurity = 'SocialSecurity',
    Disability = 'Disability',
    Retirement = 'Retirement',
    SelfEmployed = 'SelfEmployed',
    Other = 'Other',
}

// noinspection JSUnusedGlobalSymbols
export enum BankAccountType {
    Checking = 'Checking',
    Savings = 'Savings',
}
