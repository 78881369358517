import { FC } from 'react';
import {
    SxProps,
    Theme,
    useTheme,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import {
    useNotificationsInfo,
} from '../../../common/hooks/NotificationsProvider';

const styles = {
    buttonGroup: (theme: Theme) => ({
        '.MuiToggleButtonGroup-grouped': {
            borderRadius: '19px',
            borderWidth: '2px',
            borderColor: theme.palette.primary.contrastText,
            padding: '0 16px',
            height: '38px',
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '.Mui-selected': {
            backgroundColor: `${theme.palette.primary.contrastText} !important`,
            outline: 'none !important',
            color: 'black !important',
            '&:hover': {
                backgroundColor: theme.palette.primary.contrastText,
            },
        },
    }),
};

const NotificationsUnreadFilter: FC<{sx?: SxProps<Theme>}> = ({ sx }) => {
    const { filter , setFilter } = useNotificationsInfo();
    const theme = useTheme();

    return <ToggleButtonGroup
        sx={{
            ...styles.buttonGroup(theme),
            ...(typeof sx === 'function' ? sx(theme) : sx),
        }}
        value={filter.unreadOnly}
        exclusive
        onChange={() => setFilter({...filter, unreadOnly: !filter.unreadOnly })}
    >
        <ToggleButton value={false}>All</ToggleButton>
        <ToggleButton value={true}>Unread</ToggleButton>
    </ToggleButtonGroup>;
};

export default NotificationsUnreadFilter;
