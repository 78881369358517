import { ErrorScreen } from '../../config/runtimeConfig';
import { MA_MAINTENANCE_CODES, WEBSITE_MAINTENANCE_CODES } from '../constants';

export function getEnabledScreen(
    screens: ErrorScreen[] | [],
    isForAllWebsite: boolean,
): ErrorScreen | undefined {
    const enabledScreens = screens.length
        ? screens
        .filter(screen => isForAllWebsite
            ? WEBSITE_MAINTENANCE_CODES.includes(screen.code)
            : MA_MAINTENANCE_CODES.includes(screen.code),
        )
        .filter(screen => screen.enabled === true)
        .sort((a, b) => a.priority - b.priority)
        : []
    ;

    return enabledScreens.length ? enabledScreens[0] : undefined;
}
