import React, { FunctionComponent } from 'react';
import SingleBlockPage, { SingleBlockPageProps } from './index';
import OfflineError from '../../components/OfflineError';
import { Theme } from '@mui/material';
import { KnownBreakpoints } from '../../common/constants';
import Box from '@mui/material/Box';
import { ContentLayoutProps } from '../BaseLayout';

export const styles = {
    layout: (theme: Theme) => ({
        position: 'relative',
        padding: '100px 0',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
            paddingTop: '38px 0',
        },
    }),
    container: (fullWidth?: boolean) => ({
        position: 'relative',
        width: fullWidth ? '100%' : '440px',
        paddingRight: fullWidth ? 'unset' : '45px',
        paddingLeft: fullWidth ? 'unset' : '53px',
    }),
};

export type SimpleFormPageProps = SingleBlockPageProps & ContentLayoutProps & {
    networkError: boolean;
    setNetworkError: (value: boolean) => void;
};

export const SimpleFormPage: FunctionComponent<SimpleFormPageProps> = props => {
    const {
        networkError,
        setNetworkError,
        children,
        ...singleBlockPageProps
    } = props;

    return <SingleBlockPage { ...singleBlockPageProps }>
        { networkError &&
            <OfflineError onClose={ () => setNetworkError(false) } />
        }
        <Box sx={ styles.layout }>
            <Box sx={ styles.container() }>
                { children }
            </Box>
        </Box>
    </SingleBlockPage>;
};

const SimplePage: FunctionComponent<SingleBlockPageProps> = props => {
    const { children, fullPaperWidth, ...singleBlockPageProps } = props;

    return <SingleBlockPage { ...singleBlockPageProps }>
        <Box sx={ styles.layout }>
            <Box sx={ styles.container(fullPaperWidth) }>
                { children }
            </Box>
        </Box>
    </SingleBlockPage>;
};

export default SimplePage;
