import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Error from './Error';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material/styles';
import { isLight } from '../common/helpers';

const styles = {
    overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'black',
        opacity: '0.1',
        zIndex: '99999',
    } as SxProps,
    dialogWrapper: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '999999',
    } as SxProps,
    closeArea: {
        display: 'table',
        width: '100%',
        height: '100%',
        opacity: '1',
    } as SxProps,
    dialogWindow: (theme: Theme) => ({
        width: '100%',
        maxWidth: '400px',
        margin: '32px',
        padding: '32px',
        background: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.light,
        borderRadius: '20px',
        boxShadow: theme.shadows[1],
    }),
    footerButton: {
        marginTop: '64px',
        padding: '0 32px',
    } as SxProps,
};

export interface OfflineErrorProps {
    onError?: (err: any) => void;
    onClose?: () => void;
}

const OfflineError: FunctionComponent<OfflineErrorProps> = props => {
    const handleDialogClick = (e: React.MouseEvent) => e.stopPropagation();
    const handleClose = () => props.onClose && props.onClose();

    return <React.Fragment>
        <Box sx={ styles.overlay }/>
        <Box sx={ styles.dialogWrapper }>
            <Box sx={ styles.closeArea } onClick={ handleClose }>
                <Box sx={{ display: 'table-cell', verticalAlign: 'middle' }}>
                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Box
                            sx={ styles.dialogWindow }
                            onClick={ handleDialogClick }
                        >
                            <Error
                                code="offline"
                                showRefresh={ false }
                                footer={
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={ styles.footerButton }
                                        onClick={ handleClose}
                                        data-testid="btn-close-offline-error"
                                    >
                                        OK
                                    </Button>
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </React.Fragment>;
};

export default OfflineError;
