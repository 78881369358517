export interface MenuType {
    id: number;
    attributes: {
        name: string;
        body: MenuItem[];
    }
}

export enum MenuComponent {
    // noinspection JSUnusedGlobalSymbols
    Item = 'items.menu-item',
    Dropdown = 'blocks.dropdown',
}

export interface MenuItemType {
    id: number;
    __component: string;
    title: string;
    link: string;
    icon?: JSX.Element;
}

export interface DropdownType extends MenuItemType {
    items: MenuItemType[];
}

export type MenuItem = MenuItemType | DropdownType;

export interface MenuProps {
    menuItems: MenuItem[];
    backgroundColor?: string;
    withoutThemeSwitcher?: boolean;
}

export interface MenuItemProps {
    item: MenuItemType;
    withoutThemeSwitcher?: boolean;
}
