import { NextApiRequest } from 'next';
import GqlClient from '../clients/GqlClient';
import asyncLogger from '../logger';
import absoluteUrl from 'next-absolute-url';

export const trackRequest = async (req: NextApiRequest): Promise<void> => {
    const apiPkg = require('../../package.json');
    const url = absoluteUrl(req).origin + (req?.url || '');

    await GqlClient.gqlRequest(
        `mutation WSTrackRequest($input: TrackingRequestInput!) {
            trackRequest(input: $input)
        }`,
        {
            req,
            variables: { input: {
                clientDate: req.headers['x-client-date'],
                appVersion: apiPkg.version,
                httpMethod: req.method,
                url,
            } },
        },
    ).catch(asyncLogger.error);
};
