import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CheckboxIndeterminateIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 20 20'}
            width='20'
            height='20'
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <rect
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                stroke={theme.palette.text.primary}
                fill='transparent'
            />
            <path
                d="M 3.537 10.122 L 16.551 10.167"
                stroke={theme.palette.text.primary}
                fill='transparent'
            ></path>
        </SvgIcon>
    );
}

export default CheckboxIndeterminateIcon;
