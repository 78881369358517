import { Badge, CircularProgress } from '@mui/material';
import BellIcon from '../../../assets/SvgIcons/BellIcon';
import { isLight } from '../../../common/helpers';
import { FC, MouseEventHandler } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { useNotificationsInfo }
    from '../../../common/hooks/NotificationsProvider';

const styles = {
    badgeContent: (
        theme: Theme,
        open: boolean,
        hasUnread: boolean,
        loading: boolean,
    ) => ({
        padding: '10px',
        '& .MuiBadge-badge': {
            minWidth: '0px',
            width: hasUnread ? '10px' : 0,
            height: hasUnread ? '10px' : 0,
            padding: 'unset',
            borderRadius: '50%',
            border: hasUnread
                ? `2px solid ${ theme.palette.common.white }`
                : 'none',
            transform: 'translate(-4px, 5.5px)',
        },
        borderRadius: '50%',
        backgroundColor: open ?
            isLight(theme)
                ? theme.palette.primary.light
                : theme.palette.secondary.main
            : 'inherit',
        cursor: loading ? 'unset' : 'pointer',
        ...(
            loading
                ? {}
                : {
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: '50%',
                    },
                }
        ),
    }),
    loaderProgress: (theme: Theme) => ({
        color: theme.palette.primary.light,
    }),
};

export interface NotificationsButtonProps {
    selected?: boolean;
    onClick: MouseEventHandler<HTMLElement>;
}

const NotificationsButton: FC<NotificationsButtonProps> = ({
    selected,
    onClick,
}) => {
    const theme = useTheme();
    const { visible, hasUnread, initializing } = useNotificationsInfo();

    return visible
        ? <Badge
            color={ 'error' }
            overlap={ 'circular' }
            badgeContent={ hasUnread ? '' : null }
            sx={
                styles.badgeContent(theme, !!selected, hasUnread, initializing)
            }
            onClick={ initializing ? undefined : onClick }
        >
            { initializing
                ? <CircularProgress sx={ styles.loaderProgress } size={ 24 }/>
                : <BellIcon />
            }
        </Badge>
        : null;
};

export default NotificationsButton;
