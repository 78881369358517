import { Method } from 'axios';
import { NextApiRequest, NextApiResponse } from 'next';
import { ErrorCode } from '../clients/errors';

export enum RestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export function isMethod(req: NextApiRequest, method: Method): boolean {
    return req.method?.toLowerCase() === method.toLowerCase();
}

// noinspection JSUnusedGlobalSymbols
export function isGet(req: NextApiRequest) {
    return isMethod(req, RestMethod.GET);
}

// noinspection JSUnusedGlobalSymbols
export function isPost(req: NextApiRequest): boolean {
    return isMethod(req, RestMethod.POST);
}

// noinspection JSUnusedGlobalSymbols
export function isPut(req: NextApiRequest): boolean {
    return isMethod(req, RestMethod.PUT);
}

// noinspection JSUnusedGlobalSymbols
export function isDelete(req: NextApiRequest): boolean {
    return isMethod(req, RestMethod.DELETE);
}

// noinspection JSUnusedGlobalSymbols
export default function verifyMethod(
    req: NextApiRequest,
    res: NextApiResponse,
    ...method: Method[]
) {
    if (method.find(name => name.toLowerCase() === req.method?.toLowerCase())) {
        return true;
    }

    res.status(405).end(`Method ${ req.method } Not Allowed`);

    return false;
}

export async function sendError(
    res: NextApiResponse,
    code: number,
    message: string,
) {
    await res.status(code).json({ error: { code, message } });
}

export function isOffline(err: unknown) {
    return (err as any).code === ErrorCode.ERR_NETWORK;
}
