import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export interface CheckboxProps {
    checkboxProps?: SvgIconProps;
    stroke?: string;
}

function CheckboxIcon(props: CheckboxProps) {
    const theme = useTheme<Theme>();
    const { checkboxProps, stroke } = props;

    return (
        <SvgIcon
            viewBox={'0 0 20 20'}
            width='20'
            height='20'
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            { ...checkboxProps }
        >
            <rect
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                stroke={ stroke || theme.palette.text.primary }
                fill='transparent'
            />
        </SvgIcon>
    );
}

export default CheckboxIcon;
