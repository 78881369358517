import asyncLogger from '../logger';

function base64decode(str: string, encoding: BufferEncoding = 'utf8'): string {
    if (typeof window === 'undefined') {
        return Buffer.from(str, 'base64').toString(encoding);
    }

    return window.atob(str);
}

/**
 * Decodes given JWT token and returns object representation of its data
 *
 * @param {string} token
 * @return {any}
 */
export default function jwtDecode<T>(token: string | null): T | null {
    if (!token) {
        return null;
    }

    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            base64decode(base64)
                .split('')
                .map(
                    (c) => '%' + (
                        '00' + c.charCodeAt(0).toString(16)
                    ).slice(-2),
                )
                .join(''),
        );

        return JSON.parse(jsonPayload) as T;
    } catch (err) {
        asyncLogger.error(err);
    }

    return null;
}
