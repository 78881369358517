import React, { useState } from 'react';
import DesktopHeaderButton from '.';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../../../common/helpers';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { MENU_ITEM_HOVER_COLOR } from '../../../../config';
import { NamedRoute } from '../../../../common/hooks/useNamedRoute';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../../../common/constants';
import { useAccountInfo } from '../../../../common/hooks/AccountInfoProvider';
import { useRouter } from 'next/router';
import SmartLink from '../../../SmartLink';
import { Loan } from '../../../../common/types/Loan';

const styles = {
    icon: (theme: Theme, open: boolean) => ({
        color: theme.palette.common.white,
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    menuItem: {
        flexGrow: 1,
        padding: '0 32px',
        '&:hover': {
            backgroundColor: MENU_ITEM_HOVER_COLOR,
        },
    },
    menuItemTitle: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '36px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.secondary.dark,
    }),
    divider: (theme: Theme) => ({
        margin: '0 32px !important',
        borderColor: isLight(theme)
            ? theme.palette.divider
            : theme.palette.info.light
        ,
    }),
};

interface LoanIdButtonProps {
    id: string;
    additionalLoansIds?: string[];
    customerLoans?: Loan[];
}

const LoanIdButton: React.FunctionComponent<LoanIdButtonProps> = props => {
    const { id, additionalLoansIds, customerLoans } = props;

    const theme = useTheme();
    const isLaptop = useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        ),
    );

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { currentLoanId, setCurrentLoanId } = useAccountInfo();
    const [currentId, setCurrentId] = useState(currentLoanId || id);
    const open = Boolean(anchorEl);
    const router = useRouter();
    const sortedCustomerLoans = customerLoans || [];

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    if ((additionalLoansIds?.length || 0) <= 1) {
        return <DesktopHeaderButton
            timeout={ 1000 }
            component={ SmartLink }
            sx={{
                padding: '11px 29px 12px 27px',
                lineHeight: '16.94px',
                whiteSpace: 'nowrap',
            }}
            href={ NamedRoute.HOME }
            data-testid="btn-loan-ref"
        >
            { !isLaptop ? `Loan ID - ${ id }` : `#${ currentId }` }
        </DesktopHeaderButton>;
    }

    if (!additionalLoansIds?.find(i => i === id)) {
        additionalLoansIds?.unshift(id);
    }

    return <React.Fragment>
        <DesktopHeaderButton
            sx={{
                padding: '11px 29px 12px 27px',
                lineHeight: '16.94px',
                whiteSpace: 'nowrap',
                [theme.breakpoints.down(KnownBreakpoints.tablet)]: {
                    backgroundColor: theme.palette.secondary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.light,
                    },
                },
            }}
            endIcon={ <ArrowDropDownIcon sx={ styles.icon(theme, open) } /> }
            onClick={ handleOpen }
            data-testid="btn-loan-ref"
        >
            { !isLaptop ? `Loan ID - ${ currentId }` : `#${ currentId }` }
        </DesktopHeaderButton>
        <Menu
            anchorEl={ anchorEl }
            open={ open }
            onClose={ () => setAnchorEl(null) }
            PaperProps={{ sx: { mt: '11px' } }}
        >
            { sortedCustomerLoans.map((loan, index) => (
                <Box key={ index }>
                    <MenuItem
                        sx={ styles.menuItem }
                        onClick={ () => {
                            setCurrentId(loan.id);
                            setCurrentLoanId(loan.id);
                            setAnchorEl(null);
                            router.push(NamedRoute.HOME).then();
                        }}
                    >
                        <Typography
                            component="span"
                            sx={ styles.menuItemTitle }
                        >
                            { 'Loan #' + loan.id + ' - ' + loan.status }
                        </Typography>
                    </MenuItem>
                    { (index < sortedCustomerLoans.length - 1) &&
                        <Divider sx={ styles.divider } />
                    }
                </Box>
            )) }
        </Menu>
    </React.Fragment>;
};

export default LoanIdButton;
