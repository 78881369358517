import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Theme, useTheme } from '@mui/material/styles';

function ConnectIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 91 76'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M26.8482 71.0374C17.8668 65.8519 12.0069 58.1494 9.26856 47.9299C6.53023 37.7103 7.75378 28.1098 12.9392 19.1284L15.6111 14.5006L2.63383 17.9778L0.938673 11.6514L25.1088 5.17505L31.5851 29.3452L25.2587 31.0403L21.7815 18.0631L19.1096 22.6909C14.8742 30.0269 13.8649 37.8313 16.0816 46.1043C18.2983 54.3773 23.0747 60.6315 30.4107 64.8669C32.3989 66.0148 34.3833 66.932 36.3638 67.6183C38.3442 68.3047 40.3209 68.7603 42.2936 68.9851L44.1626 75.9604C40.9027 75.9066 37.8534 75.4487 35.0145 74.5867C32.1756 73.7246 29.4535 72.5415 26.8482 71.0374ZM66.6034 70.8167L60.1271 46.6466L66.6157 44.908L70.0495 57.723L72.4839 53.5065C76.7193 46.1706 77.7286 38.3661 75.5119 30.0931C73.2951 21.8201 68.5188 15.5659 61.1828 11.3305C59.1946 10.1826 57.2003 9.28257 55.2 8.63049C53.1997 7.9784 51.2528 7.47139 49.3593 7.10946L47.4903 0.134189C50.7501 0.187984 53.7896 0.663034 56.6087 1.55934C59.4278 2.45565 62.14 3.65589 64.7453 5.16006C73.7267 10.3455 79.5866 18.048 82.3249 28.2676C85.0632 38.4871 83.8397 48.0876 78.6543 57.069L76.1012 61.4912L89.0784 58.0139L90.7736 64.3403L66.6034 70.8167Z'
                }
                fill={ props.fill || theme.palette.text.primary}
            />
        </SvgIcon>
    );
}

export default ConnectIcon;
