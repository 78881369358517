import React, {
    FC,
    Key,
    MouseEventHandler,
    PropsWithChildren,
    Ref,
    useEffect,
    useState,
} from 'react';
import Link from './Link';

interface CommonLinkProps {
    href: string;
    key?: Key;
    onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
    style?: React.CSSProperties;
    target?: string;
    className?: string;
    forwardedRef?: Ref<HTMLAnchorElement>;
}

const SmartLink: FC<PropsWithChildren<CommonLinkProps>> = ({
    children,
    className,
    forwardedRef,
    ...linkProps
}) => {
    const [env, setEnv] = useState<string | undefined>(undefined);
    const [isAppLink, setIsAppLink] = useState(false);
    const [cls, setCls] = useState('');

    useEffect(() => {
        const isBrowser = typeof window !== 'undefined'
            && typeof sessionStorage !== 'undefined'
        ;

        if (isBrowser) {
            const env = sessionStorage.getItem('env');
            const appLink = linkProps.href.startsWith('/app')
                && new URL(document.location.href).pathname.startsWith('/app')
            ;

            setEnv(env || 'web');
            setIsAppLink(appLink);
            setCls(className || '');
        }
    }, [linkProps.href, className]);

    return env === 'web' && !isAppLink
        ? <a className={ cls } { ...linkProps } ref={ forwardedRef }>
            { children }
        </a>
        : <Link className={ cls } { ...linkProps } ref={ forwardedRef }>
            { children }
        </Link>;
};

export default React.forwardRef<
    HTMLAnchorElement,
    PropsWithChildren<CommonLinkProps>
>((props, ref) => <SmartLink { ...props } forwardedRef={ ref } />);
