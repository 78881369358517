import OfflineStore from '../OfflineStore';
import asyncLogger from '../logger';
import { ApiClient } from '../clients/ApiClient';
import { registerDevice, postMessage } from './pushNotifications';
import { RestMethod } from './rest';
import jwtDecode from './jwtDecode';
import { GtmEvent, fireGtmEvent } from '../gtmEvents';

export function login(token: string) {
    const user = jwtDecode<{ customerId: string }>(token);

    if (user) {
        OfflineStore.set(OfflineStore.USER_AUTHORIZED, true);
        postMessage({ type: 'login' }).catch();
        fireGtmEvent(GtmEvent.LOGIN, { customerId: user.customerId });
    }

    registerDevice().catch();
}



export async function logout() {
    OfflineStore.del(OfflineStore.USER_AUTHORIZED);

    await ApiClient.request(
        `/logout`,
        { method: RestMethod.POST },
    );

    registerDevice().catch(asyncLogger.error);
    postMessage({ type: 'logout' }).catch();
    fireGtmEvent(GtmEvent.LOGOUT);
}

// noinspection JSUnusedLocalSymbols
export function isTokenExpired(token: string): boolean {
    if (!token) {
        return true;
    }

    try {
        const data = jwtDecode(token) as any;

        return !!(data && Date.now() > (data.exp || 0) * 1000);
    } catch (e) { /* ignore */ }

    return true;
}

export function isAuthorized(req: any): boolean {
    return !!req.cookies.authToken;
}
