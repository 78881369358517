/* eslint-disable no-console */

import asyncLogger from '../logger';

export interface ConsoleMessage {
    level: string;
    args: any[];
}

export interface ConsoleMessageWithOrigin extends ConsoleMessage {
    origin: (...args: any[]) => void;
}

export function setConsoleHook(
    hook: (message: ConsoleMessageWithOrigin) => void,
) {
    for (const level of Object.keys(asyncLogger)) {
        const origin = (asyncLogger as any)[level];

        (asyncLogger as any)[level] = (...args: any[]) =>
            hook({ level, args, origin })
        ;
    }
}

export function toConsoleMessage(
    message: ConsoleMessageWithOrigin,
): ConsoleMessage {
    return { level: message.level, args: message.args };
}
